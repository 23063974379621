import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";
import {
  IElementControls,
  IElementEventRaw,
  IElementSummaryData,
} from "./types";

const createAction = deprecated.createAction;

export const updateElementControls = createAction(
  "UPDATE_ELEMENT_CONTROLS",
  (resolve) => {
    return (controls: IElementControls) => resolve(controls);
  }
);

export const elementDialogShow = createAction(
  "ELEMENT_DIALOG_SHOW",
  (resolve) => (id: number) => resolve(id)
);

export const elementDialogHide = createAction(
  "ELEMENT_DIALOG_HIDE",
  (resolve) => () => resolve()
);

export const fetchElementSummary = createAsyncAction(
  "FETCH_ELEMENT_SUMMARY_START",
  "FETCH_ELEMENT_SUMMARY_SUCCESS",
  "FETCH_ELEMENT_SUMMARY_FAILURE"
)<void, { id: number; data: IElementSummaryData }, IError>();

export const fetchElementEventData = createAsyncAction(
  "FETCH_ELEMENT_EVENT_DATA_START",
  "FETCH_ELEMENT_EVENT_DATA_SUCCESS",
  "FETCH_ELEMENT_EVENT_DATA_FAILURE"
)<void, { id: number; data: IElementEventRaw }, IError>();

import React from "react";
import styled from "styled-components/macro";
import { TabLink } from "./Tab";

const Item = styled.li`
  flex: 1;
`;

interface IStyleProps {
  selected: boolean;
}

interface IProps {
  key: number;
  index: number;
  label: React.ReactNode;
  link: string;
  onClick: (index: number) => void;
}

type Props = IStyleProps & IProps;

const EntryTab: React.FC<Props> = ({
  index,
  label,
  link,
  onClick,
  selected,
}) => {
  const onTabClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onClick(index);
  };
  return (
    <Item>
      <TabLink href={`#${link}`} onClick={onTabClick} selected={selected}>
        {label}
      </TabLink>
    </Item>
  );
};

export default EntryTab;

import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { ISettings } from "../../core/store/game/types";

interface IProps {
  error: string;
  settings: ISettings;
}

const JoinLeagueError: React.FC<IProps> = ({ error, settings }) => {
  const { t } = useTranslation();
  const getErrorMessage = (
    error: string,
    settings: ISettings,
    t: TFunction
  ) => {
    switch (error) {
      case "max_length": {
        return t(
          "joinPrivate.errors.maxLength",
          "Invalid code entered. Please check the code."
        );
      }
      case "invalid": {
        return t(
          "joinPrivate.errors.invalid",
          "Invalid code entered. Please check the code and confirm with the league administrator if you are still having problems."
        );
      }
      case "league_closed": {
        return t(
          "joinPrivate.errors.leagueClosed",
          "This league is closed to new entries."
        );
      }
      case "already_joined": {
        return t(
          "joinPrivate.errors.alreadyJoined",
          "You are already entered in this league."
        );
      }
      case "entry_banned": {
        return t(
          "joinPrivate.errors.entryBanned",
          "The creator of this league has banned you from entering."
        );
      }
      case "max_private_entries": {
        return t(
          "joinPrivate.errors.maxPrivate",
          "You are in the maximum number of {{ maxPrivateEntries }} private leagues. Before you can join a new league you will need to leave one of these leagues.",
          {
            maxPrivateEntries: settings.league_join_private_max,
          }
        );
      }
      case "public_league_max_exceeded": {
        return `You may only enter ${settings.league_join_public_max} public leagues`;
      }
      default:
        return error;
    }
  };
  return <p>{getErrorMessage(error, settings, t)}</p>;
};

export default JoinLeagueError;

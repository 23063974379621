import styled, { css } from "styled-components/macro";

interface ISubHeadingProps {
  variant?: string;
}

const SubHeading = styled.h3<ISubHeadingProps>`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 1.6rem;
  font-weight: bold;

  ${(props) =>
    props.variant === "light" &&
    css`
      color: ${({ theme }) => theme.colors.white}; ;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[3]};
  }
`;

export default SubHeading;

import { navigate } from "@reach/router";
import { ThunkResult } from "..";
import { apiDelete, apiGet, apiPost, apiPut } from "../../utils/api";
import { getPlayerData } from "../player/reducers";
import * as actions from "./actions";
import {
  IBanEntryData,
  ICreateClassicLeagueData,
  ICreateH2HLeagueData,
  IJoinPrivateLeagueData,
  IJoinPublicLeagueData,
  IUpdateClassicLeagueData,
  IUpdateH2HLeagueData,
} from "./types";
import {
  localStorageKey,
  setValue,
  clearValue,
} from "./autoJoinPersistentStorage";

export const createClassicLeague =
  (data: ICreateClassicLeagueData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost(
        "leagues-classic/",
        data,
        dispatch,
        actions.createClassicLeague
      );
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const createH2HLeague =
  (data: ICreateH2HLeagueData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost("leagues-h2h/", data, dispatch, actions.createH2HLeague);
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const fetchClassicLeagueForAdmin =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `leagues-classic/${leagueId}/`,
        dispatch,
        actions.fetchClassicLeagueForAdmin
      );
    } catch (e) {
      return;
    }
  };

export const fetchH2HLeagueForAdmin =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `leagues-h2h/${leagueId}/`,
        dispatch,
        actions.fetchH2HLeagueForAdmin
      );
    } catch (e) {
      return;
    }
  };

export const updateClassicLeague =
  (
    leagueId: number,
    data: IUpdateClassicLeagueData
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPut(
        `leagues-classic/${leagueId}/`,
        data,
        dispatch,
        actions.updateClassicLeague
      );
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const updateH2HLeague =
  (leagueId: number, data: IUpdateH2HLeagueData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPut(
        `leagues-h2h/${leagueId}/`,
        data,
        dispatch,
        actions.updateH2HLeague
      );
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const banLeagueEntry =
  (leagueId: number, data: IBanEntryData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost(
        `league/${leagueId}/bans/ban/`,
        data,
        dispatch,
        actions.banLeagueEntry
      );
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const unbanLeagueEntry =
  (leagueId: number, data: IBanEntryData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost(
        `league/${leagueId}/bans/unban/`,
        data,
        dispatch,
        actions.unbanLeagueEntry
      );
    } catch (e) {
      return;
    }
    navigate("/leagues");
  };

export const joinPrivateLeague =
  (data: IJoinPrivateLeagueData): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      try {
        await apiPost(
          "leagues-private/join/",
          data,
          dispatch,
          actions.joinPrivateLeague,
          {
            entry: player.entry,
          }
        );
      } catch (e) {
        return;
      }
    }
  };

export const joinPublicLeague =
  (data: IJoinPublicLeagueData): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      try {
        await apiPost(
          "leagues-public/join/",
          data,
          dispatch,
          actions.joinPublicLeague,
          {
            entry: player.entry,
          }
        );
      } catch (e) {
        return;
      }
    }
    navigate("/leagues");
  };

export const fetchLeagueCode =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `league/${leagueId}/code/`,
        dispatch,
        actions.fetchLeagueCode,
        { league: leagueId }
      );
    } catch (e) {
      return;
    }
  };

export const regenerateLeagueCode =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost(
        `league/${leagueId}/code/regenerate/`,
        {},
        dispatch,
        actions.regenerateLeagueCode,
        { league: leagueId }
      );
    } catch (e) {
      return;
    }
  };

export const leaveLeague =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      try {
        await apiPost(
          `league/${leagueId}/leave/`,
          {},
          dispatch,
          actions.leaveLeague,
          { entry: player.entry, league: leagueId }
        );
      } catch (e) {
        return;
      }
    }
  };

export const deleteLeague =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    const player = getPlayerData(getState());
    if (player && player.entry) {
      try {
        // TODO - Change endpoint to handle all types of league
        await apiPost(
          `leagues-private/delete/`,
          { league: leagueId },
          dispatch,
          actions.deleteLeague,
          { entry: player.entry, league: leagueId }
        );
      } catch (e) {
        return;
      }
      navigate("/leagues");
    }
  };

export const fetchRenewableLeagues =
  (): ThunkResult<Promise<void>> => async (dispatch) =>
    apiGet(`leagues-renewable/`, dispatch, actions.fetchRenewableLeagues);

export const renewRenewableLeague =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch) =>
    apiPost(
      `leagues-renewable/${id}/renew/`,
      {},
      dispatch,
      actions.renewRenewableLeague,
      { id }
    );

export const deleteRenewableLeague =
  (id: number): ThunkResult<Promise<void>> =>
  async (dispatch) =>
    apiDelete(
      `leagues-renewable/${id}/`,
      {},
      dispatch,
      actions.deleteRenewableLeague,
      { id }
    );

export const fetchClassicLeagueStandings =
  (
    leagueId: number,
    pageNewEntries: number,
    pageStandings: number,
    phaseId: number
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `leagues-classic/${leagueId}/standings/` +
          `?page_new_entries=${pageNewEntries}&page_standings=${pageStandings}` +
          `&phase=${phaseId}`,
        dispatch,
        actions.fetchClassicLeagueStandings,
        { phaseId }
      );
    } catch (e) {
      return;
    }
  };

export const fetchH2HLeagueStandings =
  (
    leagueId: number,
    pageNewEntries: number,
    pageStandings: number
  ): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `leagues-h2h/${leagueId}/standings/` +
          `?page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`,
        dispatch,
        actions.fetchH2HLeagueStandings
      );
    } catch (e) {
      return;
    }
  };

export const fetchH2HLeagueMatches = (
  leagueId: number,
  entryId: number,
  eventId: number,
  page: number
): ThunkResult<Promise<void>> => {
  let url = `leagues-h2h-matches/league/${leagueId}/?page=${page}`;
  if (entryId) {
    url += `&entry=${entryId}`;
  }
  if (eventId) {
    url += `&event=${eventId}`;
  }
  return async (dispatch) => {
    try {
      await apiGet(url, dispatch, actions.fetchH2HLeagueMatches, {
        entryId,
        eventId,
        leagueId,
      });
    } catch (e) {
      return;
    }
  };
};

export const checkLeagueCode =
  (code: string): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiPost(
        `league/code-check/`,
        { code },
        dispatch,
        actions.checkLeagueCode,
        {
          code,
        }
      );
    } catch (e) {
      dispatch(clearCode());
    }
  };

export const setCode =
  (code: string): ThunkResult<void> =>
  (dispatch) => {
    setValue(localStorageKey, code);
    dispatch(actions.setAutoJoinCode(code));
  };

export const clearCode = (): ThunkResult<void> => (dispatch) => {
  clearValue(localStorageKey);
  dispatch(actions.clearAutoJoinCode());
};

export const fetchLeagueEntries =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet(
        `league/${leagueId}/entries/`,
        dispatch,
        actions.fetchLeagueEntries,
        {
          leagueId,
        }
      );
    } catch (e) {
      // Error will be in store and available via a selector
      return;
    }
  };

export const fetchLeagueCupStatus =
  (leagueId: number): ThunkResult<Promise<void>> =>
  async (dispatch) =>
    apiGet(
      `league/${leagueId}/cup-status/`,
      dispatch,
      actions.fetchLeagueCupStatus
    );

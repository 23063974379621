import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import { addPlayer, addWatched } from "../bootstrap/actions";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  data: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(addPlayer):
        return action.payload;
      default:
        return state;
    }
  },
  loginError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.login.failure):
        return action.payload;
      default:
        return null;
    }
  },
  passwordChangeError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.passwordChange.failure):
        return action.payload;
      default:
        return null;
    }
  },
  passwordResetError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.passwordReset.failure):
        return action.payload;
      default:
        return null;
    }
  },
  passwordSetError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.passwordSet.failure):
        return action.payload;
      default:
        return null;
    }
  },
  playerUpdateError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.playerUpdate.failure):
        return action.payload;
      default:
        return null;
    }
  },
  registerError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.register.failure):
        return action.payload;
      default:
        return null;
    }
  },
  watched: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(addWatched):
        return action.payload;
      case getType(actions.addToWatched):
        return state.indexOf(action.payload) === -1
          ? [...state, action.payload]
          : state;
      case getType(actions.removeFromWatched):
        return state.filter((code) => code !== action.payload);
      default:
        return state;
    }
  },
});

// State Selectors
export const getPlayerData = (state: RootState) => state.player.data;

export const getWatched = (state: RootState) => state.player.watched;

export const getLoginError = (state: RootState) => state.player.loginError;

export const getPasswordChangeError = (state: RootState) =>
  state.player.passwordChangeError;

export const getPasswordResetError = (state: RootState) =>
  state.player.passwordResetError;

export const getPasswordSetError = (state: RootState) =>
  state.player.passwordSetError;

export const getPlayerUpdateError = (state: RootState) =>
  state.player.playerUpdateError;

export const getRegisterError = (state: RootState) =>
  state.player.registerError;

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import { IDreamTeamData } from "./types";
import * as actions from "./actions";

export const fetchOverallDreamTeam =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IDreamTeamData>(
        "dream-team/",
        dispatch,
        actions.fetchOverallDreamTeam
      );
    } catch (e) {
      return;
    }
  };

export const fetchEventDreamTeam =
  (event: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IDreamTeamData>(
        `dream-team/${event}/`,
        dispatch,
        actions.fetchEventDreamTeam,
        { event }
      );
    } catch (e) {
      return;
    }
  };

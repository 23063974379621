import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { RootState } from "../core/store";
import { getLoginError, getPlayerData } from "../core/store/player/reducers";
import Alert from "./Alert";
import Copy from "./Copy";
import { KeycloakLoginLinks } from "./KeycloakLoginLinks";

const Promo = styled.div`
  margin: ${({ theme }) => `0 ${theme.space[4]} 6rem ${theme.space[4]} `};
  padding: ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    text-align: start;
  }
`;

const PromoLeft = styled.div`
  margin: ${({ theme }) => theme.space[4]} 0;
  text-align: inherit;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(70% - 1rem);
    margin: 0;
    padding-inline-end: ${({ theme }) => theme.space[4]};
  }
`;

const PromoRight = styled.div`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex: 0 0 calc(30% - 1rem);
    align-self: center;
    > a {
      text-transform: capitalize;
    }
  }
`;

const PromoHeading = styled.h3`
  margin: 0;
  font-size: 2.1rem;
  font-family: ${({ theme }) => theme.fonts.impact};
  color: ${({ theme }) => theme.colors.green};
`;

const PromoText = styled.p`
  color: ${({ theme }) => theme.colors.white};
`;

const mapState = (state: RootState) => ({
  player: getPlayerData(state),
  error: getLoginError(state),
});

const connector = connect(mapState);

type PropsFromRedux = ConnectedProps<typeof connector>;
const LOGIN_ERROR = "kc_loginError";
const userError = localStorage.getItem(LOGIN_ERROR);

const Login: React.FC<PropsFromRedux> = ({ error, player }) => {
  const { t } = useTranslation();
  if (error) {
    return (
      <Box mb={2}>
        <Alert type="error">
          {t("login.incorrectDetails", "Incorrect login details")}
        </Alert>
      </Box>
    );
  }
  if (player && player.id) {
    return (
      <Box mb={4} p={4}>
        <Copy>
          <p>
            {t(
              "login.loggedInAs",
              "You are logged in as {{firstName}} {{lastName}}",
              {
                firstName: player.first_name,
                lastName: player.last_name,
              }
            )}
          </p>
        </Copy>
      </Box>
    );
  }

  return (
    <>
      {userError && (
        <Box my={2}>
          <Alert type="error">
            {t(
              "login.error",
              "There was a problem with your account, please contact the support team"
            )}
          </Alert>
        </Box>
      )}
      <Promo>
        <PromoLeft>
          <Box>
            <Box my={2}>
              <PromoHeading>
                {t("login.promo.heading", "Play RSL Fantasy")}
              </PromoHeading>
            </Box>
            <Box my={2}>
              <PromoText>
                {t(
                  "login.promo.paragraph",
                  "Who really is the best at RSL? Challenge your friends in Fantasy RSL and have the chance to win both honour and great prizes every round!"
                )}
              </PromoText>
            </Box>
          </Box>
        </PromoLeft>
        <PromoRight>
          <Flex flexDirection={"column"}>
            <KeycloakLoginLinks />
          </Flex>
        </PromoRight>
      </Promo>
    </>
  );
};

export default connector(Login);

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getPlayerData } from "../../core/store/player/reducers";
import Register from "../player/Register";
import CreateEntryDialog from "./CreateEntryDialog";
import SquadBase from "./SquadBase";
import SquadListTable from "./SquadListTable";

const SquadSelection = () => {
  const { t } = useTranslation();
  const player = useSelector(getPlayerData);
  if (!player || player.dirty) {
    return <Register />;
  }
  return (
    <SquadBase
      listTable={(listTableProps) => <SquadListTable {...listTableProps} />}
      scoreboard="basic"
      submitDialog={(handleHide) => (
        <CreateEntryDialog handleHide={handleHide} />
      )}
      title={t("squad.title", "Squad Selection")}
    />
  );
};

export default SquadSelection;

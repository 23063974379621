import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import range from "lodash/range";
import { hideVisually, size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import { fetchEventLive } from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import {
  getEntry,
  getEntryEventFormation,
  getEntryEventPicks,
  getEntryEventPoints,
} from "../../core/store/entries/reducers";
import {
  fetchEntryEventPicks,
  fetchEntrySummary,
} from "../../core/store/entries/thunks";
import {
  IEntry,
  IEntryEvent,
  IPickLight,
} from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { fetchFixtures } from "../../core/store/fixtures/thunks";
import { IFixture } from "../../core/store/fixtures/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { ReactComponent as BaseDreamTeam } from "../../img/icons/dreamteam.svg";
import { ReactComponent as BaseReload } from "../../img/icons/reload.svg";
import { getChipName, ChipName } from "../../utils/chips";
import ButtonLink from "../ButtonLink";
import Entry from "../Entry";
import Fixtures from "../Fixtures";
import { ControlArrowRight, ArrowHyphen } from "../icons/Arrows";
import DreamTeam from "../icons/DreamTeam";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import {
  EventPager,
  PagerButton,
  PagerButtonNext,
  PagerHeading,
} from "../Pager";
import { ElementRow } from "../Pitch";
import { NotFound } from "../Routes";
import Table from "../Table";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Title from "../Title";
import Bench from "./Bench";
import BenchUnit from "./BenchUnit";
import ElementExplainDialog from "./ElementExplainDialog";
import EntryEventTable from "./EntryEventTable";
import PitchFormation from "./PitchFormation";
import { isRTL } from "../../utils/locale";
import valueForPlayerElement from "./valueForPlayedElement";
import { WithTranslation, withTranslation } from "react-i18next";
import Panel from "../Panel";
import PageAd from "../PageAd";

const PointsScoreboardWrap = styled.div`
  border: ${({ theme }) => theme.borders[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
  margin: ${({ theme }) => theme.space[2]} 0;
  padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[1]} 0;
`;

const StyledPitchWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.radii[2]};
`;

const PointsScoreboard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding: ${({ theme }) => theme.space[2]} 0;
  margin: 0 ${({ theme }) => theme.space[2]};
`;

const ScoreboardPrimary = styled.div`
  flex: 0 0 38%;
  padding-inline-end: ${({ theme }) => theme.space[2]};
  padding-inline-start: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    flex: 0 0 34%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: calc(22% - ${({ theme }) => theme.space[1]});
    margin-bottom: 0;
  }
`;

interface IPrimaryPanelProps {
  chipPlayed?: boolean;
}

const PrimaryPanel = styled.div<IPrimaryPanelProps>`
  position: relative;
  border-radius: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
  text-align: center;

  ${(props) =>
    props.chipPlayed &&
    css`
      padding-top: 2rem;
      padding-bottom: 1.8rem;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0;
  }
`;

const PrimaryHeading = styled.h4`
  margin: 0;
  padding: 1.5rem;
  font-size: ${({ theme }) => theme.fontSizes[1]};

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-top: 0.8rem;
    padding-bottom: 0.7rem;
  }
`;

const PrimaryValue = styled.div`
  padding-top: ${({ theme }) => theme.space[3]};
  color: ${({ theme }) => theme.colors.white};
  font-size: 3rem;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    font-size: 4rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding-bottom: 0.3rem;
  }
`;

const ReloadButton = styled.button`
  position: absolute;
  top: 40%;
  right: ${isRTL() && `0`};
  left: ${!isRTL() && `0`};
  transform: ${isRTL()
    ? `translateY(-50%) scaleX(-1) `
    : `translateY(-50%) scaleX(1) `};
  padding: ${({ theme }) => theme.space[1]};
  border: 0;
  border-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.primary};
  line-height: 0;
  cursor: pointer;
`;

const Reload = styled(BaseReload)`
  fill: white;
`;

const ReloadButtonText = styled.span`
  ${hideVisually()}
`;

const ScoreboardSecondary = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SecondaryPanel = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

const SecondaryItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  border: 3px solid black;
  border-radius: ${({ theme }) => theme.radii[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 45%;
    margin: 0;
  }
`;

const SecondaryHeading = styled.h4`
  margin-top: 1.7rem;
  margin-bottom: 1.7rem;
  font-family: ${({ theme }) => theme.fonts.base};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: normal;
`;

const SecondaryValue = styled.div`
  padding-bottom: 0.7rem;
  font-size: ${({ theme }) => theme.fontSizes[5]};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: ${({ theme }) => theme.fontSizes[6]};
  }
  color: ${({ theme }) => theme.colors.blue};
`;

const DreamTeamWrap = styled.div`
  width: 40%;
  margin: 0 auto ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 25%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: 30%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 20%;
  }
`;

const DreamTeamLinkIcon = styled(BaseDreamTeam)`
  ${size(16)}
  margin-inline-end: ${({ theme }) => theme.space[1]};
`;

const DreamTeamLink = styled(ReachLink)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: ${({ theme }) => theme.radii[1]};
  padding: 0.6rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.green};
  text-decoration: none;
`;

const ChipStatus = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${({ theme }) => theme.space[1]};
  }
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
`;

type OwnProps = RouteComponentProps<{ entryId: string; eventId: string }> &
  WithTranslation;

interface IPropsFromState {
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  entry: IEntry | null;
  entryData: IEntryEvent | null;
  event: IEvent | null;
  fixturesById: Record<string, IFixture> | null;
  formation: string;
  mine: boolean;
  now: IEvent | null;
  points: number;
  settings: ISettings;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEntryEventPicks: (entryId: number, eventId: number) => void;
  fetchEntrySummary: (entryId: number) => void;
  fetchEventLive: (entryId: number) => void;
  fetchFixturesForEvent: (eventId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch;

interface IState {
  pickForMenu: IPickLight | null;
}

class EntryEvent extends React.Component<Props, IState> {
  public state: IState = {
    pickForMenu: null,
  };

  public handleShowMenuForPickElement = (element: number) => {
    const matches = this.props.entryData!.picks.filter(
      (p) => p.element === element
    );
    if (matches.length) {
      this.setState({ pickForMenu: matches[0] });
    }
  };

  public handleHideMenuForPick = () => {
    this.setState({ pickForMenu: null });
  };

  public renderDreamTeam = (pick: IPickLight) =>
    this.props.elementsDataById &&
    this.props.elementsDataById[pick.element] &&
    this.props.elementsDataById[pick.element].stats.in_dreamteam ? (
      <Link to={`/dream-team/${this.props.event!.id}`}>
        <DreamTeam />
      </Link>
    ) : null;

  public fetchDataAndFixtures = () => {
    this.fetchData();
    this.props.fetchFixturesForEvent(Number(this.props.eventId));
  };

  public fetchData = () => {
    this.props.fetchEntrySummary(Number(this.props.entryId));
    this.props.fetchEntryEventPicks(
      Number(this.props.entryId),
      Number(this.props.eventId)
    );
    this.props.fetchEventLive(Number(this.props.eventId));
  };

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.eventId !== this.props.eventId ||
      prevProps.entryId !== this.props.entryId
    ) {
      this.fetchData();
    }
  }
  public render() {
    const {
      elementsById,
      elementsDataById,
      entry,
      entryData,
      event,
      fixturesById,
      formation,
      mine,
      now,
      points,
      settings,
      t,
      teamsById,
    } = this.props;
    // Handle unknown and unstarted events
    if (!now || !event || event.id > now.id) {
      return <NotFound />;
    }
    if (!entry || !entryData) {
      return null;
    }

    const chipName = entryData.active_chip
      ? getChipName(entryData.active_chip as ChipName, t)
      : "";

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = valueForPlayerElement({
      elementsById,
      elementsDataById,
      fixturesById,
      teamsById,
    });
    const benchHeading: Record<string, React.ReactNode> = {
      11: (
        <abbr title={t("entryEvent.goalkeeper.title", "Goalkeeper")}>
          {t("entryEvent.goalkeeper.abbr", "GK")}
        </abbr>
      ),
      12: "1",
      13: "2",
      14: "3",
    };

    // Handle events before the entry started
    return (
      <Wrapper>
        <Main>
          <Box mx={2} mb={4}>
            <Title>
              {t("entryEvent.title", "Points")} - {entry.name}
            </Title>
          </Box>

          {/* ROUND PAGER */}
          <PointsScoreboardWrap>
            <Box>
              {(event.id > entry.started_event || event.id < now.id) && (
                <EventPager>
                  {event.id > entry.started_event && (
                    <PagerButton>
                      <ButtonLink
                        to={`/entry/${entry.id}/event/${event.id - 1}`}
                        variant="secondary"
                        justifyContent="flexStart"
                      >
                        <ArrowHyphen />
                        {t("entryEvent.prev", "Previous")}
                      </ButtonLink>
                    </PagerButton>
                  )}
                  <Box mx={2}>
                    <PagerHeading>{event.name}</PagerHeading>
                  </Box>
                  {event.id < now.id && (
                    <PagerButtonNext>
                      <ButtonLink
                        to={`/entry/${entry.id}/event/${event.id + 1}`}
                        variant="secondary"
                        justifyContent="flexEnd"
                      >
                        {t("entryEvent.next", "Next")}
                        <ArrowHyphen />
                      </ButtonLink>
                    </PagerButtonNext>
                  )}
                  {/* Also need to check isn't last event */}
                  {mine && event.id === now.id && (
                    <PagerButtonNext>
                      <ButtonLink
                        to={`/my-team`}
                        variant="secondary"
                        justifyContent="flexEnd"
                      >
                        {t("entryEvent.next", "Next")}
                        <ArrowHyphen />
                      </ButtonLink>
                    </PagerButtonNext>
                  )}
                </EventPager>
              )}
            </Box>

            {/* SCOREBOARD */}

            <Box mb={4}>
              <PointsScoreboard>
                <ScoreboardSecondary>
                  <SecondaryPanel>
                    <SecondaryItem>
                      <SecondaryHeading>
                        {t("entryEvent.average", "Average Pts")}
                      </SecondaryHeading>
                      <SecondaryValue>
                        {event.average_entry_score || "-"}
                      </SecondaryValue>
                    </SecondaryItem>
                    <SecondaryItem>
                      <SecondaryHeading>
                        {t("entryEvent.highest", "Highest Pts")}
                      </SecondaryHeading>
                      <SecondaryValue>
                        {event.highest_scoring_entry ? (
                          <Link
                            to={`/entry/${event.highest_scoring_entry}/event/${event.id}`}
                          >
                            {event.highest_score || "???"}
                          </Link>
                        ) : (
                          "-"
                        )}
                      </SecondaryValue>
                    </SecondaryItem>
                  </SecondaryPanel>
                  <ScoreboardPrimary>
                    <Box>
                      <PrimaryPanel chipPlayed={Boolean(chipName)}>
                        <PrimaryValue>
                          {points}
                          {!event.finished && (
                            <ReloadButton onClick={this.fetchDataAndFixtures}>
                              <ReloadButtonText>
                                {t("entryEvent.reload", "Reload Points")}
                              </ReloadButtonText>
                              <Reload />
                            </ReloadButton>
                          )}
                        </PrimaryValue>
                        <PrimaryHeading>
                          {event.finished
                            ? t("entryEvent.finalPoints", "Final Points")
                            : t("entryEvent.latestPoints", "Latest Points")}
                        </PrimaryHeading>
                        {chipName && (
                          <ChipStatus>
                            {t(
                              "entryEvent.ChipStatus.played",
                              "{{ chipName }} played",
                              {
                                chipName: chipName,
                              }
                            )}
                          </ChipStatus>
                        )}
                      </PrimaryPanel>
                    </Box>
                  </ScoreboardPrimary>
                  <SecondaryPanel>
                    <SecondaryItem>
                      <SecondaryHeading>
                        {t("entryEvent.rank", "RD Rank")}
                      </SecondaryHeading>
                      <SecondaryValue>
                        {entryData.entry_history.rank
                          ? entryData.entry_history.rank.toLocaleString()
                          : "-"}
                      </SecondaryValue>
                    </SecondaryItem>
                    <SecondaryItem>
                      <SecondaryHeading>
                        {t("entryEvent.transfers", "Transfers")}
                      </SecondaryHeading>
                      <SecondaryValue>
                        <Link to={`/entry/${entry.id}/transfers`}>
                          {entryData.entry_history.event_transfers}
                        </Link>{" "}
                        {entryData.entry_history.event_transfers_cost
                          ? `(-${entryData.entry_history.event_transfers_cost} pts)`
                          : ""}
                      </SecondaryValue>
                    </SecondaryItem>
                  </SecondaryPanel>
                </ScoreboardSecondary>
              </PointsScoreboard>
              <DreamTeamWrap>
                <DreamTeamLink to={`/dream-team/${event.id}`}>
                  <DreamTeamLinkIcon />
                  {t("entryEvent.dreamTeam", "Dream Team")}
                  <ControlArrowRight />
                </DreamTeamLink>
              </DreamTeamWrap>
            </Box>
          </PointsScoreboardWrap>

          {/* TEAM */}
          <StyledPitchWrapper bg="greyLight3" pt={4}>
            <Box mx={2} mb={2}>
              <Tabs centered>
                <TabPanel
                  label={t("entryEvent.pitchView", "Pitch View")}
                  link="pitch"
                >
                  <Box mx={-2} pt={4}>
                    <PitchFormation
                      chipName={entryData.active_chip}
                      formation={formation}
                      picks={entryData.picks}
                      renderDreamTeam={this.renderDreamTeam}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      renderPickValue={renderPickValue}
                    />
                    <Bench>
                      <ElementRow>
                        {range(
                          settings.squad_squadplay,
                          settings.squad_squadsize
                        ).map((i) => (
                          <BenchUnit
                            key={i}
                            chipName={entryData.active_chip}
                            heading={benchHeading[i]}
                            pick={entryData.picks[i]}
                            renderDreamTeam={this.renderDreamTeam}
                            renderElementMenu={() =>
                              this.handleShowMenuForPickElement(
                                entryData.picks[i].element
                              )
                            }
                            renderPickValue={renderPickValue}
                          />
                        ))}
                      </ElementRow>
                    </Bench>
                  </Box>
                </TabPanel>
                <TabPanel
                  label={t("entryEvent.listView", "List View")}
                  link="list"
                >
                  <Box my={4} bg="white">
                    <EntryEventTable
                      title={t("entryEvent.starters", "Starters")}
                      picks={entryData.picks.slice(0, settings.squad_squadplay)}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      chipName={entryData.active_chip}
                      dataById={elementsDataById}
                    />
                    <EntryEventTable
                      title={t("entryEvent.substitutes", "Substitutes")}
                      picks={entryData.picks.slice(settings.squad_squadplay)}
                      renderElementMenu={this.handleShowMenuForPickElement}
                      chipName={entryData.active_chip}
                      dataById={elementsDataById}
                    />
                  </Box>
                </TabPanel>
              </Tabs>
            </Box>
          </StyledPitchWrapper>
          {entryData.automatic_subs.length > 0 && (
            <Box my={4}>
              <Panel>
                <Panel.Header
                  title={t(
                    "entryEvent.autoSubs.title",
                    "Automatic Substitutions"
                  )}
                ></Panel.Header>

                <Table>
                  <thead>
                    <tr>
                      <th>
                        {t("entryEvent.autoSubs.playerOut", "Player out")}
                      </th>
                      <th>{t("entryEvent.autoSubs.playerIn", "Player in")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entryData.automatic_subs.map((s) => (
                      <tr key={s.element_out}>
                        <td>{elementsById[s.element_out].web_name}</td>
                        <td>{elementsById[s.element_in].web_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Panel>
            </Box>
          )}
          {this.state.pickForMenu && (
            <ElementExplainDialog
              elementId={this.state.pickForMenu.element}
              eventId={event.id}
              closeDialog={this.handleHideMenuForPick}
            />
          )}
          <Box my={6}>
            <PageAd />
          </Box>
          <Fixtures eventId={event.id} />
        </Main>
        <Secondary>
          <Entry entryId={entry.id} />
        </Secondary>
      </Wrapper>
    );
  }
}
export { EntryEvent as EntryEventTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const entryId = Number(ownProps.entryId);
  const eventId = Number(ownProps.eventId);
  const player = getPlayerData(state) as IPlayer;
  return {
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    entry: getEntry(state, entryId),
    event: getEventsById(state)[eventId],
    now: getCurrentEvent(state),
    entryData: getEntryEventPicks(state, entryId, eventId),
    fixturesById: getFixturesForEventById(state, eventId),
    formation: getEntryEventFormation(state, entryId, eventId),
    points: getEntryEventPoints(state, entryId, eventId),
    settings: getSettings(state) as ISettings,
    teamsById: getTeamsById(state),
    mine: Boolean(player && player.entry && player.entry === entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntryEventPicks: (entryId, eventId) =>
    dispatch(fetchEntryEventPicks(entryId, eventId)),
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
});

// Have to split declarations due to TS error - https://github.com/DefinitelyTyped/DefinitelyTyped/issues/50211
const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(EntryEvent));
const translated = withTranslation()(connected);

export default translated;

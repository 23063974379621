import { format } from "date-fns";
import * as React from "react";
import { Trans, WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import { getElementStatsByIdentifier } from "../core/store/element-stats/reducers";
import { IElementStat } from "../core/store/element-stats/types";
import { getElementsById } from "../core/store/elements/reducers";
import { showElementSummary } from "../core/store/elements/thunks";
import { IElementsById } from "../core/store/elements/types";
import {
  getCurrentEvent,
  getEventsById,
  getNextEvent,
} from "../core/store/events/reducers";
import { IEvent, IEventsById } from "../core/store/events/types";
import { getGroupedFixturesByEvent } from "../core/store/fixtures/reducers";
import { fetchFixtures } from "../core/store/fixtures/thunks";
import {
  IFixtureStat,
  IFixtureStats,
  IGroupedFixturesByEvent,
} from "../core/store/fixtures/types";

import { formatRawAsISO, formatRawAsLocalI18n } from "../core/utils/datetime";
import { dateLocales } from "../i18n";
import Button from "./Button";
import Fixture from "./Fixture";
import { Pager, PagerButton, PagerButtonNext } from "./Pager";
import { ArrowHyphen } from "./icons/Arrows";

const Deadline = styled.h2`
  margin: ${({ theme }) => theme.space[2]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.impact};
  text-transform: uppercase;
  font-weight: 600;
`;

const Heading = styled.h3`
  margin: ${({ theme }) => theme.space[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.impact};
`;

const Note = styled.p`
  color: #6c6c6c;
  text-align: center;
`;

const FixtureDay = styled.h4`
  margin: 0;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  text-align: center;
`;

const FixtureTime = styled.time`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};
`;

const FixtureList = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
  list-style: none;
`;

const FixtureItem = styled.li`
  margin-bottom: ${({ theme }) => theme.space[2]};
  padding: 0;
  font-size: 1.1rem;
  list-style: none;
`;

const FixtureButton = styled.button<IStyledFixtureStatsProps>`
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  text-align: start;
  cursor: pointer;

  ${(props) =>
    props.isOpen &&
    css`
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    `}
`;

const FixtureStatsWrap = styled.div<IStyledFixtureStatsProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  border-top: 2px solid ${({ theme }) => theme.colors.whiteGrey};
  border-end-start-radius: ${({ theme }) => theme.radii[2]};
  border-end-end-radius: ${({ theme }) => theme.radii[2]};
  padding: ${({ theme }) => `${theme.space[2]} 5%`};
  background-color: ${({ theme }) => theme.colors.whiteGrey};

  @media (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding-inline-end: 15%;
    padding-inline-start: 15%;
  }
`;

const FixtureStatsOuterList = styled.ul`
  list-style: none;
  padding: 0;
`;

const FixtureStat = styled.li`
  margin-bottom: ${({ theme }) => theme.space[4]};
  border-end-start-radius: ${({ theme }) => theme.radii[1]};
  border-end-end-radius: ${({ theme }) => theme.radii[1]};
  background-color: white;
`;

const FixtureStatHeading = styled.h5`
  margin: 0;
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  border-end-start-radius: ${({ theme }) => theme.radii[0]};
  border-end-end-radius: ${({ theme }) => theme.radii[0]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: ${({ theme }) => theme.fontSizes[0]};
  text-align: center;
`;

const FixtureStatBody = styled.div`
  display: flex;
  padding-top: ${({ theme }) => theme.space[2]};
  padding-bottom: ${({ theme }) => theme.space[2]};
  border-end-start-radius: ${({ theme }) => theme.radii[1]};
  border-end-end-radius: ${({ theme }) => theme.radii[1]};
`;

const FixtureStatList = styled.ul`
  flex: 1;
  padding-inline-end: ${({ theme }) => theme.space[2]};
  padding-inline-start: ${({ theme }) => theme.space[2]};

  :first-child {
    border-inline-end: 1px dotted ${({ theme }) => theme.colors.blue};
    text-align: end;
  }
`;

const FixtureStatItem = styled.li`
  padding-top: ${({ theme }) => theme.space[1]};
  padding-bottom: ${({ theme }) => theme.space[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colors.whiteGrey};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  list-style: none;

  :last-child {
    border-bottom: 0;
  }
`;

const ElementButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes[2]};

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

interface IFixtureStatsForTeamProps {
  elementsById: IElementsById;
  showElementDialog: (elementId: number) => void;
  stats: IFixtureStat[];
}

const FixtureStatsForTeam: React.FC<IFixtureStatsForTeamProps> = ({
  elementsById,
  showElementDialog,
  stats,
}) => (
  <FixtureStatList>
    {stats
      .filter((fs) => elementsById[fs.element])
      .map((fs) => (
        <FixtureStatItem key={fs.element}>
          <ElementButton onClick={(e: any) => showElementDialog(fs.element)}>
            {elementsById[fs.element].web_name}
          </ElementButton>{" "}
          ({fs.value})
        </FixtureStatItem>
      ))}
  </FixtureStatList>
);

interface IFixtureStatsProps {
  elementsById: IElementsById;
  showElementDialog: (elementId: number) => void;
  stats: IFixtureStats[];
  statsByIdentifier: Record<string, IElementStat>;
}

interface IStyledFixtureStatsProps {
  isOpen?: boolean;
}

type FixtureStatProps = IFixtureStatsProps & IStyledFixtureStatsProps;

const FixtureStats: React.FC<FixtureStatProps> = ({
  elementsById,
  isOpen = false,
  showElementDialog,
  stats,
  statsByIdentifier,
}) => {
  // Our render stats should contain only stats with data and a maximum
  // of 5 per team of BPS
  const renderStats = stats
    .filter((stat) => stat.h.length || stat.a.length)
    .map((stat) =>
      stat.identifier === "bps"
        ? {
            identifier: stat.identifier,
            h: stat.h.slice(0, 5),
            a: stat.a.slice(0, 5),
          }
        : stat
    );
  return (
    <FixtureStatsWrap isOpen={isOpen}>
      <FixtureStatsOuterList>
        {renderStats.map((stat) => (
          <FixtureStat key={stat.identifier}>
            <FixtureStatHeading>
              {statsByIdentifier[stat.identifier].label}
            </FixtureStatHeading>
            <FixtureStatBody>
              <FixtureStatsForTeam
                stats={stat.h}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
              <FixtureStatsForTeam
                stats={stat.a}
                elementsById={elementsById}
                showElementDialog={showElementDialog}
              />
            </FixtureStatBody>
          </FixtureStat>
        ))}
      </FixtureStatsOuterList>
    </FixtureStatsWrap>
  );
};

interface IOwnProps {
  eventId?: number;
  useLinks?: boolean;
}

interface IPropsFromDispatch {
  fetchFixturesForEvent: (eventId: number) => void;
  showElementDialog: (elementId: number) => void;
}

interface IPropsFromState {
  currentEvent: IEvent | null;
  elementsById: IElementsById;
  eventsById: IEventsById;
  groupedFixturesByEvent: IGroupedFixturesByEvent;
  nextEvent: IEvent | null;
  statsByIdentifier: Record<string, IElementStat>;
}

type Props = IOwnProps & WithTranslation & IPropsFromDispatch & IPropsFromState;

interface IState {
  eventId: number;
  isOpen: Record<string, boolean>;
}

declare global {
  interface Window {
    postscribe: any;
  }
}

class Fixtures extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);
    let eventId = 0;
    if (props.eventId) {
      eventId = props.eventId;
    } else if (props.nextEvent) {
      eventId = props.nextEvent.id;
    } else if (props.currentEvent) {
      eventId = props.currentEvent.id;
    }
    this.state = { eventId, isOpen: {} };
  }

  public componentDidMount() {
    this.props.fetchFixturesForEvent(this.state.eventId);
  }

  public changeEvent = (eventId: number) => {
    this.props.fetchFixturesForEvent(eventId);
    this.setState({ eventId });
  };

  public toggle = (fixtureId: number) => {
    this.setState({
      isOpen: {
        ...this.state.isOpen,
        [fixtureId]: !this.state.isOpen[fixtureId],
      },
    });
  };

  public render() {
    const {
      elementsById,
      eventsById,
      groupedFixturesByEvent,
      i18n,
      showElementDialog,
      statsByIdentifier,
      t,
      useLinks = false,
    } = this.props;
    const { eventId } = this.state;
    const event = eventsById[eventId];
    if (!event) {
      return null;
    }

    const eventFixtureGroups = groupedFixturesByEvent[eventId] || [];
    return (
      <section>
        <header>
          <Deadline>
            {event.name} -{" "}
            <time dateTime={formatRawAsISO(event.deadline_time)}>
              {formatRawAsLocalI18n(
                event.deadline_time,
                dateLocales[i18n.language]
              )}
            </time>
          </Deadline>
          <Heading>
            <span>{t("fixtures.title", "Fixtures")}</span>
          </Heading>
          <div>
            <Pager>
              {eventsById[eventId - 1] && (
                <PagerButton>
                  {useLinks ? (
                    <Button
                      width={1}
                      to={`/fixtures/${eventId - 1}`}
                      onClick={() => this.changeEvent(eventId - 1)}
                      variant="secondary"
                      textTransform="upperCase"
                      justifyContent="flexStart"
                      startIcon={<ArrowHyphen color="black" />}
                    >
                      {t("fixtures.previous", "Previous")}
                    </Button>
                  ) : (
                    <Button
                      width={1}
                      onClick={() => this.changeEvent(eventId - 1)}
                      variant="secondary"
                      justifyContent="flexStart"
                      textTransform="upperCase"
                      startIcon={<ArrowHyphen color="black" />}
                    >
                      {t("fixtures.previous", "Previous")}
                    </Button>
                  )}
                </PagerButton>
              )}
              {eventsById[eventId + 1] && (
                <PagerButtonNext>
                  {useLinks ? (
                    <Button
                      width={1}
                      to={`/fixtures/${eventId + 1}`}
                      onClick={() => this.changeEvent(eventId + 1)}
                      variant="secondary"
                      textTransform="upperCase"
                      justifyContent="flexEnd"
                      endIcon={<ArrowHyphen color="black" />}
                    >
                      {t("fixtures.next", "Next")}
                    </Button>
                  ) : (
                    <Button
                      width={1}
                      onClick={() => this.changeEvent(eventId + 1)}
                      disabled={!eventsById[eventId + 1]}
                      variant="secondary"
                      textTransform="upperCase"
                      justifyContent="flexEnd"
                      endIcon={<ArrowHyphen color="black" />}
                    >
                      {t("fixtures.next", "Next")}
                    </Button>
                  )}
                </PagerButtonNext>
              )}
            </Pager>
          </div>
        </header>
        <Box p={2}>
          <Note>
            <Trans i18nKey="fixtures.localTimes">
              All times are shown in your <strong>local time</strong>
            </Trans>
          </Note>
        </Box>
        {eventFixtureGroups.map((group) => (
          <Box mx="0.4rem" mb={4} key={group.date.toISOString()}>
            <FixtureDay>
              <FixtureTime dateTime={group.date.toISOString()}>
                {format(group.date, "EEEE d MMMM yyyy")}
              </FixtureTime>
            </FixtureDay>
            <FixtureList>
              {group.fixtures.map((f) => (
                <React.Fragment key={f.id}>
                  {f.started && f.team_h_score !== null ? (
                    <FixtureItem>
                      <FixtureButton
                        onClick={() => this.toggle(f.id)}
                        isOpen={this.state.isOpen[f.id]}
                      >
                        <Fixture isOpen={this.state.isOpen[f.id]} fixture={f} />
                      </FixtureButton>
                      <FixtureStats
                        stats={f.stats}
                        elementsById={elementsById}
                        showElementDialog={showElementDialog}
                        statsByIdentifier={statsByIdentifier}
                        isOpen={this.state.isOpen[f.id]}
                      />
                    </FixtureItem>
                  ) : (
                    <FixtureItem>
                      <Fixture fixture={f} />
                    </FixtureItem>
                  )}
                </React.Fragment>
              ))}
            </FixtureList>
          </Box>
        ))}
      </section>
    );
  }
}

export { Fixtures as FixturesTest };

const mapStateToProps = (state: RootState, ownProps: IOwnProps) => ({
  currentEvent: getCurrentEvent(state),
  elementsById: getElementsById(state),
  eventsById: getEventsById(state),
  groupedFixturesByEvent: getGroupedFixturesByEvent(state),
  nextEvent: getNextEvent(state),
  statsByIdentifier: getElementStatsByIdentifier(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchFixturesForEvent: (eventId) => dispatch(fetchFixtures(eventId)),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Fixtures)
);

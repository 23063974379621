import React from "react";
import { connect } from "react-redux";
import { getElements } from "../../core/store/elements/reducers";
import { getElementTypes } from "../../core/store/element-types/reducers";
import { getTeamsById } from "../../core/store/teams/reducers";
import { RootState } from "../../core/store";
import { ITeam, ITeamsById } from "../../core/store/teams/types";
import { IElement } from "../../core/store/elements/types";
import { IElementType } from "../../core/store/element-types/types";
import { RouteComponentProps } from "@reach/router";
import { integerToMoney } from "../../core/utils/money";
import { Wrapper, Main } from "../Layout";
import Table from "../Table";
import SubHeading from "../SubHeading";

interface IElementListElementProps {
  element: IElement;
  team: ITeam;
}
const ElementListElement: React.FC<IElementListElementProps> = ({
  element,
  team,
}) => (
  <tr>
    <td>{element.web_name}</td>
    <td>{team.name}</td>
    <td>{element.total_points}</td>
    <td>SAR{integerToMoney(element.now_cost, 10)}</td>
  </tr>
);

interface IElementListTableProps {
  elements: IElement[];
  teamsById: ITeamsById;
}
const ElementListTable: React.FC<IElementListTableProps> = ({
  elements,
  teamsById,
}) => (
  <Table>
    <thead>
      <tr>
        <th className="ism-table--el-list-print__name">Player</th>
        <th className="ism-table--el-list-print__team">Team</th>
        <th className="ism-table--el-list-print__pts">Points</th>
        <th className="ism-table--el-list-print__rank">Cost</th>
      </tr>
    </thead>
    <tbody>
      {elements.map((element) => (
        <ElementListElement
          element={element}
          key={element.id}
          team={teamsById[element.team]}
        />
      ))}
    </tbody>
  </Table>
);

interface IElementListPositionProps {
  elements: IElement[];
  elementType: IElementType;
  teamsById: ITeamsById;
}
const ElementListPosition: React.FC<IElementListPositionProps> = ({
  elements,
  elementType,
  teamsById,
}) => {
  const halfElements = Math.ceil(elements.length / 2);
  const firstHalfElements = elements.slice(0, halfElements);
  const secondHalfElements = elements.slice(halfElements, elements.length);
  return (
    <Wrapper>
      <Main isWide>
        <SubHeading>{elementType.plural_name}</SubHeading>
        <div style={{ display: "flex" }}>
          <ElementListTable
            elements={firstHalfElements}
            teamsById={teamsById}
          />
          <ElementListTable
            elements={secondHalfElements}
            teamsById={teamsById}
          />
        </div>
      </Main>
    </Wrapper>
  );
};

interface IPropsFromState {
  elements: IElement[];
  elementTypes: IElementType[];
  teamsById: ITeamsById;
}

type Props = RouteComponentProps & IPropsFromState;

const PlayerList: React.FC<Props> = ({ elements, elementTypes, teamsById }) => (
  <div className="ism-main-full">
    {elementTypes.map((et) => (
      <ElementListPosition
        elements={elements
          .filter((e) => e.element_type === et.id)
          .sort((a, b) => b.now_cost - a.now_cost)}
        elementType={et}
        key={et.id}
        teamsById={teamsById}
      />
    ))}
  </div>
);

export { PlayerList as PlayerListTest };

const mapStateToProps = (state: RootState) => ({
  elements: getElements(state),
  elementTypes: getElementTypes(state),
  teamsById: getTeamsById(state),
});

export default connect(mapStateToProps)(PlayerList);

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getCurrentEvent } from "../../core/store/events/reducers";

const StatusBar = styled.div<IStatusBar>`
  padding: ${({ theme }) => theme.space[2]};
  border-radius: 0;
  background-color: ${(props) =>
    props.isFinished ? props.theme.colors.error : props.theme.colors.green};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[5]};
  font-weight: bold;
  color: ${(props) =>
    props.isFinished ? props.theme.colors.white : props.theme.colors.primary};
  text-align: center;
  line-height: 1.3;
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    border-radius: ${({ theme }) => theme.radii[1]};
  }
`;
interface IStatusBar {
  isFinished: boolean;
}

const EventStatusBar: React.FC = () => {
  const { t } = useTranslation();
  const now = useSelector(getCurrentEvent);
  if (!now) {
    return null;
  }
  const eventStatus = now.finished
    ? t("statusBar.complete", "complete")
    : t("statusBar.live", "live");

  return (
    <StatusBar isFinished={now.finished}>
      {t("statusBar.eventStatus", "Round is {{ isFinished }}", {
        isFinished: eventStatus,
      })}
    </StatusBar>
  );
};

export default EventStatusBar;

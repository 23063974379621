import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byEvent: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEventWinners.success):
        return {
          ...state,
          [action.payload.event]: action.payload.data,
        };
      default:
        return state;
    }
  },
  byPhase: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchPhaseWinners.success):
        return {
          ...state,
          [action.payload.phase]: action.payload.data,
        };

      default:
        return state;
    }
  },
});

// State Selectors
export const getWinnersByEvent = (state: RootState) => state.winners.byEvent;

export const getEventWinners = (state: RootState, eventId: number) =>
  getWinnersByEvent(state)[eventId] || [];

export const getWinnersByPhase = (state: RootState) => state.winners.byPhase;

export const getPhaseWinners = (state: RootState, phaseId: number) =>
  getWinnersByPhase(state)[phaseId] || [];

import { createAsyncAction } from "typesafe-actions";
import { IError } from "..";
import { IBestLeague, IMostValuableTeam } from "./types";

export const fetchBestClassicPrivateLeagues = createAsyncAction(
  "FETCH_BEST_CLASSIC_PRIVATE_LEAGUES_START",
  "FETCH_BEST_CLASSIC_PRIVATE_LEAGUES_SUCCESS",
  "FETCH_BEST_CLASSIC_PRIVATE_LEAGUES_FAILURE"
)<void, IBestLeague[], IError>();

export const fetchMostValuableTeams = createAsyncAction(
  "FETCH_MOST_VALUABLE_TEAMS_START",
  "FETCH_MOST_VALUABLE_TEAMS_SUCCESS",
  "FETCH_MOST_VALUABLE_TEAMS_FAILURE"
)<void, IMostValuableTeam[], IError>();

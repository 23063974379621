import React from "react";
import { useTranslation } from "react-i18next";
import HelmetHead from "../HelmetHead";

const HelmetHeadLeagues: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <HelmetHead
        title={t(
          "helmetHeadLeague.title",
          "Create & Join Fantasy Football Leagues | RSL Fantasy"
        )}
        description={t(
          "helmetHeadLeague.desc",
          "To view all types of leagues including head-to-head, classic & global, as well as creating & joining new leagues, visit the official website of the RSL."
        )}
      />
    </>
  );
};

export default HelmetHeadLeagues;

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { getElementControls } from "../../core/store/elements/reducers";
import { IElement } from "../../core/store/elements/types";
import { getStatDetails, getStatNames } from "../../utils/stats";
import { FieldWrap, SelectField } from "../FieldRenderers";

const mapState = (state: RootState) => ({
  controls: getElementControls(state),
  elementStats: getElementStats(state),
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

interface ISortOption {
  label: string;
  value: keyof IElement;
}

type Props = PropsFromRedux &
  WithTranslation & {
    handleSortChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    sortOptions?: ISortOption[];
  };

class ElementSort extends React.Component<Props> {
  public getSortOptions = (): ISortOption[] =>
    getStatNames(this.props.t).map((s) => ({
      label: getStatDetails(s, this.props.t)!.label,
      value: s,
    }));

  public render() {
    const {
      controls,
      handleSortChange,
      sortOptions = this.getSortOptions(),
      t,
    } = this.props;
    return (
      <FieldWrap>
        <SelectField
          id="sort"
          name="sort"
          label={t("sortedBy", "Sorted by")}
          value={controls.sort}
          onChange={handleSortChange}
          required={false}
        >
          {sortOptions.map((o) => (
            <option
              key={o.value}
              value={o.value}
              aria-selected={controls.filter === o.value}
            >
              {o.label}
            </option>
          ))}
        </SelectField>
      </FieldWrap>
    );
  }
}

export { ElementSort as ElementSortTest };

export default withTranslation()(connector(ElementSort));

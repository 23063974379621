import styled from "styled-components/macro";

const Copy = styled.div`
  margin: 0 ${({ theme }) => theme.space[2]};

  h4,
  h5,
  p,
  ul,
  ol,
  table {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  h4 {
    margin-top: 14px;
    font-size: 16px;
    font-weight: 600;
  }

  h6 {
    margin-bottom: ${({ theme }) => theme.space[1]};
  }

  h3 {
    margin-bottom: 18px;
  }

  ul,
  ol {
    margin-top: 0;
    margin-bottom: ${({ theme }) => theme.space[2]};
    padding-inline-start: 4rem;
  }

  ul li {
    list-style-type: disc;
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  ol li {
    list-style-type: decimal;
  }
`;

export default Copy;

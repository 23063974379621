import i18n from "i18next";

export const getShortNameFromId = (event: number, idOnly = false) => {
  const prefix = idOnly ? "" : i18n.t("events.shortName", "RD");
  return `${prefix}${event}`;
};

export const getEventShortName = (eventName: string) =>
  eventName.match(/\d+/g)?.join(".");

export const getIdFromEventName = (eventName: string) =>
  Number(getEventShortName(eventName));

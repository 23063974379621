import { size } from "polished";
import styled, { css } from "styled-components/macro";
import { ReactComponent as BaseDownIcon } from "../../img/icons/down.svg";
import { ReactComponent as BaseNewIcon } from "../../img/icons/new.svg";
import { ReactComponent as BaseSameIcon } from "../../img/icons/same.svg";
import { ReactComponent as BaseUpIcon } from "../../img/icons/up.svg";
import { isRTL } from "../../utils/locale";

const rtlStyles =
  isRTL() &&
  css`
    transform: scaleX(-1);
  `;

export const NewIcon = styled(BaseNewIcon)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.greyDarker3};
`;

export const SameIcon = styled(BaseSameIcon)`
  ${size(10)}
  fill: ${({ theme }) => theme.colors.greyDarker3};
  ${rtlStyles}
`;

export const UpIcon = styled(BaseUpIcon)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.success};
`;

export const DownIcon = styled(BaseDownIcon)`
  ${size(12)}
  fill: ${({ theme }) => theme.colors.error};
`;

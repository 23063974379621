import * as React from "react";

interface IProps {
  code: string;
}

const Flag: React.FC<IProps> = ({ code }) => {
  const flagPath = `${process.env.PUBLIC_URL}/img/flags`;

  return <img src={`${flagPath}/${code}.gif`} alt="" />;
};

export default Flag;

import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";
import { ITransfer } from "../entries/types";
import { IProposedPicks } from "./types";

const createAction = deprecated.createAction;

export const proposeElementInPosition = createAction(
  "PROPOSE_ELEMENT_IN_POSITION",
  (resolve) => {
    return (element: number, position: number) =>
      resolve({ element, position });
  }
);

export const removeElementInPosition = createAction(
  "REMOVE_ELEMENT_IN_POSITION",
  (resolve) => {
    return (element: number, position: number) =>
      resolve({ element, position });
  }
);

export const resetLastChange = createAction("RESET_LAST_CHANGE", (resolve) => {
  return () => resolve();
});

export const resetProposed = createAction("RESET_PROPOSED", (resolve) => {
  return (data: IProposedPicks = {}) => resolve(data);
});

export const createSquad = createAsyncAction(
  "CREATE_SQUAD_START",
  "CREATE_SQUAD_SUCCESS",
  "CREATE_SQUAD_FAILURE"
)<void, object, IError>();

export const makeTransfers = createAsyncAction(
  "MAKE_TRANSFERS_START",
  "MAKE_TRANSFERS_SUCCESS",
  "MAKE_TRANSFERS_FAILURE"
)<void, object, IError>();

export const fetchTransfersLatest = createAsyncAction(
  "FETCH_TRANSFERS_LATEST_START",
  "FETCH_TRANSFERS_LATEST_SUCCESS",
  "FETCH_TRANSFERS_LATEST_FAILURE"
)<void, ITransfer[], IError>();

import { ThunkResult } from "..";
import { apiGet, apiPost } from "../../utils/api";
import { checkGame } from "../bootstrap/thunks";
import { getActiveOrProposedTeamChipName } from "../chips/reducers";
import { getElementsById } from "../elements/reducers";
import { getSettings } from "../game/reducers";
import { getPlayerData } from "../player/reducers";
import * as actions from "./actions";
import {
  getMyPicksProposed,
  getMyPossibleReplacementsForPick,
  toUpdateMyTeamAPI,
} from "./reducers";
import { IMyTeamData, IPickProposed } from "./types";

export const fetchMyTeam =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    dispatch(checkGame());
    const player = getPlayerData(getState());
    if (player && player.entry) {
      try {
        await apiGet<IMyTeamData>(
          `my-team/${player.entry}/`,
          dispatch,
          actions.fetchMyTeam,
          { elementsById: getElementsById(getState()) }
        );
      } catch (e) {
        return;
      }
    }
  };

export const actionSubstitution =
  (elementId: number): ThunkResult<void> =>
  (dispatch, getState) => {
    const pick = getMyPicksProposed(getState()).reduce(
      (memo: IPickProposed | null, p) => (p.element === elementId ? p : memo),
      null
    );
    const settings = getSettings(getState());
    if (pick && settings) {
      if (pick.subStatus === "") {
        dispatch(
          actions.substitutionStart(
            pick,
            getMyPossibleReplacementsForPick(getState(), pick)
          )
        );
      } else if (pick.subStatus === "instigator") {
        dispatch(actions.substitutionStop());
      } else if (pick.subStatus === "target") {
        dispatch(
          actions.substitutionProcess(pick, settings.squad_squadplay + 1)
        );
      }
    }
  };

export const saveMyTeam =
  (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
    try {
      const player = getPlayerData(getState());
      if (!player || !player.entry) {
        return;
      }
      await apiPost<IMyTeamData>(
        `my-team/${player.entry}/`,
        {
          chip: getActiveOrProposedTeamChipName(getState()),
          picks: toUpdateMyTeamAPI(getState()),
        },
        dispatch,
        actions.saveMyTeam,
        { elementsById: getElementsById(getState()) }
      );
    } catch (e) {
      // TODO - there ae no expected errors
      return;
    }
  };

import { Link, RouteComponentProps, navigate } from "@reach/router";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import { ThunkDispatch } from "../../core/store";
import { bootstrap } from "../../core/store/bootstrap/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import Button from "../Button";
import Copy from "../Copy";
import { InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { FetchPut } from "./utils";

const VerifyDeleteOtp: React.FC<RouteComponentProps> = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const { t } = useTranslation();
  const [token, setToken] = useState("");
  const player = useSelector(getPlayerData);
  if (!player) {
    navigate("../");
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    FetchPut("player/action-delete-verify/", { token }).then(async () => {
      try {
        await reduxDispatch(bootstrap());
      } catch (e) {
        return;
      }
    });
  };
  return (
    <Wrapper>
      <Main>
        <Copy>
          <Box mt={2} mb={4}>
            <Title>{t("verifyDeleteOtp.title", "Delete Your Account")}</Title>
          </Box>
          <SubHeading>{t("mergCode.subheading", "Enter Code")}</SubHeading>
          <p>
            {t(
              "verifyDeleteOtp.intro",
              "To complete the account deletion, please enter the 6-digit code sent to your registered email address and click 'Submit code'."
            )}
          </p>
          <Box mt={4} mb={4}>
            <form onSubmit={handleSubmit}>
              <InputField
                type="text"
                id="token"
                label={t("verifyDeleteOtp.label", "Validation code")}
                value={token}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setToken(e.currentTarget.value)
                }
                required={false}
              />
              <Box mt={2} mb={4}>
                <Button type="submit" disabled={!token}>
                  {t("verifyDeleteOtp.button", "Submit code")}
                </Button>
              </Box>
            </form>
          </Box>
          <Box mt={4} mb={4}>
            <p>
              <Trans i18nKey="mergeEmail.noemail">
                Didn't get the code? If you do not see the email in your inbox,{" "}
                check your “junk mail” folder or “spam” folder. We make every{" "}
                effort to ensure these emails are delivered. If you still don't{" "}
                receive the code, click <Link to="/player">here</Link> to send
                it again.
              </Trans>
            </p>
          </Box>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default VerifyDeleteOtp;

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import * as actions from "./actions";
import { IFixture, IFixtureAllStats } from "./types";

export const fetchFixtures =
  (eventId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IFixture[]>(
        `fixtures/?event=${eventId}`,
        dispatch,
        actions.addFixturesForEvent,
        { event: eventId }
      );
    } catch (e) {
      return;
    }
  };

export const fetchFixturesFuture =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IFixture[]>(
        `fixtures/?future=1`,
        dispatch,
        actions.addFixtures
      );
    } catch (e) {
      return;
    }
  };

export const fetchFixtureAllStats =
  (fixtureId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IFixtureAllStats>(
        `fixture/${fixtureId}/stats/`,
        dispatch,
        actions.addAllStatsForFixture,
        { fixture: fixtureId }
      );
    } catch (e) {
      return;
    }
  };

export const fetchFixturesPhase =
  (phaseId: number): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    try {
      await apiGet<IFixture[]>(
        `fixtures/?phase=${phaseId}`,
        dispatch,
        actions.addFixtures
      );
    } catch (e) {
      return;
    }
  };

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import * as actions from "./actions";
import { IRegion } from "./types";

export const fetchRegions =
  (): ThunkResult<Promise<void>> => async (dispatch) => {
    try {
      await apiGet<IRegion[]>("regions/", dispatch, actions.addRegions);
    } catch (e) {
      return;
    }
  };

import sortBy from "lodash/sortBy";
import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import { fetchEntrySummary } from "../entries/actions";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byEntryId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(fetchEntrySummary.success): {
        // If we aready have some data, we make sure we keep the matches which
        // aren't in the summary
        const entryId = action.payload.id;
        const cupDetail = action.payload.data.leagues.cup;
        const newState = { ...state };
        newState[entryId] = cupDetail;
        if (!state[entryId]) {
          return newState;
        }
        const idsFromSummary = newState[entryId].matches.map((m) => m.id);
        state[entryId].matches.forEach((m) => {
          if (idsFromSummary.indexOf(m.id) === -1) {
            newState[entryId].matches.push(m);
          }
        });
        return newState;
      }

      case getType(actions.fetchEntryCupDetail.success): {
        const newState = { ...state };
        newState[action.payload.id] = {
          matches: action.payload.data.cup_matches,
          status: action.payload.data.cup_status,
        };
        return newState;
      }
      default:
        return state;
    }
  },
});

// State Selectors
export const getCupDetailForEntry = (state: RootState, entryId: number) => {
  const detail = state.cup.byEntryId[entryId];
  if (!detail) {
    return null;
  }
  return {
    ...detail,
    matches: sortBy(detail.matches, "event"),
  };
};

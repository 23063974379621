import { Dispatch } from "redux";
import { IError, RootAction } from "../store";
import { addServerError } from "../store/global/actions";

export const getCookie = (name: string): string => {
  let cookieValue = "";
  if (document.cookie && document.cookie !== "") {
    for (let cookie of document.cookie.split(";")) {
      cookie = cookie.trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

const responseIsEmpty = (response: Response) => {
  const contentLength = response.headers.get("content-length");
  return (
    response.status === 204 ||
    (contentLength && parseInt(contentLength, 10) === 0)
  );
};

const responseIsJSON = (response: Response) => {
  const contentType = response.headers.get("content-type");
  return contentType && contentType.indexOf("application/json") !== -1;
};

// TODO - Type this better
interface IAsyncActions {
  request: any;
  failure: any;
  success: any;
}

async function _api<T>(
  url: string,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  fetchArgs: RequestInit,
  successPayloadFormat?: {}
): Promise<T> {
  let response;
  try {
    dispatch(asyncActions.request());
    response = await fetch(`/api/${url}`, {
      ...fetchArgs,
      credentials: "same-origin",
    });
  } catch (e) {
    // Network error
    const error: IError = { errorText: e.message, url };
    dispatch(asyncActions.failure(error));
    throw e;
  }
  if (!response.ok) {
    // Not 2xx
    const error: IError = { statusText: response.statusText, url };
    if (response.status === 400) {
      error.badRequest = await response.json();
    } else {
      // Non 400 errors are handled globally
      dispatch(
        addServerError({
          code: response.status,
          details: await response.text(),
        })
      );
    }
    dispatch(asyncActions.failure(error));
    throw new Error(response.statusText);
  }
  const data = responseIsJSON(response)
    ? responseIsEmpty(response)
      ? {}
      : await response.json()
    : responseIsEmpty(response)
    ? ""
    : await response.json();
  dispatch(
    asyncActions.success(
      successPayloadFormat
        ? {
            ...successPayloadFormat,
            data,
          }
        : data
    )
  );
  return data as T;
}

export async function apiGet<T>(
  url: string,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  successPayloadFormat?: {}
): Promise<T> {
  return _api(url, dispatch, asyncActions, {}, successPayloadFormat);
}

async function _apiDeletePostPut<T>(
  method: "DELETE" | "POST" | "PUT",
  url: string,
  data: object,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  successPayloadFormat?: {}
): Promise<T> {
  return _api(
    url,
    dispatch,
    asyncActions,
    {
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": getCookie("csrftoken"),
      },
      method,
    },
    successPayloadFormat
  );
}

export async function apiPost<T>(
  url: string,
  data: object,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  successPayloadFormat?: {}
): Promise<T> {
  return _apiDeletePostPut<T>(
    "POST",
    url,
    data,
    dispatch,
    asyncActions,
    successPayloadFormat
  );
}

export async function apiPut<T>(
  url: string,
  data: object,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  successPayloadFormat?: {}
): Promise<T> {
  return _apiDeletePostPut<T>(
    "PUT",
    url,
    data,
    dispatch,
    asyncActions,
    successPayloadFormat
  );
}

export async function apiDelete<T>(
  url: string,
  data: object,
  dispatch: Dispatch<RootAction>,
  asyncActions: IAsyncActions,
  successPayloadFormat?: {}
): Promise<T> {
  return _apiDeletePostPut<T>(
    "DELETE",
    url,
    data,
    dispatch,
    asyncActions,
    successPayloadFormat
  );
}

import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../core/store";
import {
  getElementControls,
  getElementTypeFilterOptions,
} from "../../core/store/elements/reducers";
import { getPlayerData } from "../../core/store/player/reducers";
import { getTeams } from "../../core/store/teams/reducers";
import { ITeam } from "../../core/store/teams/types";
import { FieldWrap, SelectField } from "../FieldRenderers";

const mapState = (state: RootState) => ({
  teams: getTeams(state),
  controls: getElementControls(state),
  elementTypeFilterOptions: getElementTypeFilterOptions(state),
  player: getPlayerData(state),
});

const connector = connect(mapState, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux &
  WithTranslation & {
    teams: ITeam[];
    handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  };

class ElementFilter extends React.Component<Props> {
  public render() {
    const {
      controls,
      elementTypeFilterOptions,
      handleFilterChange,
      player,
      t,
      teams,
    } = this.props;

    // filterTeams
    const filterTeams = teams
      .filter((team: any) => team.unavailable === false)
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <FieldWrap>
        <SelectField
          id="filter"
          name="filter"
          label={t("elementFilter.view", "View")}
          value={controls.filter}
          onChange={handleFilterChange}
          required={false}
        >
          <optgroup label={t("elementFilter.global", "Global")}>
            <option value="all" aria-selected={controls.filter === "all"}>
              {t("elementFilter.allPlayers", "All players")}
            </option>
            {player && player.entry && (
              <option value="wl" aria-selected={controls.filter === "wl"}>
                {t("elementFilter.watchList", "Watchlist")}
              </option>
            )}
          </optgroup>
          <optgroup label={t("elementFilter.byPosition", "By position")}>
            {elementTypeFilterOptions.map((o) => (
              <option
                key={o.value}
                value={o.value}
                aria-selected={controls.filter === o.value}
              >
                {o.label}
              </option>
            ))}
          </optgroup>
          <optgroup label={t("elementFilter.topLeague", "Team")}>
            {filterTeams.map((o) => (
              <option
                key={`te_${o.id}`}
                value={`te_${o.id}`}
                aria-selected={controls.filter === `te_${o.id}`}
              >
                {o.name}
              </option>
            ))}
          </optgroup>
        </SelectField>
      </FieldWrap>
    );
  }
}

export { ElementFilter as ElementFilterTest };

export default withTranslation()(connector(ElementFilter));

import React from "react";
import Downshift from "downshift";
import { SearchField } from "../../FieldRenderers";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";

interface IEntry {
  entry: number;
  player_name: string;
  entry_name: string;
}

interface IEntrySelectorProps {
  entries: IEntry[];
  handleChange: (selectedItem: any) => void;
  searchChange: (value: string) => any;
}

const EntrySelector: React.FC<IEntrySelectorProps> = ({
  entries,
  handleChange,
  searchChange,
}) => {
  const { t } = useTranslation();

  return (
    <Downshift
      onChange={(selection) => handleChange(Number(selection.entry))}
      onInputValueChange={debounce(searchChange, 400)}
      itemToString={(item) => (item ? item.player_name : "")}
    >
      {({
        getInputProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
        highlightedIndex,
        selectedItem,
      }) => (
        <div>
          <SearchField
            {...getInputProps()}
            {...getLabelProps()}
            label={t("addBan.labels", "Player")}
          />
          <ul {...getMenuProps()}>
            {isOpen
              ? entries.map((item, index) => (
                  <li
                    {...getItemProps({
                      key: item.entry,
                      index,
                      item,
                      style: {
                        backgroundColor:
                          highlightedIndex === index ? "lightgray" : "white",
                        fontWeight: selectedItem === item ? "bold" : "normal",
                      },
                    })}
                  >
                    {item.player_name} ({item.entry_name})
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </Downshift>
  );
};

interface IProps {
  excluded?: number[];
  handleChange: (selectedItem: any) => void;
  leagueId: number;
}

interface IState {
  entries: IEntry[];
}

class EntrySelectorWrapper extends React.Component<IProps, IState> {
  public state: IState = {
    entries: [],
  };

  public lastSearch = "";

  public searchChange = async (value: string) => {
    const { excluded = [], handleChange, leagueId } = this.props;

    // If the search term has been cleared, clear any state
    if (!value.length) {
      handleChange(0);
      this.setState({ entries: [] });
      this.lastSearch = "";
    }

    // We are only interested in first name searches
    const searchMatches = value.match(/^(.*?) /);
    if (searchMatches && searchMatches[1] !== this.lastSearch) {
      const response = await fetch(
        `/api/league/${leagueId}/search-entries/?search=${encodeURIComponent(
          searchMatches[1]
        )}`
      );
      this.lastSearch = searchMatches[1];
      if (response.ok) {
        const entries: IEntry[] = await response.json();
        this.setState({
          entries: entries.filter((e) => excluded.indexOf(e.entry) === -1),
        });
      }
    }
  };

  public render() {
    return (
      <EntrySelector
        entries={this.state.entries}
        handleChange={this.props.handleChange}
        searchChange={this.searchChange}
      />
    );
  }
}

export default EntrySelectorWrapper;

import React from "react";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getRenewableLeagues } from "../../core/store/leagues/reducers";
import {
  deleteRenewableLeague,
  fetchRenewableLeagues,
  renewRenewableLeague,
} from "../../core/store/leagues/thunks";
import { IRenewableLeague } from "../../core/store/leagues/types";
import Alert from "../Alert";
import Button from "../Button";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";
import { withTranslation, WithTranslation } from "react-i18next";

interface IPropsFromState {
  leagues: IRenewableLeague[];
}

interface IPropsFromDispatch {
  destroy: (id: number) => void;
  fetch: () => void;
  renew: (id: number) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

class Renew extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetch();
  }

  public render() {
    const { destroy, leagues, renew, t } = this.props;
    return (
      <Wrapper>
        <Main>
          <Copy>
            <Title>{t("renew.title", "Renew your leagues")}</Title>
            <p>
              {t(
                "renew.p1",
                "Choose whether you wish to renew or delete last season's private leagues."
              )}
            </p>
            <p>
              {t(
                "renew.p2",
                "All players from last season will automatically be entered into the renewed league(s) when they join the game."
              )}
            </p>

            {leagues.length ? (
              <ul>
                {leagues.map((l) => (
                  <li key={l.id}>
                    <SubHeading>{l.name}</SubHeading>
                    <Flex>
                      <Box mr={2}>
                        <Button onClick={() => renew(l.id)}>
                          {t("renew.buttons.renew", "Renew")}
                        </Button>
                      </Box>
                      <Box>
                        <Button onClick={() => destroy(l.id)}>
                          {t("renew.buttons.delete", "Delete")}
                        </Button>
                      </Box>
                    </Flex>
                  </li>
                ))}
              </ul>
            ) : (
              <Alert type="info">
                {t("renew.none", "You have no leagues to renew.")}
              </Alert>
            )}
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { Renew as RenewTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  leagues: getRenewableLeagues(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  destroy: async (id: number) =>
    await dispatch(deleteRenewableLeague(id)).catch((e) => {}),
  fetch: async () => await dispatch(fetchRenewableLeagues()).catch((e) => {}),
  renew: async (id: number) =>
    await dispatch(renewRenewableLeague(id)).catch((e) => {}),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Renew)
);

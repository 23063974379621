import { createAsyncAction } from "typesafe-actions";
import { IError } from "..";
import {
  IEntry,
  IEntryChipHistory,
  IEntryEvent,
  IEntryEventHistory,
  IEntryPhaseStanding,
  IEntrySeasonHistory,
  ITransfer,
} from "./types";

export const fetchEntrySummary = createAsyncAction(
  "FETCH_ENTRY_SUMMARY_START",
  "FETCH_ENTRY_SUMMARY_SUCCESS",
  "FETCH_ENTRY_SUMMARY_FAILURE"
)<void, { id: number; data: IEntry }, IError>();

export const fetchEntryHistory = createAsyncAction(
  "FETCH_ENTRY_HISTORY_START",
  "FETCH_ENTRY_HISTORY_SUCCESS",
  "FETCH_ENTRY_HISTORY_FAILURE"
)<
  void,
  {
    id: number;
    data: {
      current: IEntryEventHistory[];
      past: IEntrySeasonHistory[];
      chips: IEntryChipHistory[];
    };
  },
  IError
>();

export const fetchEntryEventPicks = createAsyncAction(
  "FETCH_ENTRY_EVENT_PICKS_START",
  "FETCH_ENTRY_EVENT_PICKS_SUCCESS",
  "FETCH_ENTRY_EVENT_PICKS_FAILURE"
)<void, { entry: number; event: number; data: IEntryEvent }, IError>();

export const updateEntry = createAsyncAction(
  "UPDATE_ENTRY_START",
  "UPDATE_ENTRY_SUCCESS",
  "UPDATE_ENTRY_FAILURE"
)<void, object, IError>();

export const fetchEntryTransfers = createAsyncAction(
  "FETCH_ENTRY_TRANSFERS_START",
  "FETCH_ENTRY_TRANSFERS_SUCCESS",
  "FETCH_ENTRY_TRANSFERS_FAILURE"
)<void, { id: number; data: ITransfer[] }, IError>();

export const fetchEntryPhaseStandings = createAsyncAction(
  "FETCH_ENTRY_PHASE_STANDINGS_START",
  "FETCH_ENTRY_PHASE_STANDINGS_SUCCESS",
  "FETCH_ENTRY_PHASE_STANDINGS_FAILURE"
)<
  void,
  { entry: number; phase: number; data: IEntryPhaseStanding[] },
  IError
>();

export const unsubscribeEntry = createAsyncAction(
  "UNSUBSCRIBE_ENTRY_START",
  "UNSUBSCRIBE_ENTRY_SUCCESS",
  "UNSUBSCRIBE_ENTRY_FAILURE"
)<void, {}, IError>();

import { navigate, RouteComponentProps } from "@reach/router";
import qs from "qs";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box, Flex } from "rebass/styled-components";
import styled from "styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent, IEventsById } from "../../core/store/events/types";
import {
  getClassicLeague,
  getClassicNewEntries,
  getClassicStandings,
} from "../../core/store/leagues/reducers";
import { fetchClassicLeagueStandings } from "../../core/store/leagues/thunks";
import {
  IClassicStandingsWithDates,
  ILeague,
  INewEntriesData,
} from "../../core/store/leagues/types";
import { getStartedPhases } from "../../core/store/phases/reducers";
import { IPhase } from "../../core/store/phases/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { formatRawAsLocalI18n } from "../../core/utils/datetime";
import { dateLocales } from "../../i18n";
import Alert from "../Alert";
import BoldLink from "../BoldLink";
import ButtonLink from "../ButtonLink";
import { InlineSelectField } from "../FieldRenderers";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import { Pager, PagerItem, PagerItemNext } from "../Pager";
import Panel from "../Panel";
import SubHeading from "../SubHeading";
import Table from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";
import HelmetHeadLeagues from "./HelmetHeadLeagues";
import Movement from "./Movement";
import StandingsHeading from "./StandingsHeading";
import StandingsRow from "./StandingsRow";
import { getCupUrl } from "./utils";

const StyledBox = styled(Box)`
  margin-inline-end: 0.5rem;
`;

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  league: ILeague | null;
  leagueNumber: number;
  newEntries: INewEntriesData | null;
  now: IEvent | null;
  pageNewEntries: number;
  pageStandings: number;
  phase: number;
  phases: IPhase[];
  player: IPlayer | null;
  standings: IClassicStandingsWithDates | null;
  eventsById: IEventsById;
}

interface IPropsFromDispatch {
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) => void;
}

type Props = WithTranslation & OwnProps & IPropsFromState & IPropsFromDispatch;

class StandingsClassic extends React.Component<Props> {
  public fetchLeagueStandings() {
    if (this.props.leagueNumber) {
      this.props.fetchStandings(
        this.props.leagueNumber,
        this.props.phase,
        this.props.pageNewEntries,
        this.props.pageStandings
      );
    }
  }

  public componentDidMount() {
    this.fetchLeagueStandings();
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.pageNewEntries !== this.props.pageNewEntries ||
      prevProps.pageStandings !== this.props.pageStandings ||
      prevProps.phase !== this.props.phase
    ) {
      this.fetchLeagueStandings();
    }
  }

  public buildLink(
    phase: number,
    pageNewEntries: number,
    pageStandings: number
  ) {
    return `/leagues/${
      this.props.league!.id
    }/standings/c?phase=${phase}&page_new_entries=${pageNewEntries}&page_standings=${pageStandings}`;
  }

  public handleChange = (e: React.FormEvent<HTMLSelectElement>) =>
    navigate(this.buildLink(parseInt(e.currentTarget.value, 10), 1, 1));

  public render() {
    const {
      i18n,
      league,
      leagueNumber,
      newEntries,
      now,
      pageNewEntries,
      pageStandings,
      phase,
      phases,
      player,
      standings,
      eventsById,
      t,
    } = this.props;

    if (!league) {
      return null;
    }

    const started = now && league.start_event <= now.id;
    console.log("league::", league);
    return (
      <>
        <HelmetHeadLeagues />
        <Wrapper>
          <Main>
            <StandingsHeading
              type="classic"
              league={league}
              leagueNumber={leagueNumber}
            />
            {league.has_cup && (
              <Box px={2} mb={4}>
                <BoldLink to={getCupUrl(league.id, league.cup_league)}>
                  {league.cup_league
                    ? t("standingClassic.matches", "View cup matches")
                    : t("standingClassic.info", "View cup info")}
                </BoldLink>
              </Box>
            )}
            {league.start_event > 1 && (
              <Box m={2}>
                <Alert type="info">
                  {started
                    ? t(
                        "standingClassic.started",
                        "League scoring started in {{startEvent}}",
                        { startEvent: eventsById[league.start_event].name }
                      )
                    : t(
                        "standingClassic.starting",
                        "League scoring will start in {{startEvent}}",
                        { startEvent: eventsById[league.start_event].name }
                      )}
                </Alert>
              </Box>
            )}
            <Box m={2}>
              <form>
                <InlineSelectField
                  id="selectPhase"
                  name="selectPhase"
                  label={t("standingClassic.selectPhase", "Select phase:  ")}
                  onChange={this.handleChange}
                  value={phase}
                >
                  {phases.map((p) => (
                    <option
                      value={p.id}
                      key={p.id}
                      aria-selected={phase === p.id}
                    >
                      {p.name}
                    </option>
                  ))}
                </InlineSelectField>
              </form>
            </Box>
            {standings && standings.lastUpdatedData && (
              <Box m={2}>
                {t(
                  "standingClassic.lastUpdate",
                  "Last updated: {{ lastUpdatedData }} (local time)",
                  {
                    lastUpdatedData: formatRawAsLocalI18n(
                      standings.lastUpdatedData,
                      dateLocales[i18n.language]
                    ),
                  }
                )}
              </Box>
            )}
            <Box mb={2}>
              <Panel>
                <Table>
                  <thead>
                    <tr>
                      <th scope="col">{t("standingClassic.rank", "Rank")}</th>
                      <th scope="col">
                        {t("standingClassic.team", "Team & Manager")}
                      </th>
                      <th scope="col">
                        <Tooltip content="Round points">
                          <TooltipLabel>
                            {t("standingClassic.rd", "RD")}
                          </TooltipLabel>
                        </Tooltip>
                      </th>
                      <th scope="col">
                        <Tooltip content="Total points">
                          <TooltipLabel>
                            {t("standingClassic.tot", "TOT")}
                          </TooltipLabel>
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {standings &&
                      standings.results.map((ls) => (
                        <StandingsRow
                          mine={player !== null && player.entry === ls.entry}
                          key={ls.entry}
                        >
                          <td>
                            <Flex alignItems="center">
                              <StyledBox>{ls.rank}</StyledBox>
                              <Movement
                                lastRank={ls.last_rank}
                                rank={ls.rank}
                              />
                            </Flex>
                          </td>
                          <td>
                            {now ? (
                              <Link to={`/entry/${ls.entry}/event/${now.id}`}>
                                <strong>{ls.entry_name}</strong>
                              </Link>
                            ) : (
                              <strong>{ls.entry_name}</strong>
                            )}
                            <br />
                            {ls.player_name}
                          </td>
                          <td>{ls.event_total}</td>
                          <td>{ls.total}</td>
                        </StandingsRow>
                      ))}
                  </tbody>
                </Table>
              </Panel>
            </Box>
            {standings && (
              <Pager>
                <PagerItem>
                  {pageStandings > 1 && (
                    <ButtonLink
                      to={this.buildLink(
                        phase,
                        pageNewEntries,
                        pageStandings - 1
                      )}
                      variant="tertiary"
                    >
                      <span>{t("standingClassic.previews", "Previous")}</span>
                    </ButtonLink>
                  )}
                </PagerItem>
                <PagerItemNext>
                  {standings.has_next && (
                    <ButtonLink
                      to={this.buildLink(
                        phase,
                        pageNewEntries,
                        pageStandings + 1
                      )}
                      variant="tertiary"
                    >
                      <span>{t("standingClassic.next", "Next")}</span>
                    </ButtonLink>
                  )}
                </PagerItemNext>
              </Pager>
            )}
            {newEntries && newEntries.results.length > 0 && (
              <>
                <Box my={2}>
                  <SubHeading>
                    {t("standingClassic.new", "New entries")}
                  </SubHeading>
                </Box>
                <Box mb={4}>
                  <Panel>
                    <Table>
                      <thead>
                        <tr>
                          <th scope="col">
                            {t("standingClassic.team1", "Team")}
                          </th>
                          <th scope="col">
                            {t("standingClassic.manager1", "Manager")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {newEntries.results.map((ne) => (
                          <tr key={ne.entry}>
                            <td>{ne.entry_name}</td>
                            <td>
                              <Link to={`/entry/${ne.entry}/history`}>
                                {ne.player_first_name} {ne.player_last_name}
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Panel>
                </Box>
                <Pager>
                  <PagerItem>
                    {pageNewEntries > 1 && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries - 1,
                          pageStandings
                        )}
                        variant="secondary"
                      >
                        <ControlArrowLeft />
                        <span>
                          {t("standingClassic.previews1", "Previous")}
                        </span>
                      </ButtonLink>
                    )}
                  </PagerItem>
                  <PagerItemNext>
                    {newEntries.has_next && (
                      <ButtonLink
                        to={this.buildLink(
                          phase,
                          pageNewEntries + 1,
                          pageStandings
                        )}
                        variant="secondary"
                      >
                        <Flex
                          justify-content="space-between"
                          alignItems="center"
                        >
                          <span>{t("standingClassic.next1", "Next")}</span>
                          <ControlArrowRight />
                        </Flex>
                      </ButtonLink>
                    )}
                  </PagerItemNext>
                </Pager>
              </>
            )}
          </Main>
          <Secondary>
            <></>
          </Secondary>
        </Wrapper>
      </>
    );
  }
}

export { StandingsClassic as StandingClassicTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  // Get league id from path
  const leagueNumber = parseInt(ownProps.leagueId!, 10) || 0;

  // Get phase and page numbers from query string
  const query = qs.parse(ownProps.location!.search, {
    ignoreQueryPrefix: true,
  });
  const phase = parseInt(query.phase as string, 10) || 1;
  const pageNewEntries = parseInt(query.page_new_entries as string, 10) || 1;
  const pageStandings = parseInt(query.page_standings as string, 10) || 1;

  return {
    now: getCurrentEvent(state),
    league: getClassicLeague(state, leagueNumber),
    leagueNumber,
    newEntries: getClassicNewEntries(state, leagueNumber, pageNewEntries),
    pageNewEntries,
    pageStandings,
    phase,
    phases: getStartedPhases(state),
    player: getPlayerData(state),
    standings: getClassicStandings(state, leagueNumber, phase, pageStandings),
    eventsById: getEventsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchStandings: (
    leagueId: number,
    phaseId: number,
    pageNewEntries: number,
    pageStandings: number
  ) =>
    dispatch(
      fetchClassicLeagueStandings(
        leagueId,
        pageNewEntries,
        pageStandings,
        phaseId
      )
    ),
});
export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(StandingsClassic)
);

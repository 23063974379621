import { createAsyncAction, deprecated } from "typesafe-actions";
import { IMyTeamData } from "../my-team/types";
import { IElementsById } from "../elements/types";
import { IError } from "..";

const createAction = deprecated.createAction;

export const proposeAvailableChip = createAction(
  "PROPOSE_AVAILABLE_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const cancelProposedChip = createAction(
  "CANCEL_PROPOSED_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const cancelActiveChip = createAction(
  "CANCEL_ACTIVE_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const restoreCancelledChip = createAction(
  "RESTORE_CANCELLED_CHIP",
  (resolve) => (chipName: string) => resolve(chipName)
);

export const fetchChipState = createAsyncAction(
  "FETCH_CHIP_STATE_START",
  "FETCH_CHIP_STATE_SUCCESS",
  "FETCH_CHIP_STATE_FAILURE"
)<void, { data: IMyTeamData; elementsById: IElementsById }, IError>();

export const saveChipTeam = createAsyncAction(
  "SAVE_CHIP_TEAM_START",
  "SAVE_CHIP_TEAM_SUCCESS",
  "SAVE_CHIP_TEAM_FAILURE"
)<void, { data: IMyTeamData; elementsById: IElementsById }, IError>();

export const saveChipTransfer = createAsyncAction(
  "SAVE_CHIP_TRANSFER_START",
  "SAVE_CHIP_TRANSFER_SUCCESS",
  "SAVE_CHIP_TRANSFER_FAILURE"
)<void, object, IError>();

import { IError } from "../../core/store";

export const getErrorType = (errors: IError | null) => {
  if (errors) {
    if (errors.badRequest) {
      if (errors.badRequest.non_field_errors) {
        return errors.badRequest.non_field_errors[0].code;
      }
      if (errors.badRequest.code) {
        return errors.badRequest.code[0].code;
      }
    }
    return JSON.stringify(errors);
  }
  return "";
};

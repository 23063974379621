import React from "react";
import styled from "styled-components/macro";

const StyledResult = styled.span<IOwnProps>`
  font-family: ${({ theme }) => theme.fonts.base};
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-inline-end: ${({ theme }) => theme.space[1]};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: center;
  vertical-align: middle;
`;

interface IOwnProps {
  resultChar: string;
}

const Result: React.FC<IOwnProps> = ({ resultChar }) => (
  <StyledResult resultChar={resultChar.toLowerCase()}>
    {resultChar}
  </StyledResult>
);

export default Result;

import styled, { css } from "styled-components/macro";
import Table from "./Table";
import { SubStatus } from "../core/store/my-team/types";
import { rgba } from "polished";

interface IProps {
  elementType?: number;
}

export const ElementTable = styled(Table)<IProps>`
  width: 100%;
  white-space: nowrap;
  table-layout: fixed;
  background-color: white;
  border-collapse: collapse;

  th,
  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  thead th {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const PrimaryCell = styled.td`
  position: relative;
  text-align: center;
`;

export const PrimaryCellLined = styled(PrimaryCell)`
  ::after {
    content: "";
    position: absolute;
    top: 1rem;
    left: 0;
    bottom: 1rem;
    display: block;
    width: 2px;
    background: ${({ theme }) => theme.colors.whiteGrey};
  }
`;

interface IElementRowProps {
  variant?: SubStatus;
}

export const ElementRow = styled.tr<IElementRowProps>`
  td:first-child {
    padding: 0.2rem;
  }

  ${(props) =>
    props.variant === "instigator" &&
    css`
      background-color: ${rgba("yellow", 0.6)};
    `}

  ${(props) =>
    props.variant === "target" &&
    css`
      background-color: ${rgba("#f60", 0.6)};
    `}

  ${(props) =>
    props.variant === "invalid" &&
    css`
      opacity: 0.6;
    `}
`;

interface IElementCellProps {
  bold?: boolean;
}

export const ElementCell = styled.td<IElementCellProps>`
  position: relative;
`;

import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./store";

const configureStore = (initialState?: object) => {
  const middlewares = [thunkMiddleware];
  const enhancer = composeWithDevTools(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, initialState!, enhancer);
  return store;
};

export default configureStore;

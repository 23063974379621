import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import {
  getElementsById,
  getElementsEventDataById,
  getFormation,
} from "../elements/reducers";
import { getSettings } from "../game/reducers";
import {
  deleteLeague,
  joinPrivateLeague,
  leaveLeague,
} from "../leagues/actions";
import { getTeamsById } from "../teams/reducers";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntrySummary.success):
        return {
          ...state,
          [action.payload.id]: action.payload.data,
        };
      case getType(joinPrivateLeague.success): {
        const newLeague = action.payload.data;
        const leaguesKey = newLeague.scoring === "c" ? "classic" : "h2h";
        const entry = state[action.payload.entry];

        if (entry) {
          const isAlreadyInLeague = entry.leagues[leaguesKey].some(
            (league) => league.id === newLeague.id
          );

          if (!isAlreadyInLeague) {
            const updatedEntry = {
              ...entry,
              leagues: {
                ...entry.leagues,
                [leaguesKey]: entry.leagues[leaguesKey].concat([newLeague]),
              },
            };

            return {
              ...state,
              [action.payload.entry]: updatedEntry,
            };
          }
        }
        return state;
      }
      case getType(deleteLeague.success):
      case getType(leaveLeague.success): {
        const newState = { ...state };
        const entry = newState[action.payload.entry];
        if (entry) {
          entry.leagues.classic = entry.leagues.classic.filter(
            (le) => le.id !== action.payload.league
          );
          entry.leagues.h2h = entry.leagues.h2h.filter(
            (le) => le.id !== action.payload.league
          );
        }
        return newState;
      }
      default:
        return state;
    }
  },
  eventHistoryById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryHistory.success):
        return {
          ...state,
          [action.payload.id]: action.payload.data.current,
        };
      default:
        return state;
    }
  },
  seasonHistoryById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryHistory.success):
        return {
          ...state,
          [action.payload.id]: action.payload.data.past,
        };
      default:
        return state;
    }
  },
  chipHistoryById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryHistory.success):
        return {
          ...state,
          [action.payload.id]: action.payload.data.chips,
        };
      default:
        return state;
    }
  },
  eventPicksById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryEventPicks.success):
        return {
          ...state,
          [action.payload.entry]: {
            ...state[action.payload.entry],
            [action.payload.event]: action.payload.data,
          },
        };
      default:
        return state;
    }
  },
  transfersById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryTransfers.success):
        return {
          ...state,
          [action.payload.id]: action.payload.data,
        };
      default:
        return state;
    }
  },
  phaseStandingsById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEntryPhaseStandings.success):
        return {
          ...state,
          [action.payload.entry]: {
            ...state[action.payload.entry],
            [action.payload.phase]: action.payload.data,
          },
        };
      default:
        return state;
    }
  },
});

// State Selectors
export const getEntry = (state: RootState, entryId: number) =>
  state.entries.byId[entryId] || null;

export const getEntryEventHistory = (state: RootState, entryId: number) =>
  state.entries.eventHistoryById[entryId] || [];

export const getEntrySeasonHistory = (state: RootState, entryId: number) =>
  state.entries.seasonHistoryById[entryId] || [];

export const getEntryChipHistory = (state: RootState, entryId: number) =>
  state.entries.chipHistoryById[entryId] || [];

export const getEntryEventPicks = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  if (
    !state.entries.eventPicksById[entryId] ||
    !state.entries.eventPicksById[entryId][eventId]
  ) {
    return null;
  }
  return state.entries.eventPicksById[entryId][eventId];
};

export const getEntryEventPoints = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const data = getEntryEventPicks(state, entryId, eventId);
  const elementsEventDataById = getElementsEventDataById(state, eventId);
  if (!data || !elementsEventDataById) {
    return 0;
  }
  return data.picks.reduce(
    (total, p) =>
      total +
      elementsEventDataById[p.element].stats.total_points * p.multiplier,
    0
  );
};

export const getEntryEventFormation = (
  state: RootState,
  entryId: number,
  eventId: number
) => {
  const data = getEntryEventPicks(state, entryId, eventId);
  const elementsById = getElementsById(state);
  const settings = getSettings(state);
  if (!data || !elementsById || !settings) {
    return "";
  }
  return getFormation(
    data.picks
      .slice(0, settings.squad_squadplay)
      .map((p) => elementsById[p.element])
  );
};

export const getPrivateClassicLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter((l) => l.league_type === "x")
    : [];
};

export const getPrivateClassicCupLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter(
        (l) => l.league_type === "x" && l.has_cup === true
      )
    : [];
};

export const getPrivateH2HLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry ? entry.leagues.h2h.filter((l) => l.league_type === "x") : [];
};

export const getPublicClassicLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter((l) => l.league_type === "c")
    : [];
};

export const getPublicClassicCupLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter(
        (l) => l.league_type === "c" && l.has_cup === true
      )
    : [];
};

export const getPublicH2HLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry ? entry.leagues.h2h.filter((l) => l.league_type === "c") : [];
};

export const getSystemClassicLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter((l) => l.league_type === "s")
    : [];
};

export const getSystemClassicCupLeaguesForEntry = (
  state: RootState,
  entryId: number
) => {
  const entry = getEntry(state, entryId);
  return entry
    ? entry.leagues.classic.filter(
        (l) => l.league_type === "s" && l.has_cup === true
      )
    : [];
};

export const getLeagueForEntry = (
  state: RootState,
  entryId: number,
  leagueId: number
) => {
  const entry = getEntry(state, entryId);
  if (entry) {
    const classic = entry.leagues.classic.filter((l) => l.id === leagueId);
    if (classic.length) {
      return classic[0];
    }
    const h2h = entry.leagues.h2h.filter((l) => l.id === leagueId);
    if (h2h.length) {
      return h2h[0];
    }
  }
  return null;
};

export const getCupMatchesByLeagueId = (state: RootState, entryId: number) => {
  const entry = getEntry(state, entryId);
  if (entry) {
    const cupMatches = entry.leagues.cup_matches;
    if (cupMatches.length > 0) {
      return cupMatches.reduce(
        (memo, match) => ({ ...memo, [match.league]: match }),
        {}
      );
    }
    return null;
  }
  return null;
};

export const getTransfersForEntry = (state: RootState, entryId: number) =>
  state.entries.transfersById[entryId] || [];

export const getFanLeagueDetails = (state: RootState, entryId: number) => {
  const fanLeagueMatch = /^team-(\d+)$/;
  const teamsById = getTeamsById(state);
  const systemClassicLeagues = getSystemClassicLeaguesForEntry(state, entryId);

  // Please note the "!" non-null assertion operator on the filter
  const fanLeagues = systemClassicLeagues.filter((l) =>
    l.short_name!.match(fanLeagueMatch)
  );

  if (fanLeagues.length) {
    return {
      league: fanLeagues[0],
      team: teamsById[fanLeagues[0].short_name!.match(fanLeagueMatch)![1]],
    };
  }

  return null;
};

export const getPhaseStandingsForEntry = (state: RootState, entryId: number) =>
  state.entries.phaseStandingsById[entryId];

export const getPhaseStandingsForEntryPhase = (
  state: RootState,
  entryId: number,
  phaseId: number
) => state.entries.phaseStandingsById[entryId]?.[phaseId];

export const getPhaseStandingsForEntryPhaseLeague = (
  state: RootState,
  entryId: number,
  phaseId: number,
  leagueId: number
) =>
  state.entries.phaseStandingsById[entryId]?.[phaseId]?.find(
    (ps) => ps.league_id === leagueId
  );

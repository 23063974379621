import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getEventsById } from "../../core/store/events/reducers";
import { IH2HMatch } from "../../core/store/leagues/types";
import { getIdFromEventName } from "../../utils/events";
import Result from "../Result";
import CupSummaryScore from "./CupSummaryScore";

const EventCell = styled.td`
  text-align: center;
`;

const StatusCell = styled.td`
  text-align: center;
`;

const CupSummaryData: React.FC<{
  match: IH2HMatch;
  entryId: number;
}> = ({ match, entryId }) => {
  const { t } = useTranslation();
  const resultChar =
    match.winner === entryId
      ? t("CupSummaryData.W", "W")
      : match.winner
      ? t("CupSummaryData.L", "L")
      : "";
  const eventsById = useSelector(getEventsById);
  const cupEventNameId = match.event
    ? getIdFromEventName(eventsById[match.event].name)
    : 0;
  return (
    <>
      <EventCell>{cupEventNameId}</EventCell>
      <StatusCell>
        {match.winner ? (
          <Result resultChar={resultChar} />
        ) : (
          <CupSummaryScore match={match} entryId={entryId} />
        )}
      </StatusCell>
    </>
  );
};

export default CupSummaryData;

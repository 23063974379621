import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import * as actions from "./actions";
import { IState } from "./types";

export default combineReducers<IState, RootAction>({
  serverError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addServerError):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getServerError = (state: RootState) => state.global.serverError;

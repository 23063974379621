import { ThunkResult } from "..";
import { apiGet, apiPost, apiPut } from "../../utils/api";
import { bootstrap } from "../bootstrap/thunks";
import * as actions from "./actions";
import { IUpdateEntryData } from "./types";

export const fetchEntrySummary =
  (entryId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(`entry/${entryId}/`, dispatch, actions.fetchEntrySummary, {
        id: entryId,
      });
    } catch (e) {
      return;
    }
  };

export const fetchEntryHistory =
  (entryId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `entry/${entryId}/history/`,
        dispatch,
        actions.fetchEntryHistory,
        {
          id: entryId,
        }
      );
    } catch (e) {
      return;
    }
  };

export const fetchEntryEventPicks =
  (entryId: number, eventId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `entry/${entryId}/event/${eventId}/picks/`,
        dispatch,
        actions.fetchEntryEventPicks,
        {
          entry: entryId,
          event: eventId,
        }
      );
    } catch (e) {
      return;
    }
  };

export const updateEntry =
  (data: IUpdateEntryData): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    await apiPut("entry-update/", data, dispatch, actions.updateEntry);
    dispatch(bootstrap());
  };

export const fetchEntryTransfers =
  (entryId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `entry/${entryId}/transfers/`,
        dispatch,
        actions.fetchEntryTransfers,
        { id: entryId }
      );
    } catch (e) {
      return;
    }
  };

export const fetchEntryPhaseStandings =
  (entryId: number, phaseId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `entry/${entryId}/phase/${phaseId}/standings/`,
        dispatch,
        actions.fetchEntryPhaseStandings,
        { entry: entryId, phase: phaseId }
      );
    } catch (e) {
      return;
    }
  };

export const unsubscribeEntry =
  (signature: string): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    await apiPost(
      "entry/unsubscribe/",
      { signature },
      dispatch,
      actions.unsubscribeEntry
    );
    dispatch(bootstrap());
  };

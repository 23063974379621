import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "../bootstrap/actions";
import { IEvent, IState } from "./types";
import { fetchEventStatus } from "./actions";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addEvents):
        const newState: { [key: string]: IEvent } = {};
        action.payload.forEach((event) => {
          newState[event.id] = event;
        });
        return newState;
      default:
        return state;
    }
  },
  currentEventStatus: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(fetchEventStatus.success):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getEventsById = (state: RootState) => state.events.byId;

export const getEvents = (state: RootState) =>
  Object.keys(state.events.byId).map((e) => state.events.byId[e]);

export const getPreviousEvent = createSelector(getEvents, (events) => {
  const previousEvent = events.filter((e) => e.is_previous);
  return previousEvent.length ? previousEvent[0] : null;
});

export const getCurrentEvent = createSelector(getEvents, (events) => {
  const currentEvent = events.filter((e) => e.is_current);
  return currentEvent.length ? currentEvent[0] : null;
});

export const getNextEvent = createSelector(getEvents, (events) => {
  const nextEvent = events.filter((e) => e.is_next);

  return nextEvent.length ? nextEvent[0] : null;
});

export const getNextNEvents = (n: number = 3) =>
  createSelector(
    getEventsById,
    getNextEvent,

    (all, nxt) => {
      const events: IEvent[] = [];
      if (nxt) {
        for (let step = 0; step < n; step++) {
          if (all[nxt.id + step]) {
            events.push(all[nxt.id + step]);
          }
        }
      }

      return events;
    }
  );

export const getFutureEvents = createSelector(
  getEvents,
  getNextEvent,
  (events, next) => (next ? events.filter((e) => e.id >= next.id) : [])
);

export const getFinishedEvents = createSelector(getEvents, (events) =>
  events.filter((e) => e.finished)
);

export const getLastEvent = createSelector(getEventsById, (eventsById) => {
  const ids = Object.keys(eventsById).map((id) => Number(id));
  if (!ids.length) {
    return null;
  }
  return eventsById[Math.max(...ids)];
});

export const getCurrentEventStatus = (state: RootState) =>
  state.events.currentEventStatus;

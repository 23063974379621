import styled from "styled-components/macro";

export const Wrapper = styled.div`
  max-width: 1220px;
  min-height: 0;
  min-width: 0;
  margin: 0 auto;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
  }
`;

interface IMainProps {
  isWide?: boolean;
}

export const Main = styled.div<IMainProps>`
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: ${(props) => (props.isWide ? "100%" : "calc(100% / 3 * 2)")};
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: ${(props) => (props.isWide ? "100%" : "calc(100% / 4 * 3)")};
  }
`;

export const Secondary = styled.div`
  padding-top: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    width: calc(100% / 3);
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: calc(100% / 4 * 1);
  }
`;

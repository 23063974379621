import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";
import { IElementsById } from "../elements/types";
import { IMyTeamData, IPickProposed } from "./types";

const createAction = deprecated.createAction;

export const fetchMyTeam = createAsyncAction(
  "FETCH_MY_TEAM_START",
  "FETCH_MY_TEAM_SUCCESS",
  "FETCH_MY_TEAM_FAILURE"
)<void, { data: IMyTeamData; elementsById: IElementsById }, IError>();

export const saveMyTeam = createAsyncAction(
  "SAVE_MY_TEAM_START",
  "SAVE_MY_TEAM_SUCCESS",
  "SAVE_MY_TEAM_FAILURE"
)<void, { data: IMyTeamData; elementsById: IElementsById }, IError>();

export const substitutionStart = createAction(
  "SUBSTITUTION_START",
  (resolve) => {
    return (pick: IPickProposed, possibleReplacements: IPickProposed[]) =>
      resolve({ pick, possibleReplacements });
  }
);

export const substitutionStop = createAction(
  "SUBSTITUTION_STOP",
  (resolve) => () => resolve()
);

export const substitutionProcess = createAction(
  "SUBSTITUTION_PROCESS",
  (resolve) => (pick: IPickProposed, subsStart: number) =>
    resolve({ pick, subsStart })
);

export const changeCaptain = createAction(
  "CHANGE_CAPTAIN",
  (resolve) => (elementId: number) => resolve({ elementId, isVice: false })
);

export const changeViceCaptain = createAction(
  "CHANGE_VICE_CAPTAIN",
  (resolve) => (elementId: number) => resolve({ elementId, isVice: true })
);

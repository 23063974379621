import { size } from "polished";
import * as React from "react";
import styled from "styled-components/macro";
import { IElement } from "../core/store/elements/types";
import { ReactComponent as Info } from "../img/icons/info.svg";
import { ReactComponent as Status } from "../img/icons/status.svg";

const StyledInfo = styled(Info)`
  ${size(14)}
  display: block;
  margin: auto;

  .box {
    fill: ${({ theme }) => theme.colors.white};
  }

  .icon {
    fill: ${({ theme }) => theme.colors.blue};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledStatus = styled(Status)<{ copnr: number }>`
  ${size(14)}
  display: block;
  margin: auto;
  fill: ${(props) => props.theme.colors.elementStatus[props.copnr].bg};
  color: ${(props) => props.theme.colors.elementStatus[props.copnr].color};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

interface IProps {
  element: IElement;
}

const ElementStatus: React.FC<IProps> = ({ element }) => {
  const copnr = element.chance_of_playing_next_round as number;
  return element.news ? <StyledStatus copnr={copnr} /> : <StyledInfo />;
};

export default ElementStatus;

import { size } from "polished";
import styled from "styled-components/macro";
import { ReactComponent as BaseDreamTeam } from "../../img/icons/dreamteam.svg";

const DreamTeam = styled(BaseDreamTeam)`
  ${size(14)};
  color: white;
  fill: #005baa;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

export default DreamTeam;

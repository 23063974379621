import styled from "styled-components/macro";

const Title = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 1.6rem;
  font-weight: bold;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    font-size: ${({ theme }) => theme.fontSizes[7]};
  }
`;

export default Title;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components/macro";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IH2HMatch } from "../../core/store/leagues/types";
import Table from "../Table";
import H2HMatch from "./H2HMatch";

const StyledMatchesTable = styled(Table)`
  table-layout: fixed;
  white-space: nowrap;

  /* Have to override Table td border to include tiebrake inside match border-bottom */
  td {
    border-top: 1px solid ${({ theme }) => theme.colors.grey};
    border-bottom: 0;
  }

  tr:last-child td {
    border-bottom: 1px solid ${({ theme }) => theme.colors.grey};
  }
`;

const MatchesRoundColRound = styled.th`
  width: 10%;
`;

const MatchesRoundColEntry = styled.th`
  width: 32%;
`;

const MatchesRoundColEntry1 = styled.th`
  width: 32%;

  ${StyledMatchesTable} & {
    text-align: end;
  }
`;

export const MatchesRoundColScore = styled.th`
  width: 21%;
`;

interface IProps {
  selectedEntry: number;
  isCup?: boolean;
  isAllMine?: boolean;
  matches: IH2HMatch[] | null;
}

const MatchesTable: React.FC<IProps> = ({
  selectedEntry,
  isAllMine = false,
  isCup = false,
  matches,
}) => {
  const { t } = useTranslation();
  const now = useSelector(getCurrentEvent);

  if (!matches) {
    return null;
  }

  return (
    <StyledMatchesTable>
      <thead>
        <tr>
          <MatchesRoundColRound scope="col">
            {t("matchesTable.rd", "RD")}
          </MatchesRoundColRound>
          <MatchesRoundColEntry1 scope="col">
            {t("matchesTable.team1", "Team")}
          </MatchesRoundColEntry1>
          <MatchesRoundColScore scope="col">&nbsp;</MatchesRoundColScore>
          <MatchesRoundColEntry scope="col">
            {t("matchesTable.team2", "Team")}
          </MatchesRoundColEntry>
        </tr>
      </thead>
      <tbody>
        {matches.map((m) => (
          <H2HMatch
            selectedEntry={selectedEntry}
            key={m.id}
            match={m}
            started={now ? m.event <= now.id : false}
            showEvent
            isAllMine={isAllMine}
            isCup={isCup}
          />
        ))}
      </tbody>
    </StyledMatchesTable>
  );
};

export default MatchesTable;

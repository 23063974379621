import * as React from "react";

interface IDialogProps {
  render: (
    showDialog: boolean,
    handleHide: () => void,
    handleShow: () => void
  ) => React.ReactNode;
}

interface IDialogState {
  readonly showDialog: boolean;
}

class DialogManager extends React.Component<IDialogProps, IDialogState> {
  public readonly state: Readonly<IDialogState> = { showDialog: false };

  public handleShow = () => {
    this.setState({ showDialog: true });
  };

  public handleHide = () => {
    this.setState({ showDialog: false });
  };

  public render() {
    return (
      <>
        {this.props.render(
          this.state.showDialog,
          this.handleShow,
          this.handleHide
        )}
      </>
    );
  }
}

export default DialogManager;

import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledScoreboardPanel = styled.div`
  padding: ${(props) => props.theme.space[2]} 0;
  text-align: center;
`;

const Heading = styled.h3`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.4rem;
  font-weight: 400;
`;

const Value = styled.div<IValue>`
  color: ${({ theme }) => theme.colors.blue};
  font-size: 2.3rem;
  font-weight: bold;

  ${(props) =>
    props.isError &&
    css`
      color: ${props.theme.colors.error};
    `}

  ${(props) =>
    props.isSuccess &&
    css`
      color: ${props.theme.colors.blue};
    `}
`;

interface IScoreboardPanel {
  heading: string;
  value: string;
}

interface IValue {
  isError?: boolean;
  isSuccess?: boolean;
}

type Props = IScoreboardPanel & IValue;

export const ScoreboardPanel: React.FC<Props> = ({
  heading,
  isError,
  isSuccess,
  value,
}) => (
  <StyledScoreboardPanel>
    <Heading>{heading}</Heading>
    <Value isError={isError} isSuccess={isSuccess}>
      {value}
    </Value>
  </StyledScoreboardPanel>
);

import { IElementBase } from "../store/elements/types";

export const isMoneyStat = (stat: any): boolean => {
  const moneyStats: Array<keyof IElementBase> = [
    "cost_change_event",
    "cost_change_event_fall",
    "cost_change_start",
    "cost_change_start_fall",
    "now_cost",
  ];
  return moneyStats.indexOf(stat) > -1;
};

export const integerToMoney = (int: number, divisor: number) =>
  (int / divisor).toFixed(1);

export const integerToMoneyFloat = (int: number, divisor: number) =>
  int / divisor;

export const moneyToInteger = (money: string, divisor: number) =>
  Math.round(parseFloat(money) * divisor);

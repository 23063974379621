export const FetchAPI = async (
  uri: string,
  data: Record<string, string>,
  method: string
) => {
  await fetch(`/api/${uri}`, {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const FetchPost = async (uri: string, data: Record<string, string>) =>
  FetchAPI(uri, data, "POST");

export const FetchPut = async (uri: string, data: Record<string, string>) =>
  FetchAPI(uri, data, "PUT");

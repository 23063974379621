import produce from "immer";
import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import { getEntry } from "../entries/reducers";
import { IEntry, ILeagueEntry } from "../entries/types";
import { getPlayerData } from "../player/reducers";
import * as actions from "./actions";
import { getInitialState } from "./autoJoinPersistentStorage";
import {
  IClassicStanding,
  IH2HStanding,
  INewEntry,
  IRenewableLeague,
  IState,
} from "./types";

const classicStandingsById = produce(
  (draft: IState["classicStandingsById"], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchClassicLeagueStandings.success): {
        const { data, phaseId } = action.payload;
        const newData = {
          league: data.league,
          newEntriesByPage: draft[data.league.id]?.newEntriesByPage || {},
          standingsByPhaseAndPage:
            draft[data.league.id]?.standingsByPhaseAndPage || {},
        };
        newData["newEntriesByPage"][data.new_entries.page] = data.new_entries;
        if (!newData["standingsByPhaseAndPage"][phaseId]) {
          newData["standingsByPhaseAndPage"][phaseId] = {};
        }
        newData["standingsByPhaseAndPage"][phaseId][data.standings.page] = {
          ...data.standings,
          lastUpdatedData: data.last_updated_data,
        };
        draft[data.league.id] = newData;
        break;
      }
      case getType(actions.deleteLeague.success): {
        if (draft[action.payload.league]) {
          delete draft[action.payload.league];
        }
      }
    }
  },
  {}
);

const getH2HMatchesKey = (entryId: number, eventId: number, page: number) =>
  `${entryId}-${eventId}-${page}`;

const h2hMatchesById = produce(
  (draft: IState["h2hMatchesById"], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchH2HLeagueMatches.success): {
        const { data, entryId, eventId, leagueId } = action.payload;
        const key = getH2HMatchesKey(entryId, eventId, data.page);
        const newData = {
          byEntryEventPage: draft[leagueId]?.byEntryEventPage || {},
        };
        newData["byEntryEventPage"][key] = data;
        draft[leagueId] = newData;
        break;
      }
    }
  },
  {}
);

// State Reducer
export default combineReducers<IState, RootAction>({
  adminById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.createClassicLeague.success):
      case getType(actions.fetchClassicLeagueForAdmin.success):
      case getType(actions.updateClassicLeague.success):
      case getType(actions.createH2HLeague.success):
      case getType(actions.fetchH2HLeagueForAdmin.success):
      case getType(actions.updateH2HLeague.success):
        return {
          ...state,
          [action.payload.id]: action.payload,
        };
      case getType(actions.deleteLeague.success): {
        const newState = { ...state };
        if (newState[action.payload.league]) {
          delete newState[action.payload.league];
        }
        return newState;
      }
      default:
        return state;
    }
  },
  byCode: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.checkLeagueCode.success): {
        return {
          ...state,
          [action.payload.code]: action.payload.data,
        };
      }
      default:
        return state;
    }
  },
  autoJoinCode: (state = getInitialState(), action: RootAction) => {
    switch (action.type) {
      case getType(actions.setAutoJoinCode):
        return action.payload;
      case getType(actions.clearAutoJoinCode):
        return null;
      default:
        return state;
    }
  },
  classicStandingsById,
  codesByLeagueId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchLeagueCode.success):
      case getType(actions.regenerateLeagueCode.success):
        return {
          ...state,
          [action.payload.league]: action.payload.data.code,
        };
      case getType(actions.deleteLeague.success): {
        const newState = { ...state };
        if (newState[action.payload.league]) {
          delete newState[action.payload.league];
        }
        return newState;
      }
      default:
        return state;
    }
  },
  createClassicLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.createClassicLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
  createH2HLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.createH2HLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
  cupStatusById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchLeagueCupStatus.success):
        return {
          ...state,
          [action.payload.qualifying_league]: action.payload,
        };
      default:
        return state;
    }
  },
  h2hMatchesById,
  h2hStandingsById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchH2HLeagueStandings.success): {
        const data = action.payload;
        const s = state[data.league.id];
        return {
          ...state,
          [data.league.id]: {
            league: data.league,
            newEntriesByPage: {
              ...(s ? s.newEntriesByPage : {}),
              [data.new_entries.page]: data.new_entries,
            },
            standingsByPage: {
              ...(s ? s.standingsByPage : {}),
              [data.standings.page]: data.standings,
            },
          },
        };
      }
      case getType(actions.deleteLeague.success): {
        const newState = { ...state };
        if (newState[action.payload.league]) {
          delete newState[action.payload.league];
        }
        return newState;
      }
      default:
        return state;
    }
  },
  entriesById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchLeagueEntries.success): {
        return {
          ...state,
          [action.payload.leagueId]: action.payload.data,
        };
      }
      default:
        return state;
    }
  },
  entriesErrorById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchLeagueEntries.failure): {
        if (action.payload.url) {
          const match = action.payload.url.match(/^league\/(\d+)\/entries\/$/);
          if (match) {
            return {
              ...state,
              [match[1]]: action.payload,
            };
          }
        }
        return state;
      }
      default:
        return state;
    }
  },
  joinPrivateLeagueSuccess: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.joinPrivateLeague.success):
        return action.payload.data.id;
      default:
        return null;
    }
  },
  joinPrivateLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.joinPrivateLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
  joinPublicLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.joinPublicLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
  renewableById: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchRenewableLeagues.success): {
        const newState: Record<string, IRenewableLeague> = {};
        action.payload.forEach((rl) => {
          newState[rl.id] = rl;
        });
        return newState;
      }
      case getType(actions.deleteRenewableLeague.success):
      case getType(actions.renewRenewableLeague.success): {
        const newState = { ...state };
        delete newState[action.payload.id];
        return newState;
      }
      default:
        return state;
    }
  },
  updateClassicLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.updateClassicLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
  updateH2HLeagueError: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.updateH2HLeague.failure):
        return action.payload;
      default:
        return null;
    }
  },
});

// State Selectors and Helpers
export const getLeagueAdmin = (state: RootState, leagueId: number) =>
  state.leagues.adminById[leagueId] || null;

export const getCode = (state: RootState, leagueId: number) =>
  state.leagues.codesByLeagueId[leagueId] || null;

export const getRenewableLeagues = (state: RootState) =>
  Object.values(state.leagues.renewableById);

export const getCreateClassicLeagueError = (state: RootState) =>
  state.leagues.createClassicLeagueError;

export const getCreateH2HLeagueError = (state: RootState) =>
  state.leagues.createH2HLeagueError;

export const getJoinPrivateLeagueError = (state: RootState) =>
  state.leagues.joinPrivateLeagueError;

export const getJoinPublicLeagueError = (state: RootState) =>
  state.leagues.joinPublicLeagueError;

export const getUpdateClassicLeagueError = (state: RootState) =>
  state.leagues.updateClassicLeagueError;

export const getUpdateH2HLeagueError = (state: RootState) =>
  state.leagues.updateH2HLeagueError;

export const getClassicLeague = (state: RootState, leagueId: number) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  return leagueData ? leagueData.league : null;
};

export const getJoinPrivateLeagueSuccess = (state: RootState) =>
  typeof state.leagues.joinPrivateLeagueSuccess === "number";

const addEntryToTopOfNewEntries = (newEntries: INewEntry[], entry: IEntry) => [
  {
    entry: entry.id,
    entry_name: entry.name,
    player_first_name: entry.player_first_name,
    player_last_name: entry.player_last_name,
    joined_time: "", // We don't have so leave as a string
  },
  ...newEntries.filter((ne) => ne.entry !== entry.id),
];

export const getActiveEntryInLeague = (
  state: RootState,
  leagueId: number,
  scoring: "classic" | "h2h"
) => {
  const player = getPlayerData(state);
  if (player && player.entry) {
    const entry = getEntry(state, player.entry);
    if (entry) {
      const matches = entry.leagues[scoring].filter((l) => l.id === leagueId);
      if (matches.length) {
        return {
          entry,
          league: matches[0],
        };
      }
    }
  }
  return null;
};

const activeToClassicStanding = (
  entry: IEntry,
  league: ILeagueEntry
): IClassicStanding => ({
  entry: entry.id,
  entry_name: entry.name,
  event_total: entry.summary_event_points || 0,
  last_rank: league.entry_last_rank || 0,
  player_name: `${entry.player_first_name} ${entry.player_last_name}`,
  rank: league.entry_rank || 0,
  rank_sort: 0, // Need to add this
  total: entry.summary_overall_points || 0, // Needs to be league
});

const activeToH2HStanding = (
  entry: IEntry,
  league: ILeagueEntry
): IH2HStanding => ({
  division: 0,
  entry: entry.id,
  entry_name: entry.name,
  last_rank: league.entry_last_rank || 0,
  matches_drawn: 0,
  matches_lost: 0,
  matches_played: 0,
  matches_won: 0,
  player_name: `${entry.player_first_name} ${entry.player_last_name}`,
  points_for: 0,
  rank: league.entry_rank || 0,
  rank_sort: 0,
  total: 0,
});

// TODO - getClassicNewEntries and getH2HNewEntries are almost the same,
// refactor.
export const getClassicNewEntries = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  if (
    leagueData &&
    leagueData.newEntriesByPage[page] &&
    leagueData.league.league_type !== "s"
  ) {
    if (page === 1) {
      const activeEntry = getActiveEntryInLeague(state, leagueId, "classic");
      if (activeEntry && !activeEntry.league.entry_rank) {
        return {
          ...leagueData.newEntriesByPage[page],
          results: addEntryToTopOfNewEntries(
            leagueData.newEntriesByPage[page].results,
            activeEntry.entry
          ),
        };
      }
    }
    return leagueData.newEntriesByPage[page];
  }
  return null;
};

export const getClassicStandings = (
  state: RootState,
  leagueId: number,
  phaseId: number,
  page: number
) => {
  const leagueData = state.leagues.classicStandingsById[leagueId];
  if (
    leagueData &&
    leagueData.standingsByPhaseAndPage[phaseId] &&
    leagueData.standingsByPhaseAndPage[phaseId][page]
  ) {
    const standings = leagueData.standingsByPhaseAndPage[phaseId][page];
    const activeEntry = getActiveEntryInLeague(state, leagueId, "classic");
    // This needs some thought. We have hardcoded phase 1 as this data is
    // only for the overall phase but we should be fetching this data via
    // an api call - issue 789
    if (
      phaseId === 1 &&
      standings.results.length &&
      activeEntry &&
      activeEntry.league.entry_rank
    ) {
      if (!standings.results.some((cs) => cs.entry === activeEntry.entry.id)) {
        return {
          ...standings,
          results:
            activeEntry.league.entry_rank <= standings.results[0].rank
              ? [
                  activeToClassicStanding(
                    activeEntry.entry,
                    activeEntry.league
                  ),
                  ...standings.results,
                ]
              : [
                  ...standings.results,
                  activeToClassicStanding(
                    activeEntry.entry,
                    activeEntry.league
                  ),
                ],
        };
      }
    }
    return standings;
  }
  return null;
};

export const getH2HLeague = (state: RootState, leagueId: number) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  return leagueData ? leagueData.league : null;
};

export const getH2HNewEntries = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  if (leagueData && leagueData.newEntriesByPage[page]) {
    // Only add on 1st page and if we have some newEntries
    if (page === 1 && leagueData.newEntriesByPage[page].results.length) {
      const activeEntry = getActiveEntryInLeague(state, leagueId, "h2h");
      if (activeEntry && !activeEntry.league.entry_rank) {
        return {
          ...leagueData.newEntriesByPage[page],
          results: addEntryToTopOfNewEntries(
            leagueData.newEntriesByPage[page].results,
            activeEntry.entry
          ),
        };
      }
    }
    return leagueData.newEntriesByPage[page];
  }
  return null;
};

export const getH2HStandings = (
  state: RootState,
  leagueId: number,
  page: number
) => {
  const leagueData = state.leagues.h2hStandingsById[leagueId];
  if (leagueData && leagueData.standingsByPage[page]) {
    const standings = leagueData.standingsByPage[page];
    const activeEntry = getActiveEntryInLeague(state, leagueId, "h2h");
    if (
      activeEntry &&
      activeEntry.league.entry_rank &&
      standings.results.length
    ) {
      if (!standings.results.some((cs) => cs.entry === activeEntry.entry.id)) {
        return {
          ...standings,
          results:
            activeEntry.league.entry_rank <= standings.results[0].rank
              ? [
                  activeToH2HStanding(activeEntry.entry, activeEntry.league),
                  ...standings.results,
                ]
              : [
                  ...standings.results,
                  activeToH2HStanding(activeEntry.entry, activeEntry.league),
                ],
        };
      }
    }
    return standings;
  }
  return null;
};

export const getH2HMatches = (
  state: RootState,
  leagueId: number,
  entryId: number,
  eventId: number,
  page: number
) => {
  const key = getH2HMatchesKey(entryId, eventId, page);
  return state.leagues.h2hMatchesById[leagueId]?.byEntryEventPage[key] || null;
};

export const getLeagueFromCode = (state: RootState, code: string) =>
  state.leagues.byCode[code] || null;

export const getAutoJoinCode = (state: RootState) => state.leagues.autoJoinCode;

export const getLeagueEntries = (state: RootState, leagueId: number) =>
  state.leagues.entriesById[leagueId] || null;

export const getLeagueEntriesError = (state: RootState, leagueId: number) =>
  state.leagues.entriesErrorById[leagueId] || null;

export const getAllLeagueCupStatus = (state: RootState) =>
  state.leagues.cupStatusById;

export const getLeagueCupStatus = (state: RootState, leagueId: number) =>
  state.leagues.cupStatusById[leagueId] || null;

import { Link } from "@reach/router";
import * as React from "react";
import styled, { css } from "styled-components/macro";

interface IArrowProps {
  variant?: string;
  disabled?: boolean;
  justifyContent?: string;
}

const ButtonLinkStyles = css<IArrowProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[3]};
  border: 0;
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes[2]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.green};
  text-decoration: none;

  ${(props) =>
    props.variant === "secondary" &&
    css`
      background: ${({ theme }) => theme.colors.green};
      color: ${({ theme }) => theme.colors.primary};
      text-transform: uppercase;
    `}
  ${(props) =>
    props.justifyContent === "flexStart" &&
    css`
      justify-content: flex-start;
    `}
    ${(props) =>
    props.justifyContent === "flexEnd" &&
    css`
      justify-content: flex-end;
    `};
`;

export const ButtonHyperlink = styled.a<IArrowProps>`
  ${ButtonLinkStyles}
`;

const StyledButtonLink = styled(Link)<IArrowProps>`
  ${ButtonLinkStyles}
`;

export const ButtonLinkCenter = styled.span`
  flex: 1;
  text-align: center;
`;

interface IProps {
  onClick?: () => void;
  to: string;
  children: React.ReactNode;
  variant?: string;
  disabled?: boolean;
  justifyContent?: string;
}

type Props = IProps;

const ButtonLink: React.FC<Props> = ({
  children,
  onClick,
  to,
  variant = "primary",
  disabled = false,
  justifyContent,
}) => (
  <StyledButtonLink
    to={to}
    onClick={onClick}
    variant={variant}
    disabled={disabled}
    justifyContent={justifyContent}
  >
    {children}
  </StyledButtonLink>
);

export default ButtonLink;

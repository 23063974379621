import { createAsyncAction, deprecated } from "typesafe-actions";
import { IError } from "..";
import { ILeagueEntry } from "../entries/types";
import {
  IClassicStandingsAPIData,
  IEntryInLeague,
  IH2HMatchesData,
  IH2HStandingsAPIData,
  ILeague,
  ILeagueAdmin,
  ILeagueCode,
  ILeagueCupStatus,
  IRenewableLeague,
} from "./types";

const createAction = deprecated.createAction;

// Action Creators
export const createClassicLeague = createAsyncAction(
  "CREATE_CLASSIC_LEAGUE_START",
  "CREATE_CLASSIC_LEAGUE_SUCCESS",
  "CREATE_CLASSIC_LEAGUE_FAILURE"
)<void, ILeagueAdmin, IError>();

export const createH2HLeague = createAsyncAction(
  "CREATE_H2H_LEAGUE_START",
  "CREATE_H2H_LEAGUE_SUCCESS",
  "CREATE_H2H_LEAGUE_FAILURE"
)<void, ILeagueAdmin, IError>();

export const joinPrivateLeague = createAsyncAction(
  "JOIN_PRIVATE_LEAGUE_START",
  "JOIN_PRIVATE_LEAGUE_SUCCESS",
  "JOIN_PRIVATE_LEAGUE_FAILURE"
)<void, { entry: number; data: ILeagueEntry }, IError>();

export const joinPublicLeague = createAsyncAction(
  "JOIN_PUBLIC_LEAGUE_START",
  "JOIN_PUBLIC_LEAGUE_SUCCESS",
  "JOIN_PUBLIC_LEAGUE_FAILURE"
)<void, { entry: number; data: ILeagueEntry }, IError>();

export const fetchClassicLeagueStandings = createAsyncAction(
  "FETCH_CLASSIC_LEAGUE_STANDINGS_START",
  "FETCH_CLASSIC_LEAGUE_STANDINGS_SUCCESS",
  "FETCH_CLASSIC_LEAGUE_STANDINGS_FAILURE"
)<void, { phaseId: number; data: IClassicStandingsAPIData }, IError>();

export const fetchH2HLeagueStandings = createAsyncAction(
  "FETCH_H2H_LEAGUE_STANDINGS_START",
  "FETCH_H2H_LEAGUE_STANDINGS_SUCCESS",
  "FETCH_H2H_LEAGUE_STANDINGS_FAILURE"
)<void, IH2HStandingsAPIData, IError>();

export const fetchH2HLeagueMatches = createAsyncAction(
  "FETCH_H2H_LEAGUE_MATCHES_START",
  "FETCH_H2H_LEAGUE_MATCHES_SUCCESS",
  "FETCH_H2H_LEAGUE_MATCHES_FAILURE"
)<
  void,
  { entryId: number; eventId: number; leagueId: number; data: IH2HMatchesData },
  IError
>();

export const fetchClassicLeagueForAdmin = createAsyncAction(
  "FETCH_CLASSIC_LEAGUE_FOR_ADMIN_START",
  "FETCH_CLASSIC_LEAGUE_FOR_ADMIN_SUCCESS",
  "FETCH_CLASSIC_LEAGUE_FOR_ADMIN_FAILURE"
)<void, ILeagueAdmin, IError>();

export const fetchH2HLeagueForAdmin = createAsyncAction(
  "FETCH_H2H_LEAGUE_FOR_ADMIN_START",
  "FETCH_H2H_LEAGUE_FOR_ADMIN_SUCCESS",
  "FETCH_H2H_LEAGUE_FOR_ADMIN_FAILURE"
)<void, ILeagueAdmin, IError>();

export const updateClassicLeague = createAsyncAction(
  "UPDATE_CLASSIC_LEAGUE_START",
  "UPDATE_CLASSIC_LEAGUE_SUCCESS",
  "UPDATE_CLASSIC_LEAGUE_FAILURE"
)<void, ILeagueAdmin, IError>();

export const updateH2HLeague = createAsyncAction(
  "UPDATE_H2H_LEAGUE_START",
  "UPDATE_H2H_LEAGUE_SUCCESS",
  "UPDATE_H2H_LEAGUE_FAILURE"
)<void, ILeagueAdmin, IError>();

export const deleteLeague = createAsyncAction(
  "DELETE_LEAGUE_START",
  "DELETE_LEAGUE_SUCCESS",
  "DELETE_LEAGUE_FAILURE"
)<void, { entry: number; league: number; data: {} }, IError>();

export const banLeagueEntry = createAsyncAction(
  "BAN_LEAGUE_ENTRY_START",
  "BAN_LEAGUE_ENTRY_SUCCESS",
  "BAN_LEAGUE_ENTRY_FAILURE"
)<void, ILeagueAdmin, IError>();

export const unbanLeagueEntry = createAsyncAction(
  "UNBAN_LEAGUE_ENTRY_START",
  "UNBAN_LEAGUE_ENTRY_SUCCESS",
  "UNBAN_LEAGUE_ENTRY_FAILURE"
)<void, ILeagueAdmin, IError>();

export const fetchLeagueCode = createAsyncAction(
  "FETCH_LEAGUE_CODE_START",
  "FETCH_LEAGUE_CODE_SUCCESS",
  "FETCH_LEAGUE_CODE_FAILURE"
)<void, { league: number; data: ILeagueCode }, IError>();

export const regenerateLeagueCode = createAsyncAction(
  "REGENERATE_LEAGUE_CODE_START",
  "REGENERATE_LEAGUE_CODE_SUCCESS",
  "REGENERATE_LEAGUE_CODE_FAILURE"
)<void, { league: number; data: ILeagueCode }, IError>();

export const leaveLeague = createAsyncAction(
  "LEAVE_LEAGUE_START",
  "LEAVE_LEAGUE_SUCCESS",
  "LEAVE_LEAGUE_FAILURE"
)<void, { entry: number; league: number; data: {} }, IError>();

export const fetchRenewableLeagues = createAsyncAction(
  "FETCH_RENEWABLE_LEAGUES_START",
  "FETCH_RENEWABLE_LEAGUES_SUCCESS",
  "FETCH_RENEWABLE_LEAGUES_FAILURE"
)<void, IRenewableLeague[], IError>();

export const renewRenewableLeague = createAsyncAction(
  "RENEW_RENEWABLE_LEAGUE_START",
  "RENEW_RENEWABLE_LEAGUE_SUCCESS",
  "RENEW_RENEWABLE_LEAGUE_FAILURE"
)<void, { id: number; data: {} }, IError>();

export const deleteRenewableLeague = createAsyncAction(
  "DELETE_RENEWABLE_LEAGUE_START",
  "DELETE_RENEWABLE_LEAGUE_SUCCESS",
  "DELETE_RENEWABLE_LEAGUE_FAILURE"
)<void, { id: number; data: {} }, IError>();

export const checkLeagueCode = createAsyncAction(
  "CHECK_LEAGUE_CODE_START",
  "CHECK_LEAGUE_CODE_SUCCESS",
  "CHECK_LEAGUE_CODE_FAILURE"
)<void, { code: string; data: ILeague }, IError>();

export const setAutoJoinCode = createAction(
  "SET_AUTO_JOIN_CODE",
  (resolve) => (code: string) => resolve(code)
);

export const clearAutoJoinCode = createAction(
  "CLEAR_AUTO_JOIN_CODE",
  (resolve) => () => resolve()
);

export const fetchLeagueEntries = createAsyncAction(
  "FETCH_LEAGUE_ENTRIES_START",
  "FETCH_LEAGUE_ENTRIES_SUCCESS",
  "FETCH_LEAGUE_ENTRIES_FAILURE"
)<void, { leagueId: number; data: IEntryInLeague[] }, IError>();

export const fetchLeagueCupStatus = createAsyncAction(
  "FETCH_LEAGUE_CUP_STATUS_START",
  "FETCH_LEAGUE_CUP_STATUS_SUCCESS",
  "FETCH_LEAGUE_CUP_STATUS_FAILURE"
)<void, ILeagueCupStatus, IError>();

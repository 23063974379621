import { Link as RouterLink } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components/macro";
import { RootState, ThunkDispatch } from "../core/store";
import {
  getEntry,
  getPrivateClassicCupLeaguesForEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicCupLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicCupLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../core/store/entries/reducers";
import { fetchEntrySummary } from "../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../core/store/entries/types";
import { getCurrentEvent } from "../core/store/events/reducers";
import { IEvent } from "../core/store/events/types";
import { getTotalPlayers } from "../core/store/game/reducers";
import { fetchMyTeam } from "../core/store/my-team/thunks";
import { ITransferState } from "../core/store/my-team/types";
import { getPlayerData } from "../core/store/player/reducers";
import { getTransferState } from "../core/store/squad/reducers";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";
import { fetchURL } from "../core/store/urls/thunks";
import { integerToMoney } from "../core/utils/money";
import Button from "./Button";
import Flag from "./Flag";
// import { kitFromEntry } from "./EntryUpdate";
import Footnote from "./Footnote";
import { ControlArrowRight } from "./icons/Arrows";
import { KeycloakEntryLinks } from "./KeycloakEntryLinks";
import Movement from "./leagues/Movement";
import MyLeagueCupSummary from "./leagues/MyLeagueCupSummary";
import { LeagueSummaryTable } from "./leagues/Styles";
import {
  getBroadcasterLeagues,
  getFilteredSystemClassicLeagues,
} from "./leagues/utils";
import Link from "./Link";
import LogoBar from "./LogoBar";
import Panel from "./Panel";
import TabHeading from "./TabHeading";
import Table from "./Table";
import EntryTabs from "./tabs/EntryTabs";
import TabPanel from "./tabs/TabPanel";
import { VisuallyHidden } from "./Utils";

const EntryNameWrapper = styled.div`
  padding: ${({ theme }) => theme.space[2]};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radii[1]};
`;

const EntryFullName = styled.h2`
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.space[1]}`};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.black};
  line-height: 24px;
`;

const EntryName = styled.h3`
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-end: ${({ theme }) => `${theme.space[1]}`};
  font-family: ${({ theme }) => theme.fonts.book};
  font-size: 17px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.black};
  line-height: 20px;
`;

const DataListItem = styled.li`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.space[2]} 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const DataListHeading = styled.h5`
  flex: 1 0 65%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const DataListValue = styled.div`
  flex: 1 0 35%;
  padding: 0 ${({ theme }) => theme.space[2]};
`;

const MovementCell = styled.td`
  width: 16%;
`;

const Rank = styled.td`
  width: 30%;
  font-weight: 800;
`;

const Name = styled.td`
  width: 54%;
  overflow-wrap: break-word;
  hyphens: auto;
`;

const linkToLeague = (l: ILeagueEntry) =>
  `/leagues/${l.id}/standings/${l.scoring}`;

const boldStyles = css`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

const BoldText = styled.span`
  ${boldStyles}
`;

const StyledLink = styled(Link)`
  ${boldStyles}
`;

const StyledTdFixedWidth = styled.td`
  width: 33%;
`;
interface ILeagueSummaryProps {
  title: React.ReactNode;
  leagues: ILeagueEntry[];
}

interface IRowProps {
  leagueEntry: ILeagueEntry;
}

const MovementHead = styled.th`
  width: 16%;
`;

const RankHead = styled.th`
  width: 30%;
`;

const NameHead = styled.th`
  width: 54%;
`;

interface IPropsFromState {
  entry: IEntry | null;
  mine: Boolean;
  now: IEvent | null;
  privateClassicLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
  teamsById: ITeamsById;
  totalPlayers: number;
  transfersState: ITransferState | null;
  privateClassicCupLeagues: ILeagueEntry[];
  publicClassicCupLeagues: ILeagueEntry[];
  systemClassicCupLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  fetchMyTeam: () => void;
  fetchUrl: (url: string) => void;
}

interface IOwnProps {
  entryId: number;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps & WithTranslation;

class Entry extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.mine && !this.props.transfersState) {
      this.props.fetchMyTeam();
    }
    if (!this.props.entry) {
      this.props.fetchEntrySummary(this.props.entryId);
    }
  }

  public render() {
    const {
      entry,
      privateClassicLeagues,
      publicClassicLeagues,
      privateH2HLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
      totalPlayers,
      transfersState,
      mine = false,
      t,
      privateClassicCupLeagues,
      publicClassicCupLeagues,
      systemClassicCupLeagues,
    } = this.props;

    if (!entry) {
      return null;
    }
    // const kit = kitFromEntry(entry);

    // Split broadcaster leagues out from system classic leagues
    const broadcasterLeagues = getBroadcasterLeagues(systemClassicLeagues);
    const filteredSystemClassicLeagues = getFilteredSystemClassicLeagues(
      systemClassicLeagues,
      broadcasterLeagues
    );

    const hasCupLeagues =
      privateClassicCupLeagues.length > 0 ||
      publicClassicCupLeagues.length > 0 ||
      systemClassicCupLeagues.length > 0;

    // Split broadcaster cup leagues out from system classic cup leagues
    const broadcasterCupLeagues = getBroadcasterLeagues(
      systemClassicCupLeagues
    );
    const filteredSystemCupLeagues = getFilteredSystemClassicLeagues(
      systemClassicCupLeagues,
      broadcasterCupLeagues
    );
    const LeagueSummary: React.FC<ILeagueSummaryProps> = ({
      leagues,
      title,
    }) => (
      <div>
        <Box>
          <TabHeading hasBorder={true} title={title} />
        </Box>
        <LeagueSummaryTable>
          <thead>
            <tr>
              <MovementHead scope="col">
                <VisuallyHidden>
                  {t("myTeam.leagueSummary.movement", "Movement")}
                </VisuallyHidden>
              </MovementHead>
              <RankHead scope="col">
                {t("myTeam.leagueSummary.rank", "Rank")}
              </RankHead>
              <NameHead scope="col">
                {t("myTeam.leagueSummary.league", "League")}
              </NameHead>
            </tr>
          </thead>
          <tbody>
            {leagues.map((l) => (
              <LeagueSummaryRow key={l.id} leagueEntry={l} />
            ))}
          </tbody>
        </LeagueSummaryTable>
      </div>
    );

    const LeagueSummaryRow: React.FC<IRowProps> = ({ leagueEntry }) => (
      <tr>
        <MovementCell>
          <Movement
            lastRank={leagueEntry.entry_last_rank}
            rank={leagueEntry.entry_rank}
          />
        </MovementCell>
        <Rank>
          {leagueEntry.entry_rank
            ? leagueEntry.entry_rank.toLocaleString()
            : null}
        </Rank>
        <Name>
          <StyledLink to={linkToLeague(leagueEntry)}>
            {leagueEntry.name}
          </StyledLink>
        </Name>
      </tr>
    );

    return (
      <Box px={2}>
        <EntryNameWrapper>
          <EntryFullName>
            {entry.player_first_name} {entry.player_last_name}
          </EntryFullName>

          <LogoBar justifyContent="space-between">
            <EntryName>{entry.name}</EntryName>
            <Flag code={entry.player_region_iso_code_short} />
          </LogoBar>
        </EntryNameWrapper>

        <Box mt={2} pb={4}>
          <Panel hasKeyline={false}>
            <Panel.Header
              variant={"secondary"}
              title={t("myTeam.pointsRankings", "Points/Rankings")}
            />
            <Panel.Body>
              <Table size="lg">
                <tbody>
                  <tr>
                    <td>{t("myTeam.overallPoints", "Overall Points")}:</td>
                    <td>
                      <BoldText>{entry.summary_overall_points}</BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.overallRank", "Overall Rank")}:</td>
                    <td>
                      <BoldText>
                        {entry.summary_overall_rank &&
                          entry.summary_overall_rank.toLocaleString()}
                      </BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.totalPlayers", "Total Players")}:</td>
                    <td>
                      <BoldText>{totalPlayers.toLocaleString()}</BoldText>
                    </td>
                  </tr>
                  <tr>
                    <td>{t("myTeam.roundPoints", "Round Points")}:</td>
                    <td>
                      <BoldText>{entry.summary_event_points}</BoldText>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Panel.Body>
            <Panel.Footer>
              <StyledLink to={`/entry/${entry.id}/history`}>
                {t("myTeam.viewRoundHistory", "View Round history")}
              </StyledLink>
            </Panel.Footer>
          </Panel>
        </Box>
        <Box pb={4}>
          <Panel hasKeyline={false}>
            <Panel.Header
              variant="secondary"
              title={t("myTeam.leaguesCups", "Leagues & Cups")}
            />
            <Panel.Body>
              <EntryTabs>
                <TabPanel
                  label={t("myTeam.leaguesTab", "Leagues")}
                  link="leagues"
                >
                  {broadcasterLeagues.length > 0 && (
                    <LeagueSummary
                      title={t("myTeam.broadcaster", "Broadcaster League")}
                      leagues={broadcasterLeagues}
                    />
                  )}
                  {privateClassicLeagues.length > 0 && (
                    <LeagueSummary
                      title={t(
                        "myTeam.privateClassic",
                        "Private Classic Leagues"
                      )}
                      leagues={privateClassicLeagues}
                    />
                  )}
                  {privateH2HLeagues.length > 0 && (
                    <LeagueSummary
                      title={t(
                        "myTeam.privateH2H",
                        "Private Head-to-Head Leagues"
                      )}
                      leagues={privateH2HLeagues}
                    />
                  )}
                  {publicClassicLeagues.length > 0 && (
                    <LeagueSummary
                      title={t(
                        "myTeam.publicClassic",
                        "Public Classic Leagues"
                      )}
                      leagues={publicClassicLeagues}
                    />
                  )}
                  {publicH2HLeagues.length > 0 && (
                    <LeagueSummary
                      title={t(
                        "myTeam.publicH2H",
                        "Public Head-to-Head Leagues"
                      )}
                      leagues={publicH2HLeagues}
                    />
                  )}
                  <LeagueSummary
                    title={t("myTeam.general", "General Leagues")}
                    leagues={filteredSystemClassicLeagues}
                  />
                </TabPanel>
                <TabPanel label={t("leaguesNav.cups", "Cups")} link="cups">
                  {hasCupLeagues ? (
                    <>
                      {privateClassicCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={privateClassicCupLeagues}
                          title={t("myTeam.leagueCups", "League cups")}
                        />
                      )}
                      {publicClassicCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={publicClassicCupLeagues}
                          title={t("myTeam.publicCups", "Public cups")}
                        />
                      )}
                      {filteredSystemCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={filteredSystemCupLeagues}
                          title={t("myTeam.generalCups", "General cups")}
                        />
                      )}
                      {broadcasterCupLeagues.length > 0 && (
                        <MyLeagueCupSummary
                          entryId={entry.id}
                          leagues={broadcasterCupLeagues}
                          title={t(
                            "myTeam.broadcasterCups",
                            "Broadcaster cups"
                          )}
                        />
                      )}
                    </>
                  ) : (
                    <Box m={2}>
                      <p>
                        {t(
                          "myTeam.none",
                          "None of your leagues have a cup yet."
                        )}
                      </p>
                    </Box>
                  )}
                </TabPanel>
              </EntryTabs>
            </Panel.Body>
          </Panel>
        </Box>

        <Box pb={4}>
          <Button width={1} to="/leagues" as={RouterLink}>
            {t("myTeam.createAndJoinLeagues", "Create and join leagues")}
          </Button>
        </Box>

        {mine && transfersState && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header
                variant={"secondary"}
                title={t(
                  "myTeam.trans.transfersAndFinance",
                  "Transfers And Finance"
                )}
              />
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>{t("myTeam.roundTransfers", "Round transfers")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>{transfersState.made}</BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.totalTransfers", "Total transfers")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {entry.last_deadline_total_transfers +
                            transfersState.made}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </StyledLink>
              </Panel.Footer>
              <Panel.Body>
                <Table size="lg">
                  <tbody>
                    <tr>
                      <td>{t("myTeam.squadValue", "Squad value")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.value, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                    <tr>
                      <td>{t("myTeam.inTheBank", "In the bank")}:</td>
                      <StyledTdFixedWidth>
                        <BoldText>
                          {integerToMoney(transfersState.bank, 10)}
                        </BoldText>
                      </StyledTdFixedWidth>
                    </tr>
                  </tbody>
                </Table>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {!mine && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header
                title={
                  <>
                    {t("myTeam.transfersAndFinance", "Transfers and Finance")}{" "}
                    <sup>*</sup>
                  </>
                }
              />
              <Panel.Body>
                <p />
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.totalTransfers", "Total transfers")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_total_transfers}
                    </DataListValue>
                  </DataListItem>
                </ul>
              </Panel.Body>
              <Panel.Footer>
                <StyledLink to={`/entry/${entry.id}/transfers`}>
                  {t("myTeam.viewTransferHistory", "View transfer history")}
                </StyledLink>
              </Panel.Footer>
              <Panel.Body>
                <ul>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.squadValue", "Squad value")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_value !== null &&
                      entry.last_deadline_bank !== null
                        ? `M SAR${integerToMoney(
                            entry.last_deadline_value -
                              entry.last_deadline_bank,
                            10
                          )}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                  <DataListItem>
                    <DataListHeading>
                      {t("myTeam.inTheBank", "In the bank")}:
                    </DataListHeading>
                    <DataListValue>
                      {entry.last_deadline_bank !== null
                        ? `M SAR${integerToMoney(entry.last_deadline_bank, 10)}`
                        : "-"}
                    </DataListValue>
                  </DataListItem>
                </ul>
                <Box mt={1} px={2}>
                  <Footnote>* Values at the last deadline</Footnote>
                </Box>
              </Panel.Body>
            </Panel>
          </Box>
        )}
        {mine && (
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header
                variant={"secondary"}
                title={t("myTeam.admin", "Admin")}
              />
              <Panel.Body>
                {mine && (
                  <Box p={1}>
                    <Box p={1}>
                      <Button
                        width={1}
                        variant="primary"
                        as={RouterLink}
                        to={`/entry-update`}
                        endIcon={<ControlArrowRight color={"white"} />}
                      >
                        {t("teamDetails", "Team details")}
                      </Button>
                    </Box>
                    <KeycloakEntryLinks />
                  </Box>
                )}
              </Panel.Body>
            </Panel>
          </Box>
        )}
      </Box>
    );
  }
}

export { Entry as EntryTest };

const mapStateToProps = (
  state: RootState,
  ownProps: IOwnProps
): IPropsFromState => {
  const player = getPlayerData(state);
  const entryId = ownProps.entryId;

  return {
    entry: getEntry(state, entryId),
    mine: Boolean(player && player.entry && player.entry === entryId),
    now: getCurrentEvent(state),
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entryId),
    privateClassicCupLeagues: getPrivateClassicCupLeaguesForEntry(
      state,
      entryId
    ),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entryId),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entryId),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entryId),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entryId),
    teamsById: getTeamsById(state),
    transfersState: getTransferState(state),
    totalPlayers: getTotalPlayers(state),
    publicClassicCupLeagues: getPublicClassicCupLeaguesForEntry(state, entryId),
    systemClassicCupLeagues: getSystemClassicCupLeaguesForEntry(state, entryId),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  fetchMyTeam: () => dispatch(fetchMyTeam()),
  fetchUrl: (url) => dispatch(fetchURL(url)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(Entry)
);

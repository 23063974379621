import { RouteComponentProps } from "@reach/router";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getLeagueAdmin } from "../../core/store/leagues/reducers";
import {
  fetchClassicLeagueForAdmin,
  updateClassicLeague,
} from "../../core/store/leagues/thunks";
import {
  IPrivateLeagueAdmin,
  IUpdateClassicLeagueData,
} from "../../core/store/leagues/types";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";
import AddBan from "./admin/AddBan";
import ChangeAdmin from "./admin/ChangeAdmin";
import ClassicDetails from "./admin/ClassicDetails";
import Code from "./admin/Code";
import Delete from "./admin/Delete";
import RemoveBan from "./admin/RemoveBan";

type OwnProps = RouteComponentProps<{ leagueId: string }>;

interface IPropsFromState {
  league: IPrivateLeagueAdmin | null;
  leagueNumber: number;
}

interface IPropsFromDispatch {
  fetchLeague: (leagueId: number) => void;
  updateLeague: (leagueId: number, data: IUpdateClassicLeagueData) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

class AdminClassic extends React.Component<Props> {
  public componentDidMount() {
    if (this.props.leagueNumber) {
      this.props.fetchLeague(this.props.leagueNumber);
    }
  }

  public render() {
    const { league, t, updateLeague } = this.props;
    if (!league) {
      return null;
    }
    return (
      <Wrapper>
        <Main>
          <Copy>
            <Title>{t("leagues.adminTitle", "League Administration")}</Title>
            <Code league={league} />
            <Box mt={4}>
              <ClassicDetails league={league} />
            </Box>
            <Box mt={4}>
              <AddBan league={league} />
            </Box>
            <Box mt={4}>
              <RemoveBan league={league} />
            </Box>
            <Box mt={4}>
              <ChangeAdmin
                league={league}
                updateAdmin={(entryId: number) =>
                  updateLeague(league.id, {
                    admin_entry: entryId,
                    closed: league.closed,
                    code_privacy: league.code_privacy,
                    name: league.name,
                    start_event: league.start_event,
                  })
                }
              />
            </Box>
            <Box mt={4}>
              <Delete league={league} />
            </Box>
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { AdminClassic as AdminClassicTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const leagueNumber =
    ownProps.leagueId && parseInt(ownProps.leagueId, 10)
      ? parseInt(ownProps.leagueId, 10)
      : 0;
  return {
    league: leagueNumber
      ? (getLeagueAdmin(state, leagueNumber) as IPrivateLeagueAdmin)
      : null,
    leagueNumber,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchLeague: (leagueId: number) =>
    dispatch(fetchClassicLeagueForAdmin(leagueId)),
  updateLeague: (leagueId: number, data: IUpdateClassicLeagueData) =>
    dispatch(updateClassicLeague(leagueId, data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AdminClassic)
);

import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "../bootstrap/actions";
import { IState, ITeam } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addTeams):
        const newState: { [key: string]: ITeam } = {};
        action.payload.forEach((team) => {
          newState[team.id] = team;
        });
        return newState;
      default:
        return state;
    }
  },
});

// State Selectors
export const getTeamsById = (state: RootState) => state.teams.byId;

export const getTeams = (state: RootState) =>
  Object.keys(state.teams.byId)
    .map((t) => state.teams.byId[t])
    .sort((a, b) => a.name.localeCompare(b.name));

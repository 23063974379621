import { RouteComponentProps } from "@reach/router";
import React from "react";
import Fixtures from "./Fixtures";
import { Main, Wrapper } from "./Layout";

const FixturesWrapper: React.FC<RouteComponentProps<{ eventId?: string }>> = ({
  eventId = 0,
}) => (
  <Wrapper>
    <Main>
      <Fixtures eventId={Number(eventId)} useLinks />
    </Main>
  </Wrapper>
);

export default FixturesWrapper;

import * as React from "react";
import styled from "styled-components/macro";
import Title from "./Title";

const StyledPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

const PageHeader: React.FC<IProps> = ({ children, title }) => (
  <StyledPageHeader>
    <Title>{title}</Title>
    {children}
  </StyledPageHeader>
);

export default PageHeader;

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getEvents } from "../../core/store/events/reducers";
import { SelectField } from "../FieldRenderers";
import { SelectBox } from "./Styles";

interface IProps {
  eventId: number;
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  startEvent: number | null;
}

const H2HEventFilter: React.FC<IProps> = ({
  eventId,
  handleFilterChange,
  startEvent,
}) => {
  const { t } = useTranslation();
  const events = useSelector(getEvents);

  if (!startEvent) {
    return null;
  }
  return (
    <SelectBox>
      <SelectField
        id="events"
        name="events"
        label={t("h2hEventFilter.round", "Round")}
        value={eventId}
        onChange={handleFilterChange}
      >
        <option value="0">{t("h2hEventFilter.all", "All Events")}</option>
        {events
          .filter((e) => e.id >= startEvent)
          .map((e) => (
            <option value={e.id} key={e.id}>
              {t("h2hEventFilter.rd", "RD {{ eId }}", { eId: e.id })}
              {e.is_current && t("h2hEventFilter.current", " - current")}
            </option>
          ))}
      </SelectField>
    </SelectBox>
  );
};

export default H2HEventFilter;

import { size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { ReactComponent as Sub } from "../../img/icons/sub.svg";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import {
  Controls,
  InfoControl,
  PitchElementWrap,
  PrimaryControl,
  StyledPitchElement,
} from "../Pitch";
import PitchElementData from "../PitchElementData";
import { UnstyledButton } from "../Utils";
import TeamElementIcon from "./TeamElementIcon";

const StyledSub = styled(Sub)`
  ${size(14)}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const ElementIconWrap = styled.span`
  position: absolute;
  top: 22%;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    right: 20px;
  }
`;

const DreamTeamWrap = styled.div`
  position: absolute;
  top: 44%;
  right: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    right: 10px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    right: 20px;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  actionMe: () => void;
  chipName: string | null;
  pick: IPickLight;
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: () => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

type Props = IPropsFromState & IPropsFromDispatch & IOwnProps;

const TeamPitchElement: React.FC<Props> = ({
  actionMe,
  chipName,
  pick,
  elementsById,
  renderElementMenu,
  renderPickValue,
  renderDreamTeam,
  settings,
}) => {
  if (!settings) return null;

  const element = elementsById[pick.element];

  return (
    <PitchElementWrap variant={pick.subStatus || ""}>
      <StyledPitchElement>
        <UnstyledButton type="button" onClick={renderElementMenu}>
          <ElementShirt
            elementId={element.id}
            hasShadow={true}
            sizes="(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px"
          />
          <PitchElementData element={element} value={renderPickValue(pick)} />
        </UnstyledButton>
        <Controls>
          {actionMe && typeof pick.subStatus !== "undefined" && (
            <PrimaryControl>
              <UnstyledButton type="button" onClick={actionMe}>
                <StyledSub />
              </UnstyledButton>
            </PrimaryControl>
          )}
          <InfoControl>
            <ElementDialogButton elementId={pick.element} />
          </InfoControl>
        </Controls>

        <ElementIconWrap>
          <TeamElementIcon pick={pick} chipName={chipName} />
        </ElementIconWrap>

        <DreamTeamWrap>{renderDreamTeam(pick)}</DreamTeamWrap>
      </StyledPitchElement>
    </PitchElementWrap>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  settings: getSettings(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamPitchElement);

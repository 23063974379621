import { combineReducers } from "redux";
import { RootAction, RootState } from "..";
import { IState, IDreamTeamElement } from "./types";
import * as actions from "./actions";
import { getType } from "typesafe-actions";
import { getElementsById, getFormation } from "../elements/reducers";

// State Reducer
export default combineReducers<IState, RootAction>({
  byEventId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchEventDreamTeam.success):
        return {
          ...state,
          [action.payload.event]: action.payload.data,
        };
      default:
        return state;
    }
  },
  overall: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchOverallDreamTeam.success):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getOverallDreamTeam = (state: RootState) =>
  state.dreamTeams.overall;

export const getEventDreamTeam = (state: RootState, eventId: number) => {
  if (!state.dreamTeams.byEventId || !state.dreamTeams.byEventId[eventId]) {
    return null;
  }
  return state.dreamTeams.byEventId[eventId];
};

export const getOverallDreamTeamFormation = (state: RootState) => {
  const data = getOverallDreamTeam(state);
  const elementsById = getElementsById(state);
  if (!data || !elementsById) {
    return "";
  }
  return getFormation(data.team.map((p) => elementsById[p.element]));
};

export const getEventDreamTeamFormation = (
  state: RootState,
  eventId: number
) => {
  const data = getEventDreamTeam(state, eventId);
  const elementsById = getElementsById(state);
  if (!data || !elementsById) {
    return "";
  }
  return getFormation(data.team.map((p) => elementsById[p.element]));
};

export const dreamTeamAsPickLight = (team: IDreamTeamElement[]) => {
  return team.map((e) => ({
    element: e.element,
    position: e.position,
    is_captain: false,
    is_vice_captain: false,
    multiplier: 1,
  }));
};

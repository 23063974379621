import { enableAllPlugins } from "immer";
import { combineReducers } from "redux";
import { ThunkAction, ThunkDispatch as BaseThunkDispatch } from "redux-thunk";
import { ActionType, deprecated, StateType } from "typesafe-actions";
import * as bootstrapActions from "./bootstrap/actions";
import bootstrap from "./bootstrap/reducers";
import * as chipActions from "./chips/actions";
import chips from "./chips/reducers";
import * as cupActions from "./cup/actions";
import cup from "./cup/reducers";
import * as dreamTeamActions from "./dream-teams/actions";
import dreamTeams from "./dream-teams/reducers";
import elementStats from "./element-stats/reducers";
import elementTypes from "./element-types/reducers";
import * as elementActions from "./elements/actions";
import elements from "./elements/reducers";
import * as entryActions from "./entries/actions";
import entries from "./entries/reducers";
import * as eventsActions from "./events/actions";
import events from "./events/reducers";
import * as fixtureActions from "./fixtures/actions";
import fixtures from "./fixtures/reducers";
import game from "./game/reducers";
import * as globalActions from "./global/actions";
import global from "./global/reducers";
import * as leagueActions from "./leagues/actions";
import leagues from "./leagues/reducers";
import * as myTeamActions from "./my-team/actions";
import myTeam from "./my-team/reducers";
import phases from "./phases/reducers";
import * as playerActions from "./player/actions";
import player from "./player/reducers";
import * as regionActions from "./regions/actions";
import regions from "./regions/reducers";
import * as squadActions from "./squad/actions";
import squad from "./squad/reducers";
import * as statsActions from "./stats/actions";
import stats from "./stats/reducers";
import teams from "./teams/reducers";
import * as urlActions from "./urls/actions";
import urls from "./urls/reducers";
import * as winnersActions from "./winners/actions";
import winners from "./winners/reducers";

enableAllPlugins();

const createAction = deprecated.createAction;

// This is an action which does nothing! Useful for setting initial state in
// tests
export const noAction = createAction("NoActionRequired");

const actions = {
  bootstrapActions,
  chipActions,
  cupActions,
  dreamTeamActions,
  elementActions,
  entryActions,
  eventsActions,
  fixtureActions,
  globalActions,
  leagueActions,
  myTeamActions,
  noAction,
  playerActions,
  regionActions,
  squadActions,
  statsActions,
  urlActions,
  winnersActions,
};

export type RootAction = ActionType<typeof actions>;

const rootReducer = combineReducers({
  bootstrap,
  chips,
  cup,
  dreamTeams,
  elementStats,
  elementTypes,
  elements,
  entries,
  events,
  fixtures,
  game,
  global,
  leagues,
  myTeam,
  phases,
  player,
  regions,
  squad,
  stats,
  teams,
  urls,
  winners,
});

export type RootState = StateType<typeof rootReducer>;

export type ThunkDispatch = BaseThunkDispatch<RootState, undefined, RootAction>;

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, RootAction>;

export interface ISelectOption {
  label: string;
  value: string;
}

export interface IError {
  errorText?: string;
  statusText?: string;
  badRequest?: {
    [key: string]: Array<{ code: string; message: string }>;
  };
  url?: string;
}

export type SavingState = "" | "saving" | "saved";

export default rootReducer;

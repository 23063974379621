import { RouteComponentProps } from "@reach/router";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import { RootState, ThunkDispatch } from "../../core/store";
import { getEventsById } from "../../core/store/events/reducers";
import { getLeagueCupStatus } from "../../core/store/leagues/reducers";
import { fetchLeagueCupStatus } from "../../core/store/leagues/thunks";
import { getIdFromEventName } from "../../utils/events";
import BoldLink from "../BoldLink";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import Title from "../Title";

const StyledMain = styled(Main)`
  width: 100%;
  padding: ${({ theme }) => `${theme.space[4]} ${theme.space[2]}`};
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    padding: ${({ theme }) => `${theme.space[8]} ${theme.space[4]}`};
  }
`;

const StyledSubheading = styled(SubHeading)`
  margin: ${({ theme }) => `${theme.space[8]} ${theme.space[0]}`};
`;

const StyledParagraph = styled.p`
  padding-top: ${({ theme }) => theme.space[4]};
`;

type OwnProps = RouteComponentProps<{ leagueId: string }>;

const CupNotStarted: React.FC<OwnProps> = ({ leagueId }) => {
  const leagueNumber = Number(leagueId!) || 0;
  const { t } = useTranslation();

  const cupStatus = useSelector((state: RootState) =>
    leagueNumber ? getLeagueCupStatus(state, leagueNumber) : null
  );
  const eventsById = useSelector(getEventsById);

  const cupEventNameId = useSelector((state: RootState) =>
    cupStatus?.qualification_event
      ? getIdFromEventName(eventsById[cupStatus.qualification_event].name)
      : null
  );
  const cupEventNameIdPlus = useSelector((state: RootState) =>
    cupStatus?.qualification_event
      ? getIdFromEventName(eventsById[cupStatus.qualification_event + 1].name)
      : null
  );

  const reduxDispatch = useDispatch<ThunkDispatch>();

  useEffect(() => {
    leagueNumber &&
      !cupStatus &&
      reduxDispatch(fetchLeagueCupStatus(leagueNumber));
  }, [leagueNumber, cupStatus, reduxDispatch]);

  if (!cupStatus) {
    return null;
  }

  return (
    <Wrapper>
      <StyledMain>
        <Copy>
          <Title>{cupStatus.name}</Title>

          <Box mt={2} mb={4}>
            <BoldLink to={`/leagues/${leagueNumber}/standings/c`}>
              {t("cupNotStarted.standings", "View league standings")}
            </BoldLink>
          </Box>

          {cupStatus.qualification_event ? (
            <>
              <StyledSubheading>
                {t(
                  "cupNotStarted.starts",
                  "The Cup is scheduled to start in RD {{ round }}",
                  { round: cupEventNameIdPlus }
                )}
              </StyledSubheading>
              {cupStatus.has_byes ? (
                <>
                  <p>
                    {t(
                      "cupNotStarted.fixtures",
                      "Fixtures will be determined at the end of Round {{ round }}",
                      { round: cupEventNameId }
                    )}{" "}
                    {cupStatus.qualification_numbers > 2 && (
                      <>
                        {t(
                          "cupNotStarted.qualify1",
                          "If there are {{ qualification_numbers }} teams in the associated league, then each team will have an opponent in Round {{ qualification_event }}.",
                          {
                            qualification_numbers:
                              cupStatus.qualification_numbers,
                            qualification_event: cupEventNameIdPlus,
                          }
                        )}
                        {cupStatus.qualification_numbers === 4 &&
                          t(
                            "cupNotStarted.qualify2",
                            "If there are 3 teams in the league, 1 team will receive a bye in Round {{ qualification_event_plus }} based on their score in Round {{ qualification_event_plus }}",
                            {
                              qualification_event_plus: cupEventNameIdPlus,
                              qualification_event: cupEventNameId,
                            }
                          )}{" "}
                        {t(
                          "cupNotStarted.qualify3",
                          "If there are between {{ qualification_numbers_div }} and {{ qualification_numbers_min }} teams in the league, some teams will receive a bye in Round {{ qualification_numbers_plus }} based on their score in Round {{ qualification_event }}",
                          {
                            qualification_numbers_div:
                              cupStatus.qualification_numbers / 2 + 1,
                            qualification_numbers_min:
                              cupStatus.qualification_numbers - 1,
                            qualification_numbers_plus: cupEventNameIdPlus,
                            qualification_event: cupEventNameId,
                          }
                        )}
                      </>
                    )}
                  </p>

                  <p>
                    {t(
                      "cupNotStarted.qualify4",
                      "The starting round of the cup is determined by the number of teams in the associated league and the final will be contested in Round 30."
                    )}
                  </p>
                  <p>
                    {t(
                      "cupNotStarted.qualify5",
                      "You will not be entered into the cup if you have joined the league after the Round prior to the cup starting."
                    )}
                  </p>

                  <StyledSubheading>
                    {t("cupNotStarted.qualify6", "How the cup works")}
                  </StyledSubheading>
                  <p>
                    {t(
                      "cupNotStarted.qualify7",
                      "Each team will be randomly drawn against another unless they have received a bye. The winner (the team with the highest Round score minus any transfer points), will progress to the process continues until the final round when the two remaining teams contest the cup final. If a cup match is drawn, then the following tie-breaks will be applied until a winner is found:"
                    )}
                  </p>
                  <ol>
                    <li>
                      {t(
                        "cupNotStarted.qualify8",
                        "Most goals scored in the Round"
                      )}
                    </li>
                    <li>
                      {t(
                        "cupNotStarted.qualify9",
                        "Fewest goals conceded in the Round"
                      )}
                    </li>
                    <li>{t("cupNotStarted.qualify10", "Virtual coin toss")}</li>
                  </ol>
                </>
              ) : (
                <>
                  <p>
                    {t(
                      "cupNotStarted.qualify11",
                      "The top {{qualification_numbers}} ranked managers in Round {{qualification_event}} will be entered into the first round of the cup in Round {{qualification_numbers_plus}}. The final will be contested in Round 30.",
                      {
                        qualification_numbers: cupStatus.qualification_numbers,
                        qualification_event: cupEventNameId,
                        qualification_numbers_plus: cupEventNameIdPlus,
                      }
                    )}
                  </p>

                  <StyledSubheading>
                    {t("cupNotStarted.qualify12", "How the cup works")}
                  </StyledSubheading>
                  <p>
                    {t(
                      "cupNotStarted.qualify13",
                      "Each qualifying team will be randomly drawn against another in the first round. The winner (the team with the highest Round score minus any transfer points), will progress to the second round and another random draw, the losers are out! This process continues until the final round when the two remaining teams contest the cup final. If a cup match is drawn, then the following tie-breaks will be applied until a winner is found:"
                    )}
                  </p>
                  <ol>
                    <li>
                      {t(
                        "cupNotStarted.qualify14",
                        "Most goals scored in the Round"
                      )}
                    </li>
                    <li>
                      {t(
                        "cupNotStarted.qualify15",
                        "Fewest goals conceded in the Round"
                      )}
                    </li>
                    <li>{t("cupNotStarted.qualify16", "Virtual coin toss")}</li>
                  </ol>
                </>
              )}
            </>
          ) : (
            <StyledParagraph>
              {t(
                "cupNotStarted.qualify17",
                "There is no information available for this cup yet."
              )}
            </StyledParagraph>
          )}
        </Copy>
      </StyledMain>
    </Wrapper>
  );
};

export default CupNotStarted;

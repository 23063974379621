import { createAsyncAction } from "typesafe-actions";
import { IError } from "..";
import { IRegion } from "./types";

// Action Creators
export const addRegions = createAsyncAction(
  "ADD_REGIONS_START",
  "ADD_REGIONS_SUCCESS",
  "ADD_REGIONS_FAILURE"
)<void, IRegion[], IError>();

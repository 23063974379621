import React from "react";
import styled from "styled-components/macro";

interface IProps {
  children: React.ReactNode;
}

const StyledDialogHeading = styled.div`
  flex: 0 0 72%;
  padding: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.fonts.impact};
  font-size: ${({ theme }) => theme.fontSizes[4]};
  line-height: 26px;
  font-weight: bold;
`;

const DialogHeading: React.FC<IProps> = ({ children }) => {
  return <StyledDialogHeading>{children}</StyledDialogHeading>;
};

export default DialogHeading;

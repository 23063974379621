async function _fetch(url: string) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return await response.json();
}
export interface IResidence {
  code: number;
  id: number;
  name: string;
  iso_code_short: string;
  iso_code_long: string;
}

// Residences
export async function fetchResidences(): Promise<IResidence> {
  const textUrl = "api/residences/";
  return _fetch(textUrl);
}

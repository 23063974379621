import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";
import { INavLink } from "../../core/hooks/types";
import useDocumentEventHandler from "../../core/hooks/useDocumentEventHandler";
import "./Dropdown.css";

const MoreButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1.8rem;
  border: 0;
  border-radius: 2px 2px 0 0;
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
  background-color: ${({ theme }) => theme.colors.blue};
  color: white;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 4.7rem;
  transition: all 0.2s;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  ::after {
    content: "";
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="%23ffffff" d="M15.9 24L0 8h32"/></svg>');
    display: inline-block;
    width: 18px;
    height: 16px;
    margin-inline-start: ${({ theme }) => theme.space[1]};
    margin-top: -2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    vertical-align: middle;
  }
`;

const NavLink = css`
  display: block;
  padding: 0 1.8rem;
  background-color: ${({ theme }) => theme.colors.primary};
  color: white;
  font-size: 1.4rem;
  line-height: 4.7rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

const NavReachLink = styled(Link)`
  ${NavLink}
`;

const NavHyperLink = styled.a`
  ${NavLink}
`;

type Props = {
  text: string;
  links: INavLink[];
};

const Dropdown: React.FunctionComponent<Props> = ({ text, links }) => {
  const [open, setOpen] = React.useState(false);
  useDocumentEventHandler({ event: "click", handler: () => setOpen(false) });
  const toggleOpen = (e: React.SyntheticEvent) => {
    e.nativeEvent.stopImmediatePropagation();
    setOpen((open) => !open);
  };

  return (
    <span className="dropdown" aria-haspopup="true">
      <MoreButton
        aria-controls="menu"
        className="button"
        type="button"
        onClick={toggleOpen}
      >
        {text}
      </MoreButton>
      <ul aria-hidden={!open} className={`list ${open && "show"}`}>
        {links.map((link) => (
          <li key={link.text}>
            {link.useRouter ? (
              <NavReachLink to={link.href}>{link.text}</NavReachLink>
            ) : (
              <NavHyperLink
                href={link.href}
                rel={link.rel}
                target={link.target}
              >
                {link.text}
              </NavHyperLink>
            )}
          </li>
        ))}
      </ul>
    </span>
  );
};

export default Dropdown;

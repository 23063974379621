import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useSelector, useDispatch } from "react-redux";
import { getPlayerData } from "../../core/store/player/reducers";
import { ThunkDispatch } from "../../core/store";
import { setCode, clearCode } from "../../core/store/leagues/thunks";

// AutoJoin component simply stores the code in local storage and redirects
// the user somewhere sensible.

type OwnProps = RouteComponentProps<{ code: string }>;

const AutoJoin: React.FC<OwnProps> = ({ code }) => {
  const player = useSelector(getPlayerData);
  const reduxDispatch = useDispatch<ThunkDispatch>();
  useEffect(() => {
    code ? reduxDispatch(setCode(code)) : reduxDispatch(clearCode());
  });
  const next =
    player && player.entry ? "/my-team" : player ? "/squad-selection" : "/";

  // Need this to make sure LocationProvider is ready
  setTimeout(() => {
    return navigate(next, {
      replace: true,
    });
  }, 0);
  return <div />;
};

export default AutoJoin;

import React from "react";

import { INavLink } from "./types";
import useWindowSize from "./useWindowSize";

type Props = {
  links: INavLink[];
  offset?: number; //px
  isRTL?: boolean;
};

function arraysEqual<T>(a: Array<T>, b: Array<T>): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

const usePriorityNav = ({ links, offset = 0, isRTL = false }: Props) => {
  const windowSize = useWindowSize();
  const [childrenWidths, setWidths] = React.useState<number[]>([]);
  const [visibleLinks, setVisibleLinks] = React.useState<INavLink[]>(links);
  const [nonVisibleLinks, setNonVisibleLinks] = React.useState<INavLink[]>([]);
  const [oldLinks, setLinks] = React.useState<INavLink[]>(links);
  // If the links have changed then we need to reset everything to force
  // the nav to be re-calculated
  if (oldLinks.length && oldLinks !== links) {
    setWidths([]);
    setVisibleLinks(links);
    setNonVisibleLinks([]);
    setLinks(links);
  }

  /**
   * Calc cumulative widths of ref elements
   *
   * Refs are non null on mount using callback
   * Callback is then only called afterwards
   * when an item is unmounted or remounted.
   */
  const measuredRef = React.useCallback(
    (node: HTMLLIElement) => {
      if (node !== null && childrenWidths.length !== links.length) {
        setWidths((childrenWidths) => {
          return isRTL
            ? childrenWidths.concat(node.getBoundingClientRect().left + offset)
            : childrenWidths.concat(
                node.getBoundingClientRect().right + offset
              );
        });
      }
    },
    [childrenWidths.length, links.length, offset, isRTL]
  );

  /**
   * Compute visible and non visible ref elements
   */
  React.useLayoutEffect(() => {
    // called first before childrenWidths are computed
    if (childrenWidths.length === 0) {
      return;
    }

    // After orientation change window.screen.width
    // can be smaller than windowSize.innerWidth,
    // so select smallest of two.
    const width =
      window.screen.width < windowSize.innerWidth
        ? window.screen.width
        : windowSize.innerWidth;

    const filteredIndexedElements = childrenWidths.filter(
      (e) => e < width - offset
    );
    const newVisibleLinks = links.filter(
      (_, i) => i < filteredIndexedElements.length
    );
    const newNonVisibleLinks = links.filter(
      (_, i) => i >= filteredIndexedElements.length
    );

    // only update links if they are different to previous visible links
    const areEqual =
      arraysEqual<INavLink>(visibleLinks, newVisibleLinks) &&
      arraysEqual<INavLink>(nonVisibleLinks, newNonVisibleLinks);
    if (!areEqual) {
      setVisibleLinks(newVisibleLinks);
      setNonVisibleLinks(newNonVisibleLinks);
    }
  }, [
    childrenWidths,
    windowSize.innerWidth,
    links,
    visibleLinks,
    nonVisibleLinks,
    offset,
  ]);

  return [measuredRef, visibleLinks, nonVisibleLinks] as [
    typeof measuredRef,
    INavLink[],
    INavLink[]
  ];
};

export default usePriorityNav;

import { size } from "polished";
import React from "react";
import { connect } from "react-redux";
import styled, { css } from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { ReactComponent as Captain } from "../../img/icons/captain.svg";
import { ReactComponent as ViceCaptain } from "../../img/icons/vice-captain.svg";
import { ReactComponent as x2 } from "../../img/icons/x2.svg";

const StyledCaptain = styled(Captain)<IStyledCaptainProps>`
  ${size(14)};
  color: white;

  ${(props) =>
    props.variant === "2capt" &&
    css`
      color: black;
      fill: #fac101;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledViceCaptain = styled(ViceCaptain)<IStyledCaptainProps>`
  ${size(14)};
  color: white;

  ${(props) =>
    props.variant === "2capt" &&
    css`
      color: black;
      fill: #fac101;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

const StyledX2 = styled(x2)`
  ${size(14)};
  color: #131924;
  fill: #e6eeff;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
}

interface IOwnProps {
  chipName: string | null;
  pick: IPickLight;
}

interface IStyledCaptainProps {
  variant?: string;
}

type Props = IPropsFromState & IOwnProps & IStyledCaptainProps;

const TeamElementIcon: React.FC<Props> = ({
  chipName,
  elementsById,
  pick,
  settings,
}) => {
  if (settings && pick.position > settings.squad_squadplay) return null;

  if (!chipName || chipName === "wildcard" || chipName === "uteam") {
    if (pick.is_captain) return <StyledCaptain />;
    if (pick.is_vice_captain) return <StyledViceCaptain />;
  }

  if (chipName === "2capt") {
    if (pick.is_captain) return <StyledCaptain variant={chipName} />;
    if (pick.is_vice_captain) return <StyledViceCaptain variant={chipName} />;
  }

  const element = elementsById[pick.element];

  if (chipName === "pdbus" && element.element_type === 2) {
    return <StyledX2 />;
  }

  return null;
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  settings: getSettings(state),
});

export default connect(mapStateToProps)(TeamElementIcon);

import { Link } from "@reach/router";
import React from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass";
import Button from "../Button";
import Copy from "../Copy";
import { Main, Wrapper } from "../Layout";
import SubHeading from "../SubHeading";
import TextDivider from "../TextDivider";
import Title from "../Title";
import { ControlArrowRight } from "../icons/Arrows";

const CreateJoin: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Box mb={4}>
          <Copy>
            <Title>{t("createJoin.title", "Create and join leagues")}</Title>
            <SubHeading>
              {t("createJoin.joinLeague.title", "Join a League & cup")}
            </SubHeading>
            <p>
              {t(
                "createJoin.joinLeague.p1",
                "Join a private or public league to compete against friends and other game players."
              )}
            </p>
            <Button
              variant="primary"
              as={Link}
              endIcon={<ControlArrowRight />}
              to="../join"
            >
              {t("createJoin.joinLeague.button", "Join a league & cup")}
            </Button>
            <TextDivider>{t("createJoin.or", "or")}</TextDivider>
            <SubHeading>
              {t("createJoin.createLeague.title", "Create a new league & cup")}
            </SubHeading>
            <p>
              {t(
                "createJoin.createLeague.p1",
                "Create a private league to compete against friends."
              )}
            </p>
            <Button
              variant="primary"
              as={Link}
              endIcon={<ControlArrowRight />}
              to="../create"
            >
              {t("createJoin.createLeague.button", "Create a league & cup")}
            </Button>
          </Copy>
        </Box>
      </Main>
    </Wrapper>
  );
};

export default CreateJoin;

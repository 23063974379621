import { rgba } from "polished";
import * as React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import { IElementType } from "../../core/store/element-types/types";
import { PitchElementWrap } from "../Pitch";
import PlaceholderShirt from "../PlaceholderShirt";

const StyledPlaceholder = styled.button`
  display: block;
  width: 6.4rem;
  margin: auto;
  padding: 0 0.3rem;
  border: 0;
  background-color: transparent;
  text-align: center;
  line-height: 1;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    width: 10.5rem;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    width: 14rem;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    padding-inline-end: 1rem;
    padding-inline-start: 1rem;
  }
`;

const Title = styled.div`
  padding: 0.1rem 0.2rem;
  background-color: ${rgba("#035f1d", 0.8)};
  color: white;
  font-size: 1rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    font-size: ${({ theme }) => theme.fontSizes[0]};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    font-size: ${({ theme }) => theme.fontSizes[1]};
  }
`;

const Abbr = styled.abbr`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    text-decoration: none;
    text-transform: lowercase;
    ::after {
      content: attr(title);
    }
  }
`;

const Span = styled.span`
  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    display: none;
  }
`;

interface IProps {
  elementType: IElementType | null;
  onClick: () => void;
}

const PitchPlaceholder: React.FC<IProps> = ({ elementType, onClick }) => {
  const { t } = useTranslation();
  if (!elementType) {
    return null;
  }
  return (
    <PitchElementWrap>
      <StyledPlaceholder onClick={onClick}>
        <PlaceholderShirt
          elementType={elementType.id}
          sizes="(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px"
          hasShadow={true}
        />
        <Title data-testid="title">
          {t("add", "Add")}{" "}
          <Abbr title={elementType.singular_name}>
            <Span>{elementType.singular_name_short}</Span>
          </Abbr>
        </Title>
      </StyledPlaceholder>
    </PitchElementWrap>
  );
};

export default PitchPlaceholder;

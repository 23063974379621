import { size } from "polished";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { showElementSummary } from "../../core/store/elements/thunks";
import { IElementsById } from "../../core/store/elements/types";
import { ReactComponent as Remove } from "../../img/icons/remove.svg";
import ElementDialogButton from "../ElementDialogButton";
import ElementShirt from "../ElementShirt";
import {
  Controls,
  InfoControl,
  PitchElementWrap,
  PrimaryControl,
  StyledPitchElement,
} from "../Pitch";
import PitchElementData from "../PitchElementData";
import PlaceholderShirt from "../PlaceholderShirt";
import { UnstyledButton } from "../Utils";

const StyledRemove = styled(Remove)`
  ${size(14)}
  display: block;
  fill: #e9ff03;
  color: #e50012;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    ${size(16)}
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    ${size(20)}
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
}

interface IPropsFromDispatch {
  showElementDialog: (elementId: number) => void;
}

interface IOwnProps {
  actionMe: () => void;
  elementId: number;
  elementValue: React.ReactNode;
  renderElementMenu: () => void;
}

interface IStyleProps {
  isRemoved?: boolean;
}
type Props = IPropsFromState & IPropsFromDispatch & IOwnProps & IStyleProps;

const SquadPitchElement: React.FC<Props> = ({
  actionMe,
  elementId,
  elementsById,
  elementValue,
  isRemoved,
  renderElementMenu,
}) => {
  const element = elementsById[elementId];
  const shirtProps = {
    hasShadow: true,
    sizes: "(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px",
  };

  return (
    <PitchElementWrap>
      <StyledPitchElement isRemoved={isRemoved}>
        <UnstyledButton type="button" onClick={renderElementMenu}>
          {isRemoved ? (
            <PlaceholderShirt
              elementType={element.element_type}
              {...shirtProps}
            />
          ) : (
            <ElementShirt {...shirtProps} elementId={element.id} />
          )}
          <PitchElementData element={element} value={elementValue} />
        </UnstyledButton>
        <Controls>
          <PrimaryControl>
            <UnstyledButton type="button" onClick={actionMe}>
              <StyledRemove />
            </UnstyledButton>
          </PrimaryControl>
          <InfoControl>
            <ElementDialogButton elementId={element.id} />
          </InfoControl>
        </Controls>
      </StyledPitchElement>
    </PitchElementWrap>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SquadPitchElement);

import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import * as actions from "../bootstrap/actions";
import { IState } from "./types";

export default combineReducers<IState, RootAction>({
  settings: (state = null, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addGameSettings):
        return action.payload;
      default:
        return state;
    }
  },
  total_players: (state = 0, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addTotalPlayers):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getSettings = (state: RootState) => state.game.settings;

export const getTotalPlayers = (state: RootState) => state.game.total_players;

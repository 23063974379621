import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  bestClassicPrivateLeagues: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchBestClassicPrivateLeagues.success):
        return action.payload;
      default:
        return state;
    }
  },
  mostValuableTeams: (state = [], action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchMostValuableTeams.success):
        return action.payload;
      default:
        return state;
    }
  },
});

// State Selectors
export const getBestClassicPrivateLeagues = (state: RootState) =>
  state.stats.bestClassicPrivateLeagues;

export const getMostValuableTeams = (state: RootState) =>
  state.stats.mostValuableTeams;

import range from "lodash/range";
import React from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IError, RootState, ThunkDispatch } from "../../core/store";
import { getFutureEvents } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getSettings } from "../../core/store/game/reducers";
import { ISettings } from "../../core/store/game/types";
import { getCreateH2HLeagueError } from "../../core/store/leagues/reducers";
import { createH2HLeague } from "../../core/store/leagues/thunks";
import { ICreateH2HLeagueData } from "../../core/store/leagues/types";
import Button from "../Button";
import Copy from "../Copy";
import { FieldWrap, InputField, SelectField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";

interface IPropsFromState {
  error: IError | null;
  events: IEvent[];
  settings: ISettings;
}

interface IPropsFromDispatch {
  createH2HLeague: (data: ICreateH2HLeagueData) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

interface IState {
  koRounds: number;
  maxEntries: number;
  name: string;
  startEvent: number;
}

class CreateH2H extends React.Component<Props, IState> {
  public requiredFields: Array<keyof IState> = ["name", "startEvent"];

  public constructor(props: Props) {
    super(props);
    this.state = {
      koRounds: 0,
      maxEntries: 0,
      name: "",
      startEvent: props.events.length ? props.events[0].id : 1,
    };
  }

  public maximumKoRounds = () => {
    const remainingEvents = this.props.events.length;
    if (remainingEvents <= 1) {
      return 0;
    }
    return Math.min(
      this.props.settings.league_max_ko_rounds_private_h2h,
      remainingEvents - 1
    );
  };

  public minimumSize = (koRounds: number) =>
    this.state.koRounds ? Math.pow(2, koRounds) : 2;

  public validStartingEvents = (koRounds: number) =>
    koRounds ? this.props.events.slice(0, -koRounds) : this.props.events;

  public apiDataFromState = (): ICreateH2HLeagueData => ({
    ko_rounds: this.state.koRounds || null,
    max_entries: this.state.maxEntries || null,
    name: this.state.name,
    start_event: this.state.startEvent,
  });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleStartEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ startEvent: parseInt(e.currentTarget.value, 10) });

  public handleMaxEntriesChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ maxEntries: parseInt(e.currentTarget.value, 10) });

  public handleKoRoundsChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const koRounds = parseInt(e.currentTarget.value, 10);
    const minimumSize = this.minimumSize(koRounds);
    const events = this.validStartingEvents(koRounds);
    this.setState({
      koRounds,
      maxEntries: this.state.maxEntries
        ? Math.max(minimumSize, this.state.maxEntries)
        : 0,
      startEvent: Math.min(events[events.length - 1].id, this.state.startEvent),
    });
  };

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.createH2HLeague(this.apiDataFromState());
  };

  public render() {
    const { error, events, settings, t } = this.props;
    if (!events.length) {
      return null;
    }
    const disabled = this.requiredFields.some((e) => !this.state[e]);
    return (
      <Wrapper>
        <Main>
          <Copy>
            <Title>
              {t("createH2H.title", "Create a new head-to-head league")}
            </Title>
            {error ? (
              error.badRequest &&
              error.badRequest.non_field_errors &&
              error.badRequest.non_field_errors[0].code ===
                "max_private_entries" ? (
                <p>
                  {t(
                    "createH2H.errors.maxPrivateEntry",
                    "You may only enter {{ maxPrivateEntries }} private leagues",
                    {
                      maxPrivateEntries:
                        settings && settings.league_join_private_max,
                    }
                  )}
                </p>
              ) : (
                <p>{JSON.stringify(error)}</p>
              )
            ) : (
              ""
            )}
            <form onSubmit={this.handleSubmit}>
              <FieldWrap>
                <InputField
                  id="ismLeagueName"
                  label={t("createH2H.leagueName.label", "League name")}
                  value={this.state.name}
                  onChange={this.handleNameChange}
                  hint={t("createH2H.leagueName.hint", "Maximum 30 characters")}
                  maxLength={30}
                />
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="ismLeagueStartEvent"
                  value={this.state.startEvent}
                  onChange={this.handleStartEventChange}
                  label={t("createH2H.startEventLabel", "Scoring starts")}
                >
                  {this.validStartingEvents(this.state.koRounds).map((e) => (
                    <option
                      value={e.id}
                      aria-selected={e.id === this.state.startEvent}
                      key={e.id}
                    >
                      {e.name}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="ismLeagueMaxSize"
                  value={this.state.maxEntries}
                  onChange={this.handleMaxEntriesChange}
                  label={t("createH2H.maxSize.label", "Maximum size")}
                >
                  <option value={0} aria-selected={!this.state.maxEntries}>
                    {t("createH2H.maxSize.unlimited", "Unlimited")}
                  </option>
                  {range(
                    this.minimumSize(this.state.koRounds),
                    settings.league_max_size_private_h2h + 1
                  ).map((num) => (
                    <option
                      value={num}
                      aria-selected={num === this.state.maxEntries}
                      key={num}
                    >
                      {num}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              {this.maximumKoRounds() > 0 && (
                <FieldWrap>
                  <SelectField
                    id="ismLeagueKoRounds"
                    value={this.state.koRounds}
                    onChange={this.handleKoRoundsChange}
                    label={t("createH2H.koRounds.label", "Knockout rounds")}
                  >
                    <option value={0} aria-selected={!this.state.koRounds}>
                      {t("createH2H.maxSize.none", "None")}
                    </option>
                    {range(1, this.maximumKoRounds() + 1).map((num) => (
                      <option
                        value={num}
                        aria-selected={num === this.state.koRounds}
                        key={num}
                      >
                        {num}
                      </option>
                    ))}
                  </SelectField>
                </FieldWrap>
              )}
              <FieldWrap>
                <Button variant="primary" type="submit" disabled={disabled}>
                  {t("createH2H.createLeagues", "Create league")}
                </Button>
              </FieldWrap>
            </form>
          </Copy>
        </Main>
      </Wrapper>
    );
  }
}

export { CreateH2H as CreateH2HTest };

const mapStateToProps = (state: RootState) => ({
  error: getCreateH2HLeagueError(state),
  events: getFutureEvents(state),
  settings: getSettings(state) as ISettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  createH2HLeague: (data: ICreateH2HLeagueData) =>
    dispatch(createH2HLeague(data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(CreateH2H)
);

import * as React from "react";
import styled, { css } from "styled-components/macro";

const StyledPanel = styled.div`
  padding: ${({ theme }) => theme.space[1]};
  border: 1px solid ${({ theme }) => theme.colors.black};
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledPanelHeader = styled.div<IPanelHeaderProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => `${theme.radii[1]} ${theme.radii[1]} 0 0`};
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.variant === "secondary" &&
    css`
      border-radius: ${({ theme }) => theme.radii[1]};
      background-color: ${({ theme }) => theme.colors.green};
      color: ${({ theme }) => theme.colors.black};
      text-transform: uppercase;
    `}
`;

const PanelHeading = styled.h4`
  flex: 1;
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: inherit;
  font-family: ${({ theme }) => theme.fonts.base};
`;

const StyledPanelFooter = styled.div`
  padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  border-top: 1px solid ${({ theme }) => theme.colors.whiteGrey};
  text-align: center;
`;

interface IPanelHeaderProps {
  title?: React.ReactNode;
  children?: React.ReactNode;
  variant?: string;
}

const PanelHeader: React.FC<IPanelHeaderProps> = ({
  children,
  title,
  variant,
}) => (
  <StyledPanelHeader variant={variant}>
    <PanelHeading>{title}</PanelHeading>
    {children}
  </StyledPanelHeader>
);

interface IPanelBodyProps {
  children: React.ReactNode;
}

const PanelBody: React.FC<IPanelBodyProps> = ({ children }) => (
  <div>{children}</div>
);

interface IPanelFooterProps {
  children: React.ReactNode;
}

const PanelFooter: React.FC<IPanelFooterProps> = ({ children }) => (
  <StyledPanelFooter>{children}</StyledPanelFooter>
);

interface IPanelProps {
  children?: React.ReactNode;
  hasKeyline?: boolean;
}

class Panel extends React.Component<IPanelProps> {
  public static Header = PanelHeader;
  public static Body = PanelBody;
  public static Footer = PanelFooter;

  render() {
    const { children } = this.props;
    return (
      <>
        <StyledPanel>{children}</StyledPanel>
      </>
    );
  }
}

export default Panel;

import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementTypesBySquadPosition } from "../../core/store/element-types/reducers";
import { IElementTypesBySquadPosition } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElement, IElementsById } from "../../core/store/elements/types";
import { getFixturesForNextEventByTeam } from "../../core/store/fixtures/reducers";
import { IFixturesForEventByTeam } from "../../core/store/fixtures/types";
import {
  getProposedElements,
  getSavedPicks,
} from "../../core/store/squad/reducers";
import { IProposedElements, ISavedPicks } from "../../core/store/squad/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import { integerToMoney } from "../../core/utils/money";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import { ElementCell, ElementRow, ElementTable } from "../ElementTable";
import { FixturesForElement } from "../FixtureForElement";
import { ScrollTable } from "../Table";
import Tooltip, { TooltipLabel } from "../Tooltip";

const SquadListStatus = styled.th`
  width: 7%;
`;

const SquadListElement = styled.th`
  width: 28%;
`;

const SquadListPrice = styled.th`
  width: 11%;
`;

const SquadListForm = styled.th`
  width: 8%;
`;

const SquadListPoints = styled.th`
  width: 9%;
`;

const SquadListFixture = styled.th`
  width: 15%;
`;

interface ISquadListRowProps {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  pos: number;
  renderElementMenu: (position: number) => void;
  savedPicks: ISavedPicks;
  teamsById: ITeamsById;
}

const TransfersListRow: React.FC<ISquadListRowProps> = ({
  elementsById,
  elementTypesByPosition,
  fixturesForNextEventByTeam,
  proposedElements,
  pos,
  renderElementMenu,
  savedPicks,
  teamsById,
}) => {
  if (!elementTypesByPosition) {
    return null;
  }

  let element = proposedElements[pos];
  // TODO - We need a visual indicator of the row elementState, in the past we
  // have rendered the '0' shirt instead of the regular one
  let elementState: "original" | "removed" | "replaced" = "original";
  if (!element) {
    element = elementsById[savedPicks[pos].element];
    elementState = "removed";
  } else if (element.id !== savedPicks[pos].element) {
    elementState = "replaced";
  }

  return (
    <ElementRow>
      <td>
        <ElementDialogButton elementId={element.id} variant="list" />
      </td>
      <ElementCell>
        <ElementInTable
          renderElementMenu={() => renderElementMenu(pos)}
          element={element}
          team={teamsById[element.team]}
        />
      </ElementCell>
      <td>{integerToMoney(element.now_cost, 10)}</td>
      {elementState === "replaced" ? (
        <>
          <td>{integerToMoney(element.now_cost, 10)}</td>
          <td>{integerToMoney(element.now_cost, 10)}</td>
        </>
      ) : (
        <>
          <td>{integerToMoney(savedPicks[pos].selling_price, 10)}</td>
          <td>{integerToMoney(savedPicks[pos].purchase_price, 10)}</td>
        </>
      )}
      <td>{element.form}</td>
      <td>{element.total_points}</td>
      <td>
        <FixturesForElement
          delimiter={<br />}
          fixtures={fixturesForNextEventByTeam[element.team]}
          element={element}
          teamsById={teamsById}
        />
      </td>
    </ElementRow>
  );
};

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesByPosition: IElementTypesBySquadPosition | null;
  fixturesForNextEventByTeam: IFixturesForEventByTeam;
  proposedElements: IProposedElements;
  savedPicks: ISavedPicks;
  teamsById: ITeamsById;
}

interface IOwnProps {
  elementType: number;
  positions: number[];
  renderElementDialog: (element: IElement) => void;
  renderElementMenu: (pos: number) => void;
}

type Props = IPropsFromState & IOwnProps & WithTranslation;

const TransfersListTable: React.SFC<Props> = ({
  t,
  elementType,
  elementTypesByPosition,
  positions,
  ...rest
}) => {
  if (!elementTypesByPosition) {
    return null;
  }
  return (
    <ScrollTable>
      <ElementTable elementType={elementType}>
        <thead>
          <tr>
            <SquadListStatus>&nbsp;</SquadListStatus>
            <SquadListElement>
              {elementTypesByPosition[positions[0]].thisType.plural_name}
            </SquadListElement>
            <SquadListPrice>
              <Tooltip
                content={t("transferListTable.currentPrice", "Current Price")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.currentPrice", "CP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPrice>
            <SquadListPrice>
              <Tooltip
                content={t("transferListTable.sellingPrice", "Selling Price")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.sellingPrice", "SP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPrice>
            <SquadListPrice>
              <Tooltip
                content={t("transferListTable.purchasePrice", "Purchase Price")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.purchasePrice", "PP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPrice>
            <SquadListForm>
              <Tooltip content={t("transferListTable.form", "Form")}>
                <TooltipLabel>
                  {t("squadListTabel.label.form", "F")}
                </TooltipLabel>
              </Tooltip>
            </SquadListForm>
            <SquadListPoints>
              <Tooltip
                content={t("transferListTable.totalPoints", "Total Points")}
              >
                <TooltipLabel>
                  {t("squadListTabel.label.totalPoints", "TP")}
                </TooltipLabel>
              </Tooltip>
            </SquadListPoints>
            <SquadListFixture>
              <Tooltip
                content={t(
                  "transferListTable.fixtureNextRound",
                  "Fixture next Round"
                )}
              >
                <TooltipLabel>
                  {t("transferListTable.label.fixtureNext", "Fix")}
                </TooltipLabel>
              </Tooltip>
            </SquadListFixture>
          </tr>
        </thead>
        <tbody>
          {positions.map((p) => (
            <TransfersListRow
              key={p}
              pos={p}
              elementTypesByPosition={elementTypesByPosition}
              {...rest}
            />
          ))}
        </tbody>
      </ElementTable>
    </ScrollTable>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  elementsById: getElementsById(state),
  elementTypesByPosition: getElementTypesBySquadPosition(state),
  fixturesForNextEventByTeam: getFixturesForNextEventByTeam(state),
  proposedElements: getProposedElements(state),
  savedPicks: getSavedPicks(state),
  teamsById: getTeamsById(state),
});

export default withTranslation()(connect(mapStateToProps)(TransfersListTable));

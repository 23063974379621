import { size } from "polished";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import Facebook from "../img/icons/social/facebook.svg";
import Instagram from "../img/icons/social/instagram.svg";
import LinkedIn from "../img/icons/social/linkedin.svg";
import SnapChat from "../img/icons/social/snapchat.svg";
import TikTok from "../img/icons/social/tiktok.svg";
import X from "../img/icons/social/x.svg";
import YouTube from "../img/icons/social/youtube.svg";
import { ReactComponent as FooterLogo } from "../img/logo-footer.svg";

const StyledFooter = styled.footer`
  padding-top: ${({ theme }) => theme.space[6]};
  padding-bottom: ${({ theme }) => theme.space[0]};
  background-color: ${({ theme }) => theme.colors.blueDarker4};
  font-family: ${({ theme }) => theme.fonts.light};
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 600;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space[6]};
`;

const StyledBox = styled(Box)`
  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin-inline-start: 0;
  }
`;

const Logo = styled(FooterLogo)`
  ${size(135)}
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.greyLight2};
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    text-align: start;
  }
`;

const SocialIconsWrapper = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.space[4]};
  padding: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    justify-content: start;
    gap: ${({ theme }) => theme.space[6]};
  }

  > li {
    list-style: none;
  }
`;

const SocialIcon = styled.img`
  width: 2.6rem;
  height: 2.6rem;
  cursor: pointer;
`;

const SocialMedia = [
  {
    name: "Facebook",
    icon: Facebook,
    link: "https://www.facebook.com/spl/",
  },
  {
    name: "Instagram",
    icon: Instagram,
    link: "https://www.instagram.com/spl_en/",
  },
  {
    name: "X",
    icon: X,
    link: "https://twitter.com/SPL_EN",
  },
  {
    name: "TikTok",
    icon: TikTok,
    link: "https://www.tiktok.com/@spl",
  },
  {
    name: "Youtube",
    icon: YouTube,
    link: "https://www.youtube.com/@-saudiproleague",
  },
  {
    name: "Snapchat",
    icon: SnapChat,
    link: "https://www.snapchat.com/add/spl",
  },
  {
    name: "Linkedin",
    icon: LinkedIn,
    link: "https://www.linkedin.com/company/saudi-pro-league/",
  },
];

const Footer = () => {
  const { t } = useTranslation();

  return (
    <StyledFooter>
      <Inner>
        <StyledBox m={"auto"} pb={3}>
          <Logo />
        </StyledBox>
        <ContentWrapper>
          <Copyright>
            <p>
              {t(
                "footer.copyright",
                "All rights are reserved Saudi Pro League Portal © 2023"
              )}
            </p>
          </Copyright>
          <SocialIconsWrapper>
            {SocialMedia.map((item, name) => (
              <li key={name}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  <SocialIcon src={item.icon} alt={item.name} />
                </a>
              </li>
            ))}
          </SocialIconsWrapper>
        </ContentWrapper>
      </Inner>
    </StyledFooter>
  );
};

export default Footer;

import { createAsyncAction } from "typesafe-actions";
import { IWinner } from "./types";
import { IError } from "..";

export const fetchEventWinners = createAsyncAction(
  "FETCH_EVENT_WINNERS_START",
  "FETCH_EVENT_WINNERS_SUCCESS",
  "FETCH_EVENT_WINNERS_FAILURE"
)<void, { event: number; data: IWinner[] }, IError>();

export const fetchPhaseWinners = createAsyncAction(
  "FETCH_PHASE_WINNERS_START",
  "FETCH_PHASE_WINNERS_SUCCESS",
  "FETCH_PHASE_WINNERS_FAILURE"
)<void, { phase: number; data: IWinner[] }, IError>();

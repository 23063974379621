import range from "lodash/range";
import React from "react";
import { connect } from "react-redux";
import { RootState, ThunkDispatch } from "../../../core/store";
import { getFutureEvents } from "../../../core/store/events/reducers";
import { IEvent } from "../../../core/store/events/types";
import { getSettings } from "../../../core/store/game/reducers";
import { ISettings } from "../../../core/store/game/types";
import { updateH2HLeague } from "../../../core/store/leagues/thunks";
import {
  IPrivateLeagueAdmin,
  IUpdateH2HLeagueData,
  LeagueCodePrivacy,
} from "../../../core/store/leagues/types";
import Button from "../../Button";
import {
  CheckboxField,
  InputField,
  SelectField,
  FieldWrap,
} from "../../FieldRenderers";
import SubHeading from "../../SubHeading";
import { Box } from "rebass/styled-components";
import { withTranslation, WithTranslation } from "react-i18next";

interface IOwnProps {
  league: IPrivateLeagueAdmin;
  started: boolean;
}

interface IPropsFromState {
  events: IEvent[];
  settings: ISettings;
}

interface IPropsFromDispatch {
  updateLeague: (leagueId: number, data: IUpdateH2HLeagueData) => void;
}

type Props = IOwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  codePrivacy: LeagueCodePrivacy;
  closed: boolean;
  koRounds: number;
  maxEntries: number;
  name: string;
  startEvent: number;
}

class H2HDetails extends React.Component<Props, IState> {
  public requiredFields: Array<keyof IState> = [
    "codePrivacy",
    "name",
    "startEvent",
  ];

  public privacyOptions: { [key: string]: string } = {
    a: "All game players",
    l: "League members",
    p: "League administrator",
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      closed: props.league.closed,
      codePrivacy: props.league.code_privacy,
      koRounds: props.league.ko_rounds || 0,
      maxEntries: props.league.max_entries || 0,
      name: props.league.name,
      startEvent: props.league.start_event,
    };
  }

  public maximumKoRounds = () => {
    const remainingEvents = this.props.events.length;
    if (remainingEvents <= 1) {
      return 0;
    }
    return Math.min(
      this.props.settings.league_max_ko_rounds_private_h2h,
      remainingEvents - 1
    );
  };

  public minimumSize = (koRounds: number) =>
    this.state.koRounds ? Math.pow(2, koRounds) : 2;

  public validStartingEvents = (koRounds: number) =>
    koRounds ? this.props.events.slice(0, -koRounds) : this.props.events;

  public apiDataFromState = (): IUpdateH2HLeagueData => ({
    admin_entry: this.props.league.admin_entry,
    closed: this.state.closed,
    code_privacy: this.state.codePrivacy,
    ko_rounds: this.state.koRounds || null,
    max_entries: this.state.maxEntries || null,
    name: this.state.name,
    start_event: this.state.startEvent,
  });

  public handleNameChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ name: e.currentTarget.value });

  public handleCodePrivacyChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ codePrivacy: e.currentTarget.value as LeagueCodePrivacy });

  public handleStartEventChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ startEvent: parseInt(e.currentTarget.value, 10) });

  public handleClosedChange = (e: React.FormEvent<HTMLInputElement>) =>
    this.setState({ closed: e.currentTarget.checked ? true : false });

  public handleMaxEntriesChange = (e: React.FormEvent<HTMLSelectElement>) =>
    this.setState({ maxEntries: parseInt(e.currentTarget.value, 10) });

  public handleKoRoundsChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const koRounds = parseInt(e.currentTarget.value, 10);
    const minimumSize = this.minimumSize(koRounds);
    const events = this.validStartingEvents(koRounds);
    this.setState({
      koRounds,
      maxEntries: this.state.maxEntries
        ? Math.max(minimumSize, this.state.maxEntries)
        : 0,
      startEvent: Math.min(events[events.length - 1].id, this.state.startEvent),
    });
  };

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.updateLeague(this.props.league.id, this.apiDataFromState());
  };

  public render() {
    const { settings, started, t } = this.props;
    const disabled = this.requiredFields.some((e) => !this.state[e]);
    return (
      <>
        <SubHeading>{t("h2hDetails.details", "League details")}</SubHeading>
        <form onSubmit={this.handleSubmit}>
          <FieldWrap>
            <InputField
              id="ismLeagueName"
              label={t("h2hDetails.labels.name", "League name")}
              value={this.state.name}
              onChange={this.handleNameChange}
              hint={t("h2hDetails.hints.max", "Maximum 30 characters")}
              maxLength={30}
            />
          </FieldWrap>
          {!started && (
            <>
              <FieldWrap>
                <SelectField
                  id="ismLeagueCodePrivacy"
                  value={this.state.codePrivacy}
                  onChange={this.handleCodePrivacyChange}
                  label={t(
                    "h2hDetails.labels.leagueCodePrivacy",
                    "League code privacy"
                  )}
                  hint={t(
                    "h2hDetails.hints.viewableBy",
                    "This sets who the league code is viewable by"
                  )}
                >
                  {Object.keys(this.privacyOptions).map((p) => (
                    <option
                      value={p}
                      aria-selected={p === this.state.codePrivacy}
                      key={p}
                    >
                      {this.privacyOptions[p]}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="ismLeagueStartEvent"
                  value={this.state.startEvent}
                  onChange={this.handleStartEventChange}
                  label={t("h2hDetails.labels.scoringStats", "Scoring starts")}
                  hint={t(
                    "h2hDetails.hints.reflected",
                    "Any change will be reflected after the next points update"
                  )}
                >
                  {this.validStartingEvents(this.state.koRounds).map((e) => (
                    <option
                      value={`${e.id}`}
                      aria-selected={e.id === this.state.startEvent}
                      key={e.id}
                    >
                      {e.name}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              <FieldWrap>
                <SelectField
                  id="ismLeagueMaxSize"
                  value={this.state.maxEntries}
                  onChange={this.handleMaxEntriesChange}
                  label={t("h2hDetails.labels.maxSize", "Maximum size")}
                >
                  <option value={0} aria-selected={!this.state.maxEntries}>
                    {t("h2hDetails.labels.unlimited", "Unlimited")}
                  </option>
                  {range(
                    this.minimumSize(this.state.koRounds),
                    settings.league_max_size_private_h2h + 1
                  ).map((num) => (
                    <option
                      value={num}
                      aria-selected={num === this.state.maxEntries}
                      key={num}
                    >
                      {num}
                    </option>
                  ))}
                </SelectField>
              </FieldWrap>
              {this.maximumKoRounds() > 0 && (
                <SelectField
                  id="ismLeagueKoRounds"
                  value={this.state.koRounds}
                  onChange={this.handleKoRoundsChange}
                  label={t(
                    "h2hDetails.labels.knockoutRounds",
                    "Knockout rounds"
                  )}
                >
                  <option value={0} aria-selected={!this.state.koRounds}>
                    {t("h2hDetails.noKORounds", "None")}
                  </option>
                  {range(1, this.maximumKoRounds() + 1).map((num) => (
                    <option
                      value={num}
                      aria-selected={num === this.state.koRounds}
                      key={num}
                    >
                      {num}
                    </option>
                  ))}
                </SelectField>
              )}
              <Box mt={2}>
                <CheckboxField
                  checked={this.state.closed}
                  id="ismLeagueClosed"
                  label={t(
                    "h2hDetails.labels.closed",
                    "Close league to new entries"
                  )}
                  onChange={this.handleClosedChange}
                />
              </Box>
            </>
          )}
          <Box mt={2}>
            <Button type="submit" disabled={disabled}>
              {t("h2hDetails.labels.updateLeague", "Update league")}
            </Button>
          </Box>
        </form>
      </>
    );
  }
}

export { H2HDetails as H2HDetailsTest };

const mapStateToProps = (state: RootState): IPropsFromState => ({
  events: getFutureEvents(state),
  settings: getSettings(state) as ISettings,
});

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  updateLeague: (leagueId: number, data: IUpdateH2HLeagueData) =>
    dispatch(updateH2HLeague(leagueId, data)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(H2HDetails)
);

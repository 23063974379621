import { RouteComponentProps } from "@reach/router";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { getIsWebView } from "../../utils/webView";
import { logout } from "../../core/store/player/thunks";

const mapDispatch = {
  logout: () => logout(),
};

const connector = connect(null, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Logout extends React.Component<RouteComponentProps & PropsFromRedux> {
  public async componentDidMount() {
    if (getIsWebView()) {
      await this.props
        .logout()
        .then(() => window.location.replace("/?webview=1"))
        .catch(() => {});
    } else {
      await this.props
        .logout()
        .then(() => window.location.replace("/"))
        .catch(() => {});
    }
  }

  public render() {
    return <div />;
  }
}

export default connector(Logout);

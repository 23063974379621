import { useState } from "react";
import { FetchGet, FetchPost } from "../components/Utils";
import { getIsWebView } from "./webView";

// ===== Constants ========================================================== //

const KC_TOKEN = "kc_token";
const KC_REFRESH_TOKEN = "kc_refreshToken";
const LOGIN_ERROR = "kc_loginError";
const queryStringParams = getIsWebView() ? "?webview=1" : "";

// ===== Variables ========================================================== //

let keycloak: any;

// ===== Fantasy ============================================================ //

const fetchProfile = async (): Promise<void> => {
  const profile = await keycloak.loadUserProfile();

  FetchPost("sso/login/", {
    email: profile.email,
    emailVerified: profile.emailVerified,
    first_name: profile.firstName,
    last_name: profile.lastName,
    profile_id: profile.id,
    ismID: profile.attributes.ism_id,
    locale: profile.attributes.locale,
    region: profile.attributes.nationality,
  })
    .then(() => {
      window.location.replace(`/${queryStringParams}`);
    })
    .catch((error: any) => {
      window.localStorage.setItem(LOGIN_ERROR, error);
    });
};

const fetchMe = async (): Promise<{ player: Object | null }> => {
  return FetchGet("me/", {}).then((response) => {
    return response.json();
  });
};

// ===== Custom URLs ======================================================== //

// Get the account URL.
async function getAccountUrl(): Promise<string> {
  const url = await keycloak.createAccountUrl();
  return url + queryStringParams;
}

// Get the logout URL.
async function getLogoutUrl(): Promise<string> {
  const redirectUri = `${window.location.origin}/player/logout/${queryStringParams}`;
  return await keycloak.createLogoutUrl({ redirectUri });
}

// ===== Callbacks ========================================================== //

// To be executed when a user is known to be unauthenticated.
function onUnauthenticatedUser() {
  window.localStorage.removeItem(KC_TOKEN);
  window.localStorage.removeItem(KC_REFRESH_TOKEN);
}

// To be executed whenever the user's access token expires and needs refreshing.
function refreshAccessToken() {
  keycloak
    .updateToken()
    .then(() => {
      window.localStorage.setItem(KC_TOKEN, keycloak.token);
      window.localStorage.setItem(KC_REFRESH_TOKEN, keycloak.refreshToken);
    })
    .catch((error: any) => {
      console.warn("failed to update token", error);
      onUnauthenticatedUser();
    });
}

function createUrls(
  setUrls: (fn: (currentState: Object) => Object) => void,
  authenticated: boolean
) {
  // Get appropriate URLs based on the authentication state.
  if (authenticated) {
    Promise.all([getAccountUrl(), getLogoutUrl()]).then(
      ([accountUrl, logoutUrl]) => {
        setUrls((currentState: KeycloakUrls) => ({
          ...currentState,
          accountUrl,
          logoutUrl,
        }));
      }
    );
  } else {
    Promise.all([keycloak.createLoginUrl(), keycloak.createRegisterUrl()]).then(
      ([loginUrl, registerUrl]) => {
        setUrls((currentState: KeycloakUrls) => ({
          ...currentState,
          loginUrl,
          registerUrl,
        }));
      }
    );
  }
}

// ===== Hook =============================================================== //

export type KeycloakUrls = {
  loginUrl?: string;
  registerUrl?: string;
  accountUrl?: string;
  logoutUrl?: string;
};

export function useKeycloak(): {
  keycloak: any;
  urls: KeycloakUrls;
} {
  const [urls, setUrls] = useState({} as KeycloakUrls);
  /**
   * If any of the following are true, there's nothing to be done:
   *
   * - There's no Keycloak Adapter available.
   * - The Keycloak Adapter has already been initialised.
   */
  if (keycloak || !(window as any).Keycloak) {
    return { keycloak, urls };
  }

  /**
   * Create a new instance of the Keycloak Adapter. This is shared across
   * all usages of this hook.
   */
  keycloak = new (window as any).Keycloak({
    url: process.env.REACT_APP_SSO_URL,
    realm: process.env.REACT_APP_SSO_REALM,
    clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  });

  // Attach callbacks to be executed after initialisation.
  keycloak.onTokenExpired = refreshAccessToken;
  keycloak.onAuthError = () => {
    onUnauthenticatedUser();
    createUrls(setUrls, false);
  };
  keycloak.onAuthLogout = onUnauthenticatedUser;
  keycloak.onReady = async function (authenticated: boolean) {
    window.localStorage.removeItem(LOGIN_ERROR);

    if (authenticated) {
      window.localStorage.setItem(KC_TOKEN, keycloak.token);
      window.localStorage.setItem(KC_REFRESH_TOKEN, keycloak.refreshToken);
    } else {
      window.localStorage.removeItem(KC_TOKEN);
      window.localStorage.removeItem(KC_REFRESH_TOKEN);
    }
    fetchMe().then((body: { player: Object | null }) => {
      if (authenticated && !body.player) {
        fetchProfile();
        return;
      }
      if (!window.location.pathname.includes("player/logout")) {
        if (!authenticated && body.player) {
          onUnauthenticatedUser();
          window.location.replace(`/player/logout/${queryStringParams}`);
          return;
        }
      }
    });
    createUrls(setUrls, authenticated);
  };

  try {
    // Initialise the Keycloak Adapter.
    keycloak.init({
      token: window.localStorage.getItem(KC_TOKEN),
      refreshToken: window.localStorage.getItem(KC_REFRESH_TOKEN),
      checkLoginIframe: false,
    });
  } catch (error: any) {
    console.error("Failed to initialize adapter:", error);
  }

  return { keycloak, urls };
}

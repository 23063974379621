import React from "react";
import { connect } from "react-redux";
import { RootState } from "../core/store";
import { getElementsById } from "../core/store/elements/reducers";
import { IElementsById } from "../core/store/elements/types";
import Shirt from "./Shirt";
import { getSettings } from "../core/store/game/reducers";
import { ISettings } from "../core/store/game/types";
import { getTeamsById } from "../core/store/teams/reducers";
import { ITeamsById } from "../core/store/teams/types";

interface IPropsFromState {
  elementsById: IElementsById;
  settings: ISettings | null;
  teamsById: ITeamsById;
}
interface IOwnProps {
  elementId: number;
  hasShadow?: boolean;
  sizes: string;
}

type Props = IPropsFromState & IOwnProps;

export const getElementShirtPath = (
  elementType: number,
  teamCode: number,
  shirtMode: string
) => {
  const suffix = elementType === 1 ? `_${elementType}` : "";
  return `${process.env.PUBLIC_URL}/dist/img/shirts/${shirtMode}/shirt_${teamCode}${suffix}`;
};

const ElementShirt: React.FC<Props> = ({
  elementId,
  elementsById,
  hasShadow,
  settings,
  sizes,
  teamsById,
}) => {
  const element = elementsById[elementId];
  const team = teamsById[element.team];

  let shirtMode = "standard";
  if (settings && settings.ui_use_special_shirts) {
    if (settings.ui_special_shirt_exclusions.indexOf(element.code) === -1) {
      shirtMode = "special";
    }
  }

  return (
    <Shirt
      hasShadow={hasShadow}
      path={getElementShirtPath(element.element_type, team.code, shirtMode)}
      sizes={sizes}
      team={team}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  elementsById: getElementsById(state),
  teamsById: getTeamsById(state),
  settings: getSettings(state),
});

export default connect(mapStateToProps)(ElementShirt);

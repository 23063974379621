import { Link as RouterLink } from "@reach/router";
import { hideVisually, size } from "polished";
import React from "react";
import {
  WithTranslation,
  useTranslation,
  withTranslation,
} from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  getEntry,
  getPrivateClassicLeaguesForEntry,
  getPrivateH2HLeaguesForEntry,
  getPublicClassicLeaguesForEntry,
  getPublicH2HLeaguesForEntry,
  getSystemClassicLeaguesForEntry,
} from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry, ILeagueEntry } from "../../core/store/entries/types";
import { leaveLeague } from "../../core/store/leagues/thunks";
import { getPlayerData } from "../../core/store/player/reducers";
import { ILoggedInPlayer } from "../../core/store/player/types";
import { ReactComponent as BaseCog } from "../../img/icons/cog.svg";
import Button from "../Button";
import Dialog, { DialogButtonItem } from "../Dialog";
import DialogHeading from "../DialogHeading";
import DialogManager from "../DialogManager";
import { Main, Secondary, Wrapper } from "../Layout";
import Link from "../Link";
import LinkButton from "../LinkButton";
import Panel from "../Panel";
import SubHeading from "../SubHeading";
import Table from "../Table";
import { ControlArrowRight } from "../icons/Arrows";
import LeaguesNav from "./LeaguesNav";
import Movement from "./Movement";

const ActionBar = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    display: flex;
    flex-direction: row-reverse; // remove when we reinstate the Renew Leagues btn (#4850)
  }
`;

const ActionBarItem = styled.li`
  padding: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
  }
`;

const ActionList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    margin: 0 auto;
  }
`;

const MyLeaguesTable = styled(Table)`
  table-layout: fixed;

  th,
  td {
    padding-inline-start: 0.5rem;
    padding-inline-end: 0.5rem;

    @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
      padding-inline-start: 1rem;
      padding-inline-end: 1rem;
    }
  }
`;

const LeagueCol = styled.th`
  width: 40%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 36%;
  }
`;

const MovementCol = styled.th`
  width: 8%;
`;

const RankCol = styled.th`
  width: 24%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 18%;
  }
`;

const OptionsCol = styled.th`
  width: 10%;
  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    width: 20%;
  }
`;

const MenuCopy = styled.span`
  margin-inline-start: ${({ theme }) => theme.space[1]};

  @media (max-width: ${({ theme }) => theme.breakpoints[2]}) {
    ${hideVisually}
  }
`;

const Cog = styled(BaseCog)`
  ${size(14)};
`;

interface IPropsFromState {
  entry: IEntry | null;
  player: ILoggedInPlayer;
  privateClassicLeagues: ILeagueEntry[];
  privateH2HLeagues: ILeagueEntry[];
  publicClassicLeagues: ILeagueEntry[];
  publicH2HLeagues: ILeagueEntry[];
  systemClassicLeagues: ILeagueEntry[];
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
  leave: (leagueId: number) => void;
}

type Props = WithTranslation & IPropsFromState & IPropsFromDispatch;

class MyLeagues extends React.Component<Props> {
  public componentDidMount() {
    this.props.fetchEntrySummary(this.props.player.entry);
  }

  public render() {
    const {
      entry,
      leave,
      privateClassicLeagues,
      privateH2HLeagues,
      publicClassicLeagues,
      publicH2HLeagues,
      systemClassicLeagues,
      t,
    } = this.props;
    if (!entry) {
      return null;
    }
    return (
      <Wrapper>
        <Main>
          <Box pb={4}>
            <Panel hasKeyline={false}>
              <Panel.Header title={t("myLeagues.title", "My Leagues")} />
              <Panel.Body>
                <Box mx={2} mt={2}>
                  <SubHeading>{entry.name}</SubHeading>
                  <LeaguesNav />
                </Box>
                <ActionBar>
                  <ActionBarItem>
                    <Button
                      variant="primary"
                      width={1}
                      as={RouterLink}
                      to="create-join"
                      endIcon={<ControlArrowRight />}
                    >
                      {t(
                        "myLeagues.createJoinButton",
                        "Create and join new leagues & cups"
                      )}
                    </Button>
                  </ActionBarItem>

                  <ActionBarItem>
                    <Button
                      width={1}
                      variant="primary"
                      as={RouterLink}
                      to="renew"
                      endIcon={<ControlArrowRight />}
                    >
                      {t("myLeagues.renewButton", "Renew your leagues")}
                    </Button>
                  </ActionBarItem>
                </ActionBar>
              </Panel.Body>
            </Panel>
          </Box>

          <Box pb={4}>
            <LeagueTypeTable
              title={t(
                "myLeagues.tableTitles.privateClassic",
                "Private classic leagues"
              )}
              leagues={privateClassicLeagues}
              leave={leave}
            />
          </Box>
          <Box pb={4}>
            <LeagueTypeTable
              title={t(
                "myLeagues.tableTitles.privateH2H",
                "Private head-to-head leagues"
              )}
              leagues={privateH2HLeagues}
              leave={leave}
            />
          </Box>
          <Box pb={4}>
            <LeagueTypeTable
              title={t(
                "myLeagues.tableTitles.publicClassic",
                "Public classic leagues"
              )}
              leagues={publicClassicLeagues}
              leave={leave}
            />
          </Box>
          <Box pb={4}>
            <LeagueTypeTable
              title={t(
                "myLeagues.tableTitles.publicH2H",
                "Public head-to-head leagues"
              )}
              leagues={publicH2HLeagues}
              leave={leave}
            />
          </Box>
          <Box pb={4}>
            <LeagueTypeTable
              title={t("myLeagues.tableTitles.global", "Global leagues")}
              leagues={systemClassicLeagues}
              leave={leave}
            />
          </Box>
        </Main>
        <Secondary />
      </Wrapper>
    );
  }
}

export { MyLeagues as MyLeaguesTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as ILoggedInPlayer; // enforced by EntryRoute
  const entry = player.entry;
  return {
    entry: getEntry(state, entry),
    player,
    privateClassicLeagues: getPrivateClassicLeaguesForEntry(state, entry),
    privateH2HLeagues: getPrivateH2HLeaguesForEntry(state, entry),
    publicClassicLeagues: getPublicClassicLeaguesForEntry(state, entry),
    publicH2HLeagues: getPublicH2HLeaguesForEntry(state, entry),
    systemClassicLeagues: getSystemClassicLeaguesForEntry(state, entry),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  ownProps: WithTranslation
): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
  leave: (leagueId: number) => {
    if (
      window.confirm(
        ownProps.t(
          "myLeagues.confirmLeave",
          "Are you sure you want to leave this league?"
        )
      )
    ) {
      dispatch(leaveLeague(leagueId));
    }
  },
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(MyLeagues)
);

interface ILeagueTypeTableProps {
  leagues: ILeagueEntry[];
  leave: (leagueId: number) => void;
  title: React.ReactNode;
}

const LeagueTypeTable: React.FC<ILeagueTypeTableProps> = ({
  leagues,
  leave,
  title,
}) => {
  const { t } = useTranslation();
  return (
    <Panel hasKeyline={false}>
      <Panel.Header title={title} />
      <Panel.Body>
        <MyLeaguesTable>
          <thead>
            <tr>
              <LeagueCol>{t("myLeagues.th.league", "League")}</LeagueCol>
              <MovementCol>&nbsp;</MovementCol>
              <RankCol>{t("myLeagues.th.currentRank", "Current Rank")}</RankCol>
              <RankCol>{t("myLeagues.th.lastRank", "Last Rank")}</RankCol>
              <OptionsCol>&nbsp;</OptionsCol>
            </tr>
          </thead>
          <tbody>
            {leagues.map((l) => (
              <tr key={l.id}>
                <td>
                  <Link to={`/leagues/${l.id}/standings/${l.scoring}`}>
                    {l.name}
                  </Link>
                </td>
                <td>
                  <Movement lastRank={l.entry_last_rank} rank={l.entry_rank} />
                </td>
                <td>{l.entry_rank ? l.entry_rank.toLocaleString() : "-"}</td>
                <td>
                  {l.entry_last_rank ? l.entry_last_rank.toLocaleString() : "-"}
                </td>
                <td>
                  <DialogManager
                    render={(showDialog, handleShow, handleHide) => (
                      <>
                        <LinkButton onClick={handleShow}>
                          <Cog />
                          <MenuCopy>
                            {t("myLeagues.optionsLink", "Options")}
                          </MenuCopy>
                        </LinkButton>
                        {showDialog && (
                          <LeagueMenuDialog
                            leagueEntry={l}
                            leave={leave}
                            handleHide={handleHide}
                          />
                        )}
                      </>
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </MyLeaguesTable>
      </Panel.Body>
    </Panel>
  );
};

interface ILeagueMenuDialogProps {
  handleHide: () => void;
  leagueEntry: ILeagueEntry;
  leave: (leagueId: number) => void;
}

const LeagueMenuDialog: React.FC<ILeagueMenuDialogProps> = ({
  handleHide,
  leagueEntry,
  leave,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog closeDialog={handleHide}>
      <Dialog.Header closeDialog={handleHide}>
        <DialogHeading>{leagueEntry.name}</DialogHeading>
      </Dialog.Header>
      <Dialog.Body isPadded={true}>
        <ActionList>
          <DialogButtonItem>
            <Button
              width={1}
              as={RouterLink}
              endIcon={<ControlArrowRight />}
              to={`/leagues/${leagueEntry.id}/standings/${leagueEntry.scoring}`}
            >
              {t("myLeagues.menuDialog.standings", "Standings")}
            </Button>
          </DialogButtonItem>
          {leagueEntry.entry_can_leave && (
            <DialogButtonItem>
              <Button
                width={1}
                endIcon={<ControlArrowRight />}
                onClick={() => {
                  leave(leagueEntry.id);
                  handleHide();
                }}
              >
                {t("myLeagues.menuDialog.leave", "Leave league")}
              </Button>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_admin && (
            <DialogButtonItem>
              <Button
                width={1}
                as={RouterLink}
                endIcon={<ControlArrowRight />}
                to={`/leagues/${leagueEntry.id}/admin/${leagueEntry.scoring}`}
              >
                {t("myLeagues.menuDialog.admin", "Administer")}
              </Button>
            </DialogButtonItem>
          )}
          {leagueEntry.entry_can_invite && (
            <DialogButtonItem>
              <Button
                width={1}
                as={RouterLink}
                endIcon={<ControlArrowRight />}
                to={`/leagues/${leagueEntry.id}/invite`}
              >
                {t("myLeagues.menuDialog.invite", "Invite friends")}
              </Button>
            </DialogButtonItem>
          )}
        </ActionList>
      </Dialog.Body>
    </Dialog>
  );
};

import { RouteComponentProps } from "@reach/router";
import values from "lodash/values";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "rebass";
import { ThunkDispatch } from "../../core/store";
import { getPasswordSetError } from "../../core/store/player/reducers";
import { passwordSet } from "../../core/store/player/thunks";
import Alert from "../Alert";
import Button from "../Button";
import ButtonLink from "../ButtonLink";
import Copy from "../Copy";
import { FieldFeedback, InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";

interface IErrors {
  newPassword1: string;
  newPassword2: string;
}

const PasswordSet: React.FC<
  RouteComponentProps<{
    uidb64: string;
    token: string;
  }>
> = ({ uidb64, token }) => {
  const passwordSetErrors = useSelector(getPasswordSetError);
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const { t } = useTranslation();
  const [initialToken] = useState(token);
  const [newPassword1, setNewPassword1] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [submitted, setSumbitted] = useState(false);
  const [errors, setErrors] = useState<IErrors>({
    newPassword1: "",
    newPassword2: "",
  });

  if (submitted) {
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>
              {t("passwordReset.completeTitle", "Password reset sent")}
            </Title>
          </Box>
          <Copy>
            <p>
              {t(
                "passwordReset.completeText",
                "Your password has been set.  You may go ahead and log in now."
              )}
            </p>
            <Box mt={2} mb={8}>
              <ButtonLink to="/">
                {t("passwordSet.completeLogin", "Log in")}
              </ButtonLink>
            </Box>
          </Copy>
        </Main>
      </Wrapper>
    );
  }

  if (!initialToken || !uidb64) {
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>
              {t("passwordReset.errorMissingTitle", "Error validating link")}
            </Title>
          </Box>
          <Copy>
            <p>
              {t(
                "passwordReset.errorMissingText",
                "There was a problem with the password reset URL. Please try clicking the link again or cut and paste it from your email application."
              )}
            </p>
          </Copy>
        </Main>
      </Wrapper>
    );
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await reduxDispatch(
        passwordSet({
          new_password1: newPassword1,
          new_password2: newPassword2,
          uidb64: uidb64 as string,
          token: initialToken as string,
        })
      );
    } catch (e) {
      return;
    }
    setSumbitted(true);
  }

  // Errors which can be picked up by the UI
  const validate = () => {
    const errors: IErrors = {
      newPassword1: "",
      newPassword2: "",
    };
    if (newPassword1.length <= 6) {
      errors.newPassword1 = t(
        "passwordSet.hintPassword1",
        "Minimum 6 characters"
      );
    }
    if (newPassword2.length && newPassword1 !== newPassword2) {
      errors.newPassword2 = t(
        "passwordSet.errorMatch",
        "Please make sure the passwords match."
      );
    }
    setErrors(errors);
  };

  // Obvious errors should be picked up by the UI but just in case we should
  //  show them. Better en errors than none ...
  const serverErrors =
    passwordSetErrors && passwordSetErrors.badRequest
      ? values(passwordSetErrors.badRequest).map((e) => e[0].message)
      : [];

  const disabled = newPassword1.length < 6 || newPassword1 !== newPassword2;

  return (
    <Wrapper>
      <Main>
        <Box mx={2}>
          <Title>{t("passwordSet.title", "Enter new password")}</Title>
        </Box>
        {serverErrors.length > 0 && (
          <Alert type="error">
            {values(serverErrors).map((r) => (
              <p>{r}</p>
            ))}
          </Alert>
        )}
        <Copy>
          <p>
            {t(
              "passwordSet.explain",
              "Please enter your new password twice so we can verify you typed it in correctly."
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <Box mt={2}>
              <InputField
                required
                id="ismNewPassword1"
                type="password"
                label={t("passwordSet.labelPassword1", "New password")}
                value={newPassword1}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setNewPassword1(e.currentTarget.value)
                }
                onBlur={validate}
                hint={t("passwordSet.hintPassword1", "Minimum 6 characters")}
              />
            </Box>
            {errors.newPassword1 && (
              <FieldFeedback>{errors.newPassword1}</FieldFeedback>
            )}
            <Box mt={2}>
              <InputField
                required
                id="ismNewPassword2"
                type="password"
                label={t("passwordSet.labelPasswordConf", "Confirm password")}
                value={newPassword2}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setNewPassword2(e.currentTarget.value)
                }
                onBlur={validate}
              />
            </Box>
            {errors.newPassword2 && (
              <FieldFeedback>{errors.newPassword2}</FieldFeedback>
            )}
            <Box mt={2} mb={8}>
              <Button type="submit" disabled={disabled}>
                {t("passwordSet.button", "Change my password")}
              </Button>
            </Box>
          </form>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default PasswordSet;

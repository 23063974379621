import React from "react";
import { Helmet } from "react-helmet";

interface IProps {
  description: string;
  title: string;
}

const HelmetHead: React.FC<IProps> = ({ description, title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default HelmetHead;

import React from "react";
import { getPlayerData } from "../../core/store/player/reducers";
import { useSelector } from "react-redux";
import { Redirect, RouteComponentProps } from "@reach/router";

const LoggedIn: React.FC<RouteComponentProps> = () => {
  const player = useSelector(getPlayerData);
  if (!player) {
    return <Redirect to="/" noThrow />;
  } else if (!player.entry) {
    return <Redirect to="/squad-selection" noThrow />;
  }
  return <Redirect to="/my-team" noThrow />;
};

export default LoggedIn;

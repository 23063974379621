import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "../bootstrap/actions";
import { IState } from "./types";

// State Reducer
const reducer = (state: IState = [], action: RootAction) => {
  switch (action.type) {
    case getType(actions.addElementStats):
      return action.payload;
    default:
      return state;
  }
};
export default reducer;

// State Selectors
export const getElementStats = (state: RootState) => state.elementStats;

export const getElementStatsByIdentifier = createSelector(
  getElementStats,
  (stats) => stats.reduce((memo, stat) => ({ ...memo, [stat.name]: stat }), {})
);

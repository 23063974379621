import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import * as actions from "./actions";
import {
  filterElements,
  getElementControls,
  getElements,
  getElementTypeFilterOptions,
  maxCostFromElements,
} from "./reducers";
import { IElementControls } from "./types";
import { getWatched } from "../player/reducers";

export const updateElementControlsAndMaxCost =
  (controls: IElementControls): ThunkResult<void> =>
  (dispatch, getState) => {
    const elements = getElements(getState());
    dispatch(
      actions.updateElementControls({
        ...controls,
        maxCost: maxCostFromElements(
          filterElements(elements, controls.filter, getWatched(getState()))
        ),
      })
    );
  };

export const updateElementTypeControl =
  (elementTypeId: number): ThunkResult<void> =>
  (dispatch, getState) => {
    dispatch(
      updateElementControlsAndMaxCost({
        ...getElementControls(getState()),
        filter: getElementTypeFilterOptions(getState())[elementTypeId - 1]
          .value,
      })
    );
  };

export const getElementSummary =
  (elementId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `element-summary/${elementId}/`,
        dispatch,
        actions.fetchElementSummary,
        { id: elementId }
      );
    } catch (e) {
      return;
    }
  };

export const showElementSummary =
  (elementId: number): ThunkResult<void> =>
  async (dispatch) => {
    dispatch(actions.elementDialogShow(elementId));
    dispatch(getElementSummary(elementId));
  };

export const fetchEventLive =
  (eventId: number): ThunkResult<void> =>
  async (dispatch) => {
    try {
      await apiGet(
        `event/${eventId}/live/`,
        dispatch,
        actions.fetchElementEventData,
        {
          id: eventId,
        }
      );
    } catch (e) {
      return;
    }
  };

import { createAsyncAction } from "typesafe-actions";
import { IError } from "..";
import { IFixture, IFixtureAllStats } from "./types";

// Action Creators
export const addFixturesForEvent = createAsyncAction(
  "ADD_FIXTURES_EVENT_START",
  "ADD_FIXTURES_EVENT_SUCCESS",
  "ADD_FIXTURES_EVENT_FAILURE"
)<void, { event: number; data: IFixture[] }, IError>();

export const addFixtures = createAsyncAction(
  "ADD_FIXTURES_START",
  "ADD_FIXTURES_SUCCESS",
  "ADD_FIXTURES_FAILURE"
)<void, IFixture[], IError>();

export const addAllStatsForFixture = createAsyncAction(
  "ADD_ALL_STATS_FOR_FIXTURE_START",
  "ADD_ALL_STATS_FOR_FIXTURE_SUCCESS",
  "ADD_ALL_STATS_FOR_FIXTURE_FAILURE"
)<void, { fixture: number; data: IFixtureAllStats }, IError>();

import { useEffect } from "react";

type HookProps = {
  event: string;
  handler: (e: any) => void;
};

// register event listener on mount on document
// and fires handler on captured click
const useDocumentEventHandler = ({ event, handler }: HookProps) => {
  useEffect(() => {
    const eventHandler = (e: Event) => {
      handler(e);
    };

    document.addEventListener(event, eventHandler);
    return () => {
      document.removeEventListener(event, eventHandler);
    };
  }, [event, handler]);
};

export default useDocumentEventHandler;

import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState } from "../../core/store";
import { getElementStats } from "../../core/store/element-stats/reducers";
import { IElementStat } from "../../core/store/element-stats/types";
import { getElementTypesById } from "../../core/store/element-types/reducers";
import { IElementTypesById } from "../../core/store/element-types/types";
import { getElementsById } from "../../core/store/elements/reducers";
import {
  IElement,
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import ElementDialogButton from "../ElementDialogButton";
import ElementInTable from "../ElementInTable";
import Table from "../Table";

const ScrollTable = styled.div`
  overflow-x: auto;
  width: 100%;
`;

const StatsTable = styled(Table)`
  white-space: nowrap;
`;

interface IDreamTeamRowProps {
  data: Record<string, any>;
  element: IElement;
  elementTypesById: IElementTypesById;
  renderElementMenu: (index: number) => void;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

const DreamTeamRow: React.FC<IDreamTeamRowProps> = ({
  data,
  element,
  renderElementMenu,
  stats,
  teamsById,
}) => (
  <tr>
    <td>
      <ElementDialogButton elementId={element.id} variant="list" />
    </td>
    <td>
      <ElementInTable
        renderElementMenu={() => renderElementMenu(element.id)}
        element={element}
        team={teamsById[element.team]}
      />
    </td>
    <td>{data.total_points}</td>
    {stats.map((s) => (
      <td key={s.name}>{data[s.name]}</td>
    ))}
  </tr>
);

interface IOwnProps {
  dataById: IElementsEventDataById;
  elements: number[];
  renderElementMenu: (index: number) => void;
}

interface IPropsFromState {
  elementsById: IElementsById;
  elementTypesById: IElementTypesById;
  stats: ReadonlyArray<IElementStat>;
  teamsById: ITeamsById;
}

type Props = IOwnProps & IPropsFromState;

const DreamTeamTable: React.FC<Props> = ({
  dataById,
  elements,
  elementsById,
  stats,
  ...rest
}) => {
  return (
    <ScrollTable>
      <StatsTable>
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Player</th>
            <th>TP</th>
            {stats.map((s) => (
              <th key={s.name}>
                <abbr title={s.label}>
                  {s.label
                    .split(" ")
                    .map((w) => w[0])
                    .join("")
                    .toUpperCase()}
                </abbr>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {elements.map((e) => (
            <DreamTeamRow
              key={e}
              element={elementsById[e]}
              stats={stats}
              data={dataById ? dataById[e].stats : elementsById[e]}
              {...rest}
            />
          ))}
        </tbody>
      </StatsTable>
    </ScrollTable>
  );
};

export { DreamTeamTable as DreamTeamTableTest };

const mapStateToProps = (state: RootState): IPropsFromState => {
  return {
    elementsById: getElementsById(state),
    elementTypesById: getElementTypesById(state),
    stats: getElementStats(state),
    teamsById: getTeamsById(state),
  };
};

export default connect(mapStateToProps)(DreamTeamTable);

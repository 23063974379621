import { Link } from "@reach/router";
import React from "react";
import styled, { css } from "styled-components/macro";

const NavList = styled.ul`
  display: flex;
  margin: ${({ theme }) => theme.space[2]};
  padding-inline-start: 0;
  padding: 0.2rem;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.white};
  list-style-type: none;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-inline-end: 20%;
    margin-inline-start: 20%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[5]}) {
    margin-inline-end: 30%;
    margin-inline-start: 30%;
  }
`;

const NavItem = styled.li`
  flex: 1;
`;

// TODO - This stops isCurrent being passed to Link but doesn't have
// any type checking
const NavLink = styled(({ isCurrent, ...rest }) => <Link {...rest} />)`
  display: block;
  padding: ${({ theme }) => theme.space[2]};
  color: ${({ theme }) => theme.colors.primary};
  font-size: 1.5rem;
  text-decoration: none;
  text-align: center;

  ${(props) =>
    props.isCurrent &&
    css`
      border-radius: ${({ theme }) => theme.radii[0]};
      background-color: ${({ theme }) => theme.colors.blue};
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
    `}
`;

interface ILinks {
  href: string;
  text: string;
}

interface ISubNav {
  links: ILinks[];
}

const SubNav: React.FC<ISubNav> = ({ links }) => (
  <nav>
    <NavList>
      {links.map((l) => (
        <NavItem key={l.href}>
          <NavLink to={l.href} isCurrent={window.location.pathname === l.href}>
            {l.text}
          </NavLink>
        </NavItem>
      ))}
    </NavList>
  </nav>
);

export default SubNav;

import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "./actions";
import { IState } from "./types";

export const timeNow = () => Math.round(new Date().getTime() / 1000);

// State Reducer
export default combineReducers<IState, RootAction>({
  loading: (state = false, action) => {
    switch (action.type) {
      case getType(actions.bootstrap.request):
        return true;
      case getType(actions.bootstrap.failure):
      case getType(actions.bootstrap.success):
        return false;
      default:
        return state;
    }
  },

  times: (state = { browser: null, server: null }, action) => {
    switch (action.type) {
      case getType(actions.bootstrap.success):
        return {
          browser: timeNow(),
          server: null,
        };
      default:
        return state;
    }
  },
});

// State Selectors
export const getBootstrapped = (state: RootState) =>
  Boolean(state.bootstrap.times.browser);

export const getBootstrapTime = (state: RootState) =>
  state.bootstrap.times.browser;

import React from "react";
import { IFixture } from "../../core/store/fixtures/types";
import { ITeamsById } from "../../core/store/teams/types";
import {
  IElementsEventDataById,
  IElementsById,
} from "../../core/store/elements/types";
import FixtureForElement from "../FixtureForElement";
import { IPickLight } from "../../core/store/entries/types";

const valueForPlayerElement =
  (initProps: {
    elementsById: IElementsById;
    elementsDataById: IElementsEventDataById | null;
    fixturesById: Record<string, IFixture> | null;
    teamsById: ITeamsById;
  }) =>
  (pick: IPickLight) => {
    if (!initProps.elementsDataById || !initProps.fixturesById) {
      return null;
    }
    const element = initProps.elementsById[pick.element];
    const elementData = initProps.elementsDataById[element.id];
    const fragments: React.ReactNode[] = [];
    let started = 0;
    elementData.explain.forEach((e) => {
      const fixture = initProps.fixturesById![e.fixture];
      if (!fixture.started) {
        fragments.push(
          <FixtureForElement
            element={element}
            fixture={fixture}
            teamsById={initProps.teamsById}
          />
        );
      } else {
        started++;
      }
    });
    if (started) {
      fragments.unshift(
        elementData.stats.total_points * Math.max(pick.multiplier, 1)
      );
    }
    return fragments.length ? (
      fragments.reduce((result, item) => (
        <>
          {result}
          {", "}
          {item}
        </>
      ))
    ) : (
      <span>-</span>
    );
  };

export default valueForPlayerElement;

import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "./actions";
import { IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byURL: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.fetchURL.success):
        return {
          ...state,
          [action.payload.url]: {
            ...action.payload.data,
            last_fetched: Math.round(new Date().getTime() / 1000),
          },
        };
      default:
        return state;
    }
  },
});

// State Selectors

// We leave it up to the caller to parse the content based on the conent_type.
// If used by a lot of games we could create helpers
export const getURL = (state: RootState, url: string) =>
  state.urls.byURL[url] || null;

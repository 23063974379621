import { Link } from "@reach/router";
import { ellipsis } from "polished";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import { getElementsById } from "../../core/store/elements/reducers";
import { IElementsById } from "../../core/store/elements/types";
import { getEntry } from "../../core/store/entries/reducers";
import { fetchEntrySummary } from "../../core/store/entries/thunks";
import { IEntry } from "../../core/store/entries/types";
import { getCurrentEvent } from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getPlayerData } from "../../core/store/player/reducers";
import { IPlayer } from "../../core/store/player/types";
import { ControlArrowRight } from "../icons/Arrows";

const StyledEventInfo = styled.div`
  margin-bottom: 2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[2]}) {
    margin-top: 4rem;
  }
`;

const InfoList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0 10px;
`;

const InfoItem = styled.li`
  flex: 1 0 33.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 106px;
  padding: 15px 10px 10px 10px;
  border: ${({ theme }) => theme.borders[4]};
  border-radius: ${({ theme }) => theme.radii[2]};
  list-style-type: none;
  background-color: ${({ theme }) => theme.colors.white};
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  @media (min-width: ${({ theme }) => theme.breakpoints[3]}) {
    flex: 1;
    height: auto;
  }
`;

const ItemHeading = styled.h4`
  margin: 0;
  padding: 0.5rem;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.primary};
`;

const ItemValueStat = styled.div`
  ${ellipsis()};
  font-size: 1.8rem;
  padding-bottom: 1.5rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const ItemValueScore = styled.div`
  ${ellipsis()};
  font-size: 4rem;
  color: ${({ theme }) => theme.colors.blue};
`;

const ArrowLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.blue};

  :hover {
    text-decoration: underline;
  }
`;

interface IPropsFromState {
  elementsById: IElementsById;
  entry: IEntry | null;
  now: IEvent | null;
  player: IPlayer;
}

interface IPropsFromDispatch {
  fetchEntrySummary: (entryId: number) => void;
}

type Props = IPropsFromState & IPropsFromDispatch;

const EventInfo: React.FC<Props> = ({ elementsById, entry, now, player }) => {
  const { t } = useTranslation();

  if (!now || !entry) {
    return null;
  }
  const matches = now.chip_plays.filter((cp) => cp.chip_name === "wildcard");
  const wildcards = matches.length ? matches[0].num_played : "-";
  return (
    <StyledEventInfo>
      <InfoList>
        {entry.started_event <= now.id && (
          <InfoItem>
            <ItemHeading>{t("eventInfo.yourScore", "Your score")}</ItemHeading>
            <ItemValueScore>
              <ArrowLink to={`/entry/${player.entry}/event/${now.id}`}>
                {entry.summary_event_points}
                <ControlArrowRight />
              </ArrowLink>
            </ItemValueScore>
          </InfoItem>
        )}
        <InfoItem>
          <ItemHeading>
            {t("eventInfo.averageScore", "Average score")}
          </ItemHeading>
          <ItemValueScore>{now.average_entry_score || "-"}</ItemValueScore>
        </InfoItem>
        <InfoItem>
          <ItemHeading>
            {t("eventInfo.highestScore", "Highest score")}
          </ItemHeading>
          <ItemValueScore>
            {now.highest_scoring_entry ? (
              <ArrowLink
                to={`/entry/${now.highest_scoring_entry}/event/${now.id}/`}
              >
                {now.highest_score}
                <ControlArrowRight />
              </ArrowLink>
            ) : (
              "-"
            )}
          </ItemValueScore>
        </InfoItem>
        {now.id > 1 && (
          <InfoItem>
            <ItemHeading>
              {t("eventInfo.transfersMade", "Transfers Made")}
            </ItemHeading>
            <ItemValueStat>
              {now.transfers_made ? now.transfers_made.toLocaleString() : "-"}
            </ItemValueStat>
          </InfoItem>
        )}
        <InfoItem>
          <ItemHeading>
            {t("eventInfo.wildcardsPlayed", "Wildcards Played")}
          </ItemHeading>
          <ItemValueStat>
            {wildcards === null ? "-" : wildcards.toLocaleString()}
          </ItemValueStat>
        </InfoItem>
        <InfoItem>
          <ItemHeading>
            {t("eventInfo.mostCaptained", "Most Captained")}
          </ItemHeading>
          <ItemValueStat>
            {now.most_captained
              ? elementsById[now.most_captained].web_name
              : "-"}
          </ItemValueStat>
        </InfoItem>
      </InfoList>
    </StyledEventInfo>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => {
  const player = getPlayerData(state) as IPlayer;
  const entryId = player.entry || 0;
  const now = getCurrentEvent(state) as IEvent;
  return {
    elementsById: getElementsById(state),
    entry: getEntry(state, entryId),
    now,
    player,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEntrySummary: (entryId) => dispatch(fetchEntrySummary(entryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventInfo);

import styled from "styled-components/macro";

const SaveBar = styled.div`
  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[4]};
  padding-inline-end: ${({ theme }) => theme.space[2]};
  padding-inline-start: ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    margin-inline-end: 30%;
    margin-inline-start: 30%;
  }
`;

export default SaveBar;

import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Box } from "rebass/styled-components";
import { getIsWebView } from "../utils/webView";
import { KeyCloakContext } from "./App";
import Button from "./Button";
import { ControlArrowRight } from "./icons/Arrows";

export const KeycloakEntryLinks = () => {
  const { t } = useTranslation();
  const {
    urls: { accountUrl, logoutUrl },
  } = useContext(KeyCloakContext);

  if (getIsWebView()) {
    return null;
  }

  return (
    <>
      <Box p={1}>
        <Button
          width={1}
          href={accountUrl}
          variant="primary"
          endIcon={<ControlArrowRight color={"white"} />}
        >
          {t("manageAccount", "Manage account")}
        </Button>
      </Box>
      <Box p={1}>
        <Button
          width={1}
          href={logoutUrl}
          variant="primary"
          endIcon={<ControlArrowRight color={"white"} />}
        >
          {t("signOut", "Sign out")}
        </Button>
      </Box>
    </>
  );
};

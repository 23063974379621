import { RouteComponentProps } from "@reach/router";
import * as React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Box } from "rebass/styled-components";
import styled from "styled-components/macro";
import { RootState, ThunkDispatch } from "../../core/store";
import {
  dreamTeamAsPickLight,
  getEventDreamTeam,
  getEventDreamTeamFormation,
  getOverallDreamTeam,
  getOverallDreamTeamFormation,
} from "../../core/store/dream-teams/reducers";
import {
  fetchEventDreamTeam,
  fetchOverallDreamTeam,
} from "../../core/store/dream-teams/thunks";
import { IDreamTeamData } from "../../core/store/dream-teams/types";
import {
  getElementsById,
  getElementsEventDataById,
} from "../../core/store/elements/reducers";
import {
  fetchEventLive,
  showElementSummary,
} from "../../core/store/elements/thunks";
import {
  IElementsById,
  IElementsEventDataById,
} from "../../core/store/elements/types";
import { IPickLight } from "../../core/store/entries/types";
import {
  getCurrentEvent,
  getEventsById,
} from "../../core/store/events/reducers";
import { IEvent } from "../../core/store/events/types";
import { getFixturesForEventById } from "../../core/store/fixtures/reducers";
import { IFixture } from "../../core/store/fixtures/types";
import { getTeamsById } from "../../core/store/teams/reducers";
import { ITeamsById } from "../../core/store/teams/types";
import BoldLink from "../BoldLink";
import BoldLinkButton from "../BoldLinkButton";
import ButtonLink from "../ButtonLink";
import ElementShirt from "../ElementShirt";
import Fixtures from "../Fixtures";
import { ControlArrowLeft, ControlArrowRight } from "../icons/Arrows";
import { Main, Secondary, Wrapper } from "../Layout";
import Media from "../Media";
import {
  EventPager,
  PagerButton,
  PagerButtonNext,
  PagerHeading,
} from "../Pager";
import { NotFound } from "../Routes";
import SubHeading from "../SubHeading";
import TabPanel from "../tabs/TabPanel";
import Tabs from "../tabs/Tabs";
import Title from "../Title";
import DreamTeamTable from "./DreamTeamTable";
import ElementExplainDialog from "./ElementExplainDialog";
import PitchFormation from "./PitchFormation";
import valueForPlayerElement from "./valueForPlayedElement";

const DTScoreboardWrapper = styled.div`
  margin: ${({ theme }) => theme.space[4]} 0;
  padding: ${({ theme }) => theme.space[2]};
  border: ${({ theme }) => theme.borders[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
`;

const DTScoreboard = styled.div`
  display: flex;
`;

const DTScoreboardPanel = styled.div`
  flex: 1;
  margin: ${({ theme }) => theme.space[2]};
  padding: ${({ theme }) => theme.space[2]};
  border: ${({ theme }) => theme.borders[3]};
  border-radius: ${({ theme }) => theme.radii[2]};
  background-color: white;
  text-align: center;
`;

const DTScoreboardHeading = styled.h3`
  margin: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSizes[4]};
`;

const DTScoreboardValue = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 4rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    padding-top: 0.6rem;
    padding-bottom: 1rem;
  }
`;

const TopPlayer = styled.div`
  display: inline-block;
`;

const TopPlayerData = styled.div`
  text-align: start;
`;

const PitchWrapper = styled(Box)`
  border: ${({ theme }) => theme.borders[2]};
  border-radius: ${({ theme }) => theme.radii[2]};
`;

type OwnProps = RouteComponentProps<{ eventId?: string }>;

interface IPropsFromState {
  data: IDreamTeamData | null;
  elementsById: IElementsById;
  elementsDataById: IElementsEventDataById;
  fixturesById: Record<string, IFixture> | null;
  event: IEvent | undefined;
  formation: string;
  now: IEvent | null;
  teamsById: ITeamsById;
}

interface IPropsFromDispatch {
  fetchEventLive: (entryId: number) => void;
  fetchEventDreamTeam: (eventId: number) => void;
  fetchOverallDreamTeam: () => void;
  showElementDialog: (elementId: number) => void;
}

type Props = OwnProps & IPropsFromState & IPropsFromDispatch & WithTranslation;

interface IState {
  elementForMenu: number;
}

class DreamTeam extends React.Component<Props, IState> {
  public state: IState = {
    elementForMenu: 0,
  };

  public handleShowMenuForElement = (element: number) => {
    this.setState({ elementForMenu: element });
  };

  public handleHideMenuForElement = () => {
    this.setState({ elementForMenu: 0 });
  };

  public fetchData() {
    if (this.props.event) {
      this.props.fetchEventDreamTeam(this.props.event.id);
      this.props.fetchEventLive(this.props.event.id);
    } else {
      this.props.fetchOverallDreamTeam();
    }
  }

  public componentDidMount() {
    this.fetchData();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.event !== this.props.event) {
      this.fetchData();
    }
  }

  public render() {
    const {
      data,
      elementsById,
      elementsDataById,
      event,
      fixturesById,
      formation,
      now,
      showElementDialog,
      t,
      teamsById,
    } = this.props;
    if (!now) {
      return <NotFound />;
    }
    if (!data) {
      return null;
    }
    const picks = dreamTeamAsPickLight(data.team);
    const topPlayer = elementsById[data.top_player.id];

    // For the overall team we just show the standard element dialog as there
    // is no explain
    if (this.state.elementForMenu && !event) {
      showElementDialog(this.state.elementForMenu);
    }

    // Create a new function on each render as data could have changed and
    // need to ensure a render of connected subcomponents
    const renderPickValue = event
      ? valueForPlayerElement({
          elementsById,
          elementsDataById,
          fixturesById,
          teamsById,
        })
      : (pick: IPickLight) => {
          const elementData = data.team.filter(
            (e) => e.element === pick.element
          );
          return elementData.length ? elementData[0].points : null;
        };
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{t("dreamTeam.dt", "Dream Team")}</Title>
          </Box>
          {/* ROUND PAGER */}
          <DTScoreboardWrapper>
            {event ? (
              <Box my={2}>
                <PagerHeading>{event.name}</PagerHeading>
                {(event.id > 1 || event.id < now.id) && (
                  <EventPager>
                    {event.id > 1 && (
                      <PagerButton>
                        <ButtonLink
                          to={`/dream-team/${event.id - 1}`}
                          variant="secondary"
                        >
                          <ControlArrowLeft />
                          {t("dreamTeam.prev", "Previous")}
                        </ButtonLink>
                      </PagerButton>
                    )}
                    {event.id < now.id && (
                      <PagerButtonNext>
                        <ButtonLink
                          to={`/dream-team/${event.id + 1}`}
                          variant="secondary"
                        >
                          {t("dreamTeam.next", "Next")}
                          <ControlArrowRight />
                        </ButtonLink>
                      </PagerButtonNext>
                    )}
                  </EventPager>
                )}
              </Box>
            ) : (
              <Box mx={2}>
                <SubHeading>{t("dreamTeam.overall", "Overall")}</SubHeading>
              </Box>
            )}
            {/* SCOREBOARD */}

            <Box>
              <DTScoreboard>
                <DTScoreboardPanel>
                  <DTScoreboardHeading>
                    {t("dreamTeam.topPlayer", "Top Player")}
                  </DTScoreboardHeading>
                  <Box mb={1}>
                    <BoldLinkButton
                      onClick={() => showElementDialog(topPlayer.id)}
                    >
                      {topPlayer.web_name}
                      <ControlArrowRight />
                    </BoldLinkButton>
                  </Box>
                  <Box mb={1}>
                    <TopPlayer>
                      <Media
                        img={
                          <ElementShirt
                            elementId={topPlayer.id}
                            sizes="(min-width: 1024px) 55px, (min-width: 610px) 44px, 33px"
                            hasShadow={true}
                          />
                        }
                        centred={true}
                      >
                        <TopPlayerData>
                          <div>{teamsById[topPlayer.team].short_name}</div>
                          <div>
                            {data.top_player.points}{" "}
                            {t("dreamTeam.points", "Points")}
                          </div>
                        </TopPlayerData>
                      </Media>
                    </TopPlayer>
                  </Box>
                </DTScoreboardPanel>
                <DTScoreboardPanel>
                  <DTScoreboardHeading>
                    {t("dreamTeam.totalPoints", "Total Points")}
                  </DTScoreboardHeading>
                  <DTScoreboardValue>
                    {data.team.reduce((total, e) => total + e.points, 0)}
                  </DTScoreboardValue>
                  {event ? (
                    <BoldLink to="/dream-team/">
                      {t("dreamTeam.overall", "Overall")}
                    </BoldLink>
                  ) : (
                    <BoldLink to={`/dream-team/${now.id}`}>
                      {t("dreamTeam.round", "Round")}
                    </BoldLink>
                  )}
                </DTScoreboardPanel>
              </DTScoreboard>
            </Box>
          </DTScoreboardWrapper>
          {/* TEAM */}
          <PitchWrapper bg="greyLight3" pt={4}>
            <Box mx={2} mb={2}>
              <Tabs centered>
                <TabPanel
                  label={t("dreamTeam.pitchView", "Pitch View")}
                  link="pitch"
                >
                  <Box mx={-2} pt={4}>
                    <PitchFormation
                      chipName={""}
                      formation={formation}
                      picks={picks}
                      renderDreamTeam={() => null}
                      renderElementMenu={this.handleShowMenuForElement}
                      renderPickValue={renderPickValue}
                    />
                  </Box>
                </TabPanel>
                <TabPanel
                  label={t("dreamTeam.listView", "List View")}
                  link="list"
                >
                  <Box my={4} bg="white">
                    <DreamTeamTable
                      elements={data.team.map((e) => e.element)}
                      renderElementMenu={this.handleShowMenuForElement}
                      dataById={elementsDataById}
                    />
                  </Box>
                </TabPanel>
              </Tabs>
            </Box>
          </PitchWrapper>
          {event && this.state.elementForMenu ? (
            <ElementExplainDialog
              elementId={this.state.elementForMenu}
              eventId={event.id}
              closeDialog={this.handleHideMenuForElement}
            />
          ) : null}
          {event && <Fixtures eventId={event.id} />}
        </Main>
        <Secondary>
          <span>&nbsp;</span>
        </Secondary>
      </Wrapper>
    );
  }
}
export { DreamTeam as DreamTeamTest };

const mapStateToProps = (
  state: RootState,
  ownProps: OwnProps
): IPropsFromState => {
  const eventId = Number(ownProps.eventId) || 0;
  return {
    data: eventId
      ? getEventDreamTeam(state, eventId)
      : getOverallDreamTeam(state),
    elementsById: getElementsById(state),
    elementsDataById: getElementsEventDataById(state, eventId),
    event: getEventsById(state)[eventId],
    fixturesById: getFixturesForEventById(state, eventId),
    formation: eventId
      ? getEventDreamTeamFormation(state, eventId)
      : getOverallDreamTeamFormation(state),
    now: getCurrentEvent(state),
    teamsById: getTeamsById(state),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch): IPropsFromDispatch => ({
  fetchEventLive: (eventId) => dispatch(fetchEventLive(eventId)),
  fetchEventDreamTeam: (eventId) => dispatch(fetchEventDreamTeam(eventId)),
  fetchOverallDreamTeam: () => dispatch(fetchOverallDreamTeam()),
  showElementDialog: (elementId) => dispatch(showElementSummary(elementId)),
});

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(DreamTeam)
);

import { setDefaultOptions } from "date-fns";
import { ar } from "date-fns/locale";
import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import moment from "moment";
import "moment/locale/ar";
import { initReactI18next } from "react-i18next";

const lng = window.location.hostname.substring(0, 3) === "en." ? "en" : "ar";
export const dateLocales: Record<string, Locale> = {
  ar,
};
// Update moment and date-fns locale
i18n.on("languageChanged", function (lng: any) {
  moment.locale(lng);
  setDefaultOptions({ locale: dateLocales[lng] });
});

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    ns: ["common"],
    defaultNS: "common",
    lng,
    fallbackLng: "en", // to stop trying to fetch dev transations
    debug: true,
    interpolation: {
      escapeValue: false, // react already safe from xss as escaped by default
    },
    initImmediate: false, // makes the language code accessible in components
  });

export default i18n;

import * as React from "react";
import styled from "styled-components/macro";
import { IPickLight } from "../../core/store/entries/types";
import PitchSvg from "../../img/rsl-pitch.svg";
import StatusPitchElement from "./StatusPitchElement";

const StatusPitch = styled.div`
  min-height: 330px;
  background-image: url(${PitchSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 516px 330px;
  background-color: ${({ theme }) => theme.colors.greyLight3};

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 524px;
    background-size: 820px 524px;
  }
`;

const StatusPitchRow = styled.div`
  display: flex;
  max-width: 410px;
  margin: auto;
  padding-bottom: 0.5rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    max-width: 620px;
    padding-bottom: 2.8rem;
  }
`;

const StatusPitchUnit = styled.div`
  flex: 1;
  min-height: 7.2rem;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    min-height: 9.6rem;
  }
`;

interface IFormationProps {
  generateProps: (i: number) => {
    pick: IPickLight;
    renderElementMenu: () => void;
    renderPickValue: (pick: IPickLight) => React.ReactNode;
  };
}

const FiveFourOne: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
  </>
);

const FiveThreeTwo: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
    </StatusPitchRow>
  </>
);

const FiveTwoThree: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
    </StatusPitchRow>
  </>
);

const FourFiveOne: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
  </>
);

const FourThreeThree: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
    </StatusPitchRow>
  </>
);

const FourFourTwo: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
    </StatusPitchRow>
  </>
);

const ThreeFourThree: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
    </StatusPitchRow>
  </>
);

const ThreeFiveTwo: React.FC<IFormationProps> = ({ generateProps }) => (
  <>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(0)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit />
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(1)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(2)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(3)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(4)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(5)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(6)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(7)} />
      </StatusPitchUnit>
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(8)} />
      </StatusPitchUnit>
    </StatusPitchRow>
    <StatusPitchRow>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(9)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
      <StatusPitchUnit>
        <StatusPitchElement {...generateProps(10)} />
      </StatusPitchUnit>
      <StatusPitchUnit />
    </StatusPitchRow>
  </>
);

interface IProps {
  chipName: string | null;
  formation: string;
  picks: IPickLight[];
  renderDreamTeam: (pick: IPickLight) => React.ReactNode;
  renderElementMenu: (element: number) => void;
  renderPickValue: (pick: IPickLight) => React.ReactNode;
}

interface IFormations {
  [key: string]: React.ReactNode;
}

const StatusFormation: React.FC<IProps> = ({
  formation,
  picks,
  renderElementMenu,
  renderPickValue,
}) => {
  const sharedTeamPitchElementProps = (i: number) => ({
    pick: picks[i],
    renderElementMenu: () => renderElementMenu(picks[i].element),
    renderPickValue,
  });
  const formations: IFormations = {
    "1-5-4-1": <FiveFourOne generateProps={sharedTeamPitchElementProps} />,
    "1-5-3-2": <FiveThreeTwo generateProps={sharedTeamPitchElementProps} />,
    "1-5-2-3": <FiveTwoThree generateProps={sharedTeamPitchElementProps} />,
    "1-4-5-1": <FourFiveOne generateProps={sharedTeamPitchElementProps} />,
    "1-4-4-2": <FourFourTwo generateProps={sharedTeamPitchElementProps} />,
    "1-4-3-3": <FourThreeThree generateProps={sharedTeamPitchElementProps} />,
    "1-3-5-2": <ThreeFiveTwo generateProps={sharedTeamPitchElementProps} />,
    "1-3-4-3": <ThreeFourThree generateProps={sharedTeamPitchElementProps} />,
  };
  return <StatusPitch>{formations[formation]}</StatusPitch>;
};

export default StatusFormation;

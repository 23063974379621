import { combineReducers } from "redux";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "./actions";
import { IRegion, IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addRegions.success):
        const newState: { [key: string]: IRegion } = {};
        action.payload.forEach((team) => {
          newState[team.id] = team;
        });
        return newState;
      default:
        return state;
    }
  },
});

// State Selectors
export const getRegionsById = (state: RootState) => state.regions.byId;

export const getRegions = (state: RootState) =>
  Object.keys(state.regions.byId).map((r) => state.regions.byId[r]);

import React from "react";
import { useTranslation } from "react-i18next";
import { IEntryInLeague } from "../../core/store/leagues/types";
import { SelectField } from "../FieldRenderers";
import { SelectBox } from "./Styles";

interface IProps {
  entryId: number;
  handleFilterChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  leagueEntries: IEntryInLeague[];
}

const H2HEntryFilter: React.FC<IProps> = ({
  entryId,
  handleFilterChange,
  leagueEntries,
}) => {
  const { t } = useTranslation();
  return (
    <SelectBox>
      <SelectField
        id="teams"
        name="teams"
        label="Team"
        value={entryId}
        onChange={handleFilterChange}
      >
        <option value="0">{t("h2hEntryFilters.all", "All teams")}</option>
        {leagueEntries
          .sort((a, b) => a.entry_name.localeCompare(b.entry_name))
          .map((e) => (
            <option value={e.entry} key={e.entry}>
              {e.entry_name}
            </option>
          ))}
      </SelectField>
    </SelectBox>
  );
};

export default H2HEntryFilter;

import { useState } from "react";

export function useFormHook<T>(initialStateObject: T) {
  const [formState, updateFormState] = useState(initialStateObject);

  // event handler
  const updateState = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const newFormState = {
      ...formState,
      [id]: value,
    };
    updateFormState(newFormState);
  };

  const updateCheckboxState = (event: React.FormEvent<HTMLInputElement>) => {
    const { id, checked } = event.currentTarget;
    const newFormState = {
      ...formState,
      [id]: checked,
    };
    updateFormState(newFormState);
  };

  // color change handler
  const updateColorState = (name: string, hex: string) => {
    const newFormState = {
      ...formState,
      [name]: hex,
    };
    updateFormState(newFormState);
  };

  const updateSelectState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { id, value } = event.currentTarget;
    const newFormState = {
      ...formState,
      [id]: value,
    };
    updateFormState(newFormState);
  };

  return [
    formState,
    updateState,
    updateCheckboxState,
    updateColorState,
    updateSelectState,
  ] as [
    typeof formState,
    typeof updateState,
    typeof updateCheckboxState,
    typeof updateColorState,
    typeof updateSelectState
  ];
}

export default useFormHook;

import React from "react";
import { useTranslation } from "react-i18next";
import SubNav from "../SubNav";

const LeaguesNav: React.FC = () => {
  const { t } = useTranslation();
  const links = [
    { href: "/leagues", text: t("leaguesNav.news", "Leagues") },
    { href: "/leagues/cups", text: t("leaguesNav.cups", "Cups") },
  ];
  return <SubNav links={links} />;
};

export default LeaguesNav;

import { createAsyncAction } from "typesafe-actions";
import { IDreamTeamData } from "./types";
import { IError } from "..";

export const fetchOverallDreamTeam = createAsyncAction(
  "FETCH_OVERALL_DREAM_TEAM_START",
  "FETCH_OVERALL_DREAM_TEAM_SUCCESS",
  "FETCH_OVERALL_DREAM_TEAM_FAILURE"
)<void, IDreamTeamData, IError>();

export const fetchEventDreamTeam = createAsyncAction(
  "FETCH_EVENT_DREAM_TEAM_START",
  "FETCH_EVENT_DREAM_TEAM_SUCCESS",
  "FETCH_EVENT_DREAM_TEAM_FAILURE"
)<void, { event: number; data: IDreamTeamData }, IError>();

import { hideVisually, size } from "polished";
import * as React from "react";
import styled, { css } from "styled-components/macro";

const Button = styled.button<IStyledProps>`
  ${size(42)};
  border: none;
  border-radius: ${({ theme }) => theme.radii[1]};
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.green};
  cursor: pointer;
  > svg {
    vertical-align: middle;
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: ${props.theme.colors.greyLight};
      color: ${props.theme.colors.whiteGrey};
    `}

  ${(props) =>
    props.isSmall &&
    css`
      ${size(38)};
    `}
`;

const HiddenLabel = styled.span`
  ${hideVisually}
`;

interface IStyledProps {
  disabled?: boolean;
  isSmall?: boolean;
}

interface IProps {
  actionMe: () => void;
  children: React.ReactNode;
  label: string;
}

type Props = IProps & IStyledProps;

const PaginatorButton: React.FC<Props> = ({
  actionMe,
  children,
  disabled,
  isSmall,
  label,
}) => (
  <Button
    onClick={actionMe}
    type="button"
    disabled={disabled}
    isSmall={isSmall}
  >
    {children}
    <HiddenLabel>{label}</HiddenLabel>
  </Button>
);

export default PaginatorButton;

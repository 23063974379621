import { RouteComponentProps } from "@reach/router";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Box } from "rebass";
import { ThunkDispatch } from "../../core/store";
import { passwordReset } from "../../core/store/player/thunks";
import Button from "../Button";
import Copy from "../Copy";
import { InputField } from "../FieldRenderers";
import { Main, Wrapper } from "../Layout";
import Title from "../Title";

const PasswordReset: React.FC<RouteComponentProps> = () => {
  const reduxDispatch = useDispatch<ThunkDispatch>();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [submitted, setSumbitted] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    try {
      await reduxDispatch(passwordReset({ email }));
    } catch (e) {
      return;
    }
    setSumbitted(true);
  }

  if (submitted) {
    return (
      <Wrapper>
        <Main>
          <Box mx={2}>
            <Title>{t("passwordReset.sentTitle", "Password reset sent")}</Title>
          </Box>
          <Copy>
            <p>
              {t(
                "passwordReset.sentExplain",
                "We've emailed you instructions for setting your password to the email address you submitted. You should receive it shortly."
              )}
            </p>
          </Copy>
        </Main>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Main>
        <Box mx={2}>
          <Title>{t("passwordReset.title", "Password reset")}</Title>
        </Box>
        <Copy>
          <p>
            {t(
              "passwordReset.explain",
              "Forgotten your password? Enter your e-mail address below, and we'll e-mail instructions for setting a new one."
            )}
          </p>
          <form onSubmit={handleSubmit}>
            <Box>
              <InputField
                id="ismRegisterEmail"
                type="email"
                label={t("passwordReset.email", "Email")}
                value={email}
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setEmail(e.currentTarget.value)
                }
              />
            </Box>
            <Box mt={2} mb={8}>
              <Button type="submit" disabled={!email}>
                {t("passwordReset.button", "Change my password")}
              </Button>
            </Box>
          </form>
        </Copy>
      </Main>
    </Wrapper>
  );
};

export default PasswordReset;

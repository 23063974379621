import * as React from "react";
import styled, { css } from "styled-components";
import { NewIcon, SameIcon } from "../icons/Movement";

const ARROW_SIZE = 6;

const arrowStyling = css`
  width: 0;
  height: 0;
  border-inline-start: ${ARROW_SIZE}px solid transparent;
  border-inline-end: ${ARROW_SIZE}px solid transparent;
`;

const StyledUpArrow = styled.div`
  ${arrowStyling};
  border-bottom: ${ARROW_SIZE}px solid ${({ theme }) => theme.colors.success};
`;

const StyledDownArrow = styled.div`
  ${arrowStyling};
  border-top: ${ARROW_SIZE}px solid ${({ theme }) => theme.colors.error};
`;

interface IProps {
  lastRank: number | null;
  rank: number | null;
}

const Movement: React.FC<IProps> = ({ lastRank, rank }) => {
  if (lastRank === 0 || rank === 0 || lastRank === null || rank === null) {
    return <NewIcon />;
  }
  if (rank === lastRank) {
    return <SameIcon />;
  }
  if (rank > lastRank) {
    return <StyledDownArrow />;
  }
  if (rank < lastRank) {
    return <StyledUpArrow />;
  }
  return null;
};

export default Movement;

import * as React from "react";

export interface ITabPanel {
  children: React.ReactNode;
  label: React.ReactNode;
  link: string;
}

const TabPanel: React.FC<ITabPanel> = ({ children }) => <div>{children}</div>;

export default TabPanel;

import { ThunkResult } from "..";
import { apiGet } from "../../utils/api";
import * as actions from "./actions";
import { IURLResponse } from "./types";

export const fetchURL =
  (url: string): ThunkResult<Promise<void>> =>
  async (dispatch) => {
    // We should do some rate limiting here and don't fetch if we have received
    // the url data in the last 60(?) seconds
    // Another enhancement is to handle the 'empty' response, trying again
    // after 3(?) seconds could be a solution.
    try {
      await apiGet<IURLResponse>(
        `get-url/?url=${url}`,
        dispatch,
        actions.fetchURL,
        { url }
      );
    } catch (e) {
      return;
    }
  };

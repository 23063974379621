import React from "react";
import { IPrivateLeagueAdmin } from "../../../core/store/leagues/types";
import Button from "../../Button";
import { FieldWrap } from "../../FieldRenderers";
import SubHeading from "../../SubHeading";
import EntrySelector from "./EntrySelector";
import { withTranslation, WithTranslation } from "react-i18next";

interface IProps {
  updateAdmin: (entryId: number) => void;
  league: IPrivateLeagueAdmin;
}

type Props = IProps & WithTranslation;

interface IState {
  entry: number;
}

class ChangeAdmin extends React.Component<Props, IState> {
  public state: IState = {
    entry: 0,
  };

  public handleEntryChange = (entryId: number) => {
    this.setState({ entry: entryId });
  };

  public handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.updateAdmin(this.state.entry);
  };

  public render() {
    const disabled = !this.state.entry;
    const { league, t } = this.props;
    return (
      <>
        <SubHeading>
          {t("changeAdmin.title", "Change league administrator")}
        </SubHeading>
        <form onSubmit={this.handleSubmit}>
          <FieldWrap>
            <EntrySelector
              handleChange={this.handleEntryChange}
              leagueId={league.id}
              excluded={[league.admin_entry]}
            />
          </FieldWrap>
          <FieldWrap>
            <Button type="submit" disabled={disabled}>
              {t("changeAdmin.cta", "Change administrator")}
            </Button>
          </FieldWrap>
        </form>
      </>
    );
  }
}

export default withTranslation()(ChangeAdmin);

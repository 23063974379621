import { ellipsis, hideVisually } from "polished";
import styled from "styled-components/macro";

export const Ellipsis = styled.div`
  ${ellipsis()}
`;

export const UnstyledButton = styled.button`
  display: block;
  width: 100%;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-family: ${({ theme }) => theme.fonts.base};
  line-height: 1;
  cursor: pointer;
`;

export const VisuallyHidden = styled.span`
  ${hideVisually}
`;

export const FetchAPI = async (
  uri: string,
  data: Record<string, string>,
  method: string
) => {
  return fetch(`/api/${uri}`, {
    method,
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: method === "GET" ? undefined : JSON.stringify(data),
  }).then((response) => {
    if (!response.ok) {
      // create error object and reject if not a 2xx response code
      throw response;
    }
    return response;
  });
};

export const FetchPost = async (uri: string, data: Record<string, string>) =>
  FetchAPI(uri, data, "POST");

export const FetchPut = async (uri: string, data: Record<string, string>) =>
  FetchAPI(uri, data, "PUT");

export const FetchGet = async (uri: string, data: Record<string, string>) =>
  FetchAPI(uri, data, "GET");

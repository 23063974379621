import range from "lodash/range";
import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "..";
import * as actions from "../bootstrap/actions";
import { getSettings } from "../game/reducers";
import { IElementType, IElementTypesBySquadPosition, IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addElementTypes):
        const newState: { [key: string]: IElementType } = {};
        action.payload.forEach((et) => {
          newState[et.id] = et;
        });
        return newState;
      default:
        return state;
    }
  },
});

// State Selectors
export const getElementTypesById = (state: RootState) =>
  state.elementTypes.byId;

export const getElementTypes = (state: RootState) =>
  Object.keys(state.elementTypes.byId).map((et) => state.elementTypes.byId[et]);

export const getElementTypesBySquadPosition = createSelector(
  getElementTypes,
  getSettings,
  (types) => {
    const data: IElementTypesBySquadPosition = {};

    // First pass to set types by position
    let count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        data[count] = { lastType: null, nextType: null, thisType: et };
        count++;
      });
    });

    // Second pass to set last and next types which help with rendering
    count = 1;
    types.forEach((et) => {
      range(et.squad_select).forEach(() => {
        if (data[count - 1]) {
          data[count].lastType = data[count - 1].thisType;
        }
        if (data[count + 1]) {
          data[count].nextType = data[count + 1].thisType;
        }
        count++;
      });
    });

    return data;
  }
);

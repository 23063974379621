import { combineReducers } from "redux";
import { createSelector } from "reselect";
import { getType } from "typesafe-actions";
import { RootAction, RootState } from "../";
import * as actions from "../bootstrap/actions";
import { getCurrentEvent, getFinishedEvents } from "../events/reducers";
import { IPhase, IState } from "./types";

// State Reducer
export default combineReducers<IState, RootAction>({
  byId: (state = {}, action: RootAction) => {
    switch (action.type) {
      case getType(actions.addPhases):
        const newState: { [key: string]: IPhase } = {};
        action.payload.forEach((et) => {
          newState[et.id] = et;
        });
        return newState;
      default:
        return state;
    }
  },
});

// State Selectors
export const getPhasesById = (state: RootState) => state.phases.byId;

export const getPhases = (state: RootState) =>
  Object.keys(state.phases.byId).map((p) => state.phases.byId[p]);

export const getFinishedPhases = createSelector(
  getPhases,
  getFinishedEvents,
  (phases, events) => {
    if (!events.length) {
      return [];
    }
    const lastFinishedEvent = events[events.length - 1];
    return phases.filter((p) => p.stop_event <= lastFinishedEvent.id);
  }
);

export const getStartedPhases = createSelector(
  getPhases,
  getCurrentEvent,
  (phases, now) => {
    if (!now) {
      return [];
    }
    return phases.filter((p) => p.start_event <= now.id);
  }
);

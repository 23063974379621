import styled from "styled-components/macro";

export const Pager = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EventPager = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PagerItem = styled.div`
  flex: 1;
  padding: 0 ${(props) => props.theme.space[2]};
`;

export const PagerButton = styled.div`
  flex-basis: 49%;
  padding: 0 ${({ theme }) => theme.space[2]};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}) {
    flex-basis: 39%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    flex-basis: 33%;
  }
`;

export const PagerButtonNext = styled(PagerButton)`
  margin-inline-start: 0;
`;

export const PagerHeading = styled.h3`
  flex-basis: 28%;
  font-size: 1.4rem;
  text-align: center;

  @media (min-width: ${({ theme }) => theme.breakpoints[4]}) {
    font-size: 2rem;

    margin: 5px;
    padding: 1.1rem 1rem;
  }
`;

export const PagerItemNext = styled(PagerItem)`
  margin-inline-start: auto;
  text-align: end;
`;

import {
  Location,
  Redirect,
  RouteComponentProps,
  Router,
  WindowLocation,
} from "@reach/router";
import React, { Suspense, lazy } from "react";
import { useTranslation } from "react-i18next";
import { ConnectedComponent, connect } from "react-redux";
import { RootState } from "../core/store";
import { getPlayerData } from "../core/store/player/reducers";
import { IPlayer } from "../core/store/player/types";
import { Loading } from "./App";
import Copy from "./Copy";
import EntryHistory from "./EntryHistory";
import EntryUnsubscribe from "./EntryUnsubscribe";
import EntryUpdate from "./EntryUpdate";
import FixturesWrapper from "./FixturesWrapper";
import Home from "./Home";
import { Main, Wrapper } from "./Layout";
import Login from "./Login";

import TransfersHistory from "./TransfersHistory";
import PlayerList from "./help/PlayerList";
import AdminClassic from "./leagues/AdminClassic";
import AdminH2H from "./leagues/AdminH2H";
import AutoJoin from "./leagues/AutoJoin";
import Create from "./leagues/Create";
import CreateClassic from "./leagues/CreateClassic";
import CreateH2H from "./leagues/CreateH2H";
import CreateJoin from "./leagues/CreateJoin";
import CupNotStarted from "./leagues/CupNotStarted";
import Invite from "./leagues/Invite";
import Join from "./leagues/Join";
import JoinPrivate from "./leagues/JoinPrivate";
import JoinPublic from "./leagues/JoinPublic";
import MatchesCup from "./leagues/MatchesCup";
import MatchesH2H from "./leagues/MatchesH2H";
import MyCups from "./leagues/MyCups";
import MyLeagues from "./leagues/MyLeagues";
import Renew from "./leagues/Renew";
import StandingsClassic from "./leagues/StandingsClassic";
import StandingsH2H from "./leagues/StandingsH2H";
import LoggedIn from "./player/LoggedIn";
import Logout from "./player/Logout";
import PasswordReset from "./player/PasswordReset";
import PasswordSet from "./player/PasswordSet";
import Register from "./player/Register";
import VerifyDeleteOtp from "./player/VerifyDeleteOtp";
import SquadSelection from "./squad/SquadSelection";
import Transfers from "./squad/Transfers";
import News from "./stats/News";
import Statistics from "./stats/Statistics";
import DreamTeam from "./team/DreamTeam";
import EntryEvent from "./team/EntryEvent";
import MyTeam from "./team/MyTeam";
const Help = lazy(() => import("./help/Help"));
const Rules = lazy(() => import("./help/Rules"));
const Terms = lazy(() => import("./help/Terms"));
const Prizes = lazy(() => import("./Prizes"));

interface IRouteChangeProps {
  action: () => void;
}

interface IRouteChangeWorkerProps {
  location: WindowLocation;
}

type RouteChangeProps = IRouteChangeProps & IRouteChangeWorkerProps;

class OnRouteChangeWorker extends React.Component<RouteChangeProps> {
  componentDidUpdate(prevProps: RouteChangeProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.props.action();
    }
  }

  render() {
    return null;
  }
}

const OnRouteChange: React.FC<IRouteChangeProps> = ({ action }) => (
  <Location>
    {({ location }) => (
      <OnRouteChangeWorker location={location} action={action} />
    )}
  </Location>
);

interface IProtectedRouteProps extends RouteComponentProps {
  as: React.ComponentType | ConnectedComponent<any, {}>;
  player: IPlayer | null;
}

interface IPropsFromState {
  player: IPlayer | null;
}

const PlayerRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  player,
  ...rest
}) => {
  if (player && !player.entry) {
    return <Component {...rest} />;
  } else if (player && player.dirty) {
    return <Register />;
  } else {
    return (
      <Wrapper>
        <Main isWide>
          <Login />
        </Main>
      </Wrapper>
    );
  }
};

const EntryRoute: React.FC<IProtectedRouteProps> = ({
  as: Component,
  player,
  ...rest
}) => {
  if (player && player.entry && !player.dirty) {
    return <Component {...rest} />;
  } else if (player && player.dirty) {
    return <Register />;
  } else {
    return (
      <Wrapper>
        <Main isWide>
          <Login />
        </Main>
      </Wrapper>
    );
  }
};

export const NotFound: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Main>
        <Copy>
          <h4>{t("routes.notFound.title", "Page not found")}</h4>
          <p>
            {t(
              "routes.notFound.text",
              "Sorry, but the page you were looking for can't be found."
            )}
          </p>
        </Copy>
      </Main>
    </Wrapper>
  );
};

const Routes: React.FC<IPropsFromState> = ({ player }) => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Home path="/" />
        <PlayerRoute
          as={SquadSelection}
          player={player}
          path="/squad-selection"
        />
        <EntryRoute as={MyTeam} player={player} path="/my-team" />
        <EntryRoute as={Transfers} player={player} path="/transfers" />
        <EntryRoute as={MyLeagues} player={player} path="/leagues" />
        <EntryRoute as={Renew} player={player} path="/leagues/renew" />
        <EntryRoute as={MyCups} player={player} path="/leagues/cups" />
        <EntryRoute
          as={CreateJoin}
          player={player}
          path="/leagues/create-join"
        />
        <EntryRoute as={Create} player={player} path="/leagues/create" />
        <EntryRoute
          as={CreateClassic}
          player={player}
          path="/leagues/create/classic"
        />
        <EntryRoute as={CreateH2H} player={player} path="/leagues/create/h2h" />
        <EntryRoute as={Join} player={player} path="/leagues/join" />
        <EntryRoute
          as={JoinPrivate}
          player={player}
          path="/leagues/join/private"
        />
        <EntryRoute
          as={JoinPublic}
          player={player}
          path="/leagues/join/public"
        />
        <EntryRoute
          as={AdminClassic}
          player={player}
          path="/leagues/:leagueId/admin/c"
        />
        <EntryRoute
          as={AdminH2H}
          player={player}
          path="/leagues/:leagueId/admin/h"
        />
        <EntryRoute
          as={Invite}
          player={player}
          path="/leagues/:leagueId/invite"
        />
        <StandingsClassic path="/leagues/:leagueId/standings/c" />
        <MatchesCup path="/leagues/:leagueId/cup" />
        <CupNotStarted path="/leagues/:leagueId/cup-not-started" />
        <StandingsH2H path="/leagues/:leagueId/standings/h" />
        <MatchesH2H path="/leagues/:leagueId/matches/h" />
        <EntryRoute as={EntryUpdate} player={player} path="/entry-update" />
        <EntryHistory path="/entry/:entryId/history" />
        <EntryEvent path="/entry/:entryId/event/:eventId" />
        <TransfersHistory path="/entry/:entryId/transfers" />
        <DreamTeam path="/dream-team/" />
        <DreamTeam path="/dream-team/:eventId" />
        <Statistics path="/statistics" />
        <Statistics path="/statistics/:statName" />
        <News path="/the-scout/player-news" />
        <FixturesWrapper path="/fixtures" />
        <FixturesWrapper path="/fixtures/:eventId" />
        <Help path="/help" />
        <Rules path="/help/rules" />
        <Terms path="/help/terms" />
        <PlayerList path="/player-list" />
        <AutoJoin path="/leagues/auto-join/:code" />
        <Logout path="/player/logout" />
        <Register path="/player" />
        <PasswordReset path="/player/password-reset/" />
        <PasswordSet path="/player/password-set/:uidb64/" />
        <PasswordSet path="/player/password-set/:uidb64/:token/" />
        <Prizes path="/prizes" />
        <LoggedIn path="/player/logged-in" />
        <Redirect from="/a/team/my" to="/my-team" />
        <Redirect from="/a/squad/transfers" to="/transfers" />
        <Redirect from="/a/leagues/main" to="/leagues" />
        <Redirect from="/a/fixtures" to="/fixtures" />
        <Redirect from="/a/statistics/total_points" to="/statistics" />
        <EntryUnsubscribe path="/entry/unsubscribe/:signature" />
        <VerifyDeleteOtp path="/player/verify-otp-delete" />
        <NotFound default />
      </Router>
      <OnRouteChange action={() => window.scrollTo(0, 0)} />
    </Suspense>
  );
};

const mapStateToProps = (state: RootState): IPropsFromState => ({
  player: getPlayerData(state),
});

export default connect(mapStateToProps)(Routes);
